<template>
  <div v-if="searchresult" class="mb-4">
    <div class="bg-image" style="padding: 20px 0px !important">
      <div class="container" style="max-width: 1250px !important;">
        <v-card rounded="3" style="overflow: unset">
          <v-card rounded="3" style="padding: 10px 10px; overflow: unset">
            <v-card rounded="3" class="round-mode" v-if="roundModify">
              <div class="row d-flex">
                <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                  <div>
                    <h5 class="text-center">{{ tripData.from }}</h5>
                    <p class="text-center">{{ tripData.dedate }}</p>
                  </div>
                  <v-icon size="30">mdi-swap-horizontal</v-icon>
                  <div>
                    <h5 class="text-center">{{ tripData.to }}</h5>
                    <p class="text-center">{{ tripData.redate }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">
                    {{ $t("formsContents.options.classType") }}
                  </h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ displayClassType }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">
                    {{ $t("formsContents.options.passengers") }}
                  </h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls > 0"
                      >{{ tripData.aduls }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls1 > 0"
                      >, {{ tripData.aduls1 }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls2 > 0"
                      >, {{ tripData.aduls2 }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn
                    color="#ee2b34"
                    @click="modifySection = !modifySection"
                    style="color: white"
                    >{{ $t("searchPageContent.itinContent.modify") }}
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>

            <v-card
              rounded="0"
              class="oneway-mode"
              style="padding: 10px 10px; overflow: unset"
              v-if="onewayModify"
            >
              <div class="row d-flex">
                <div class="col-lg-5 col-12">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-evenly">
                      <div>
                        <h5 class="text-center">{{ tripData.from }}</h5>
                      </div>
                      <v-icon size="30">mdi-arrow-right</v-icon>
                      <div>
                        <h5 class="text-center">{{ tripData.to }}</h5>
                      </div>
                    </div>

                    <div class="col-12 d-flex justify-content-evenly">
                      <p class="text-center m-0 mt-2">
                        {{ tripData.dedate }} / Oneway
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">Class Type</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ tripData.class }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">
                    {{ $t("fareDetContent.passengers") }}
                  </h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls > 0"
                      >{{ tripData.aduls }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls1 > 0"
                      >, {{ tripData.aduls1 }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="tripData.aduls2 > 0"
                      >, {{ tripData.aduls2 }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn color="#ee2b34" @click="modifySection = !modifySection"
                    >{{ $t("searchPageContent.itinContent.modify") }}
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>

            <v-card rounded="3" class="round-mode" v-if="multiModify">
              <div class="row d-flex">
                <div class="col-lg-5 col-12 d-flex justify-content-evenly">
                  <div>
                    <h5 class="text-center">{{ getMultifrom(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate1(multi_city) }}</p>
                    <!-- <h5 class="text-center">{{ getMultifrom(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate1(multi_city) }}</p> -->
                  </div>
                  <div class="d-flex flex-column mt-3">
                    <v-icon size="18" style="transform: rotate(45deg)"
                      >mdi-arrow-top-right</v-icon
                    >
                    <v-icon size="18" style="transform: rotate(45deg)"
                      >mdi-arrow-top-right</v-icon
                    >
                    <!-- <v-icon size="18" style="transform: rotate(45deg);">mdi-arrow-top-right</v-icon> -->
                  </div>
                  <div>
                    <h5 class="text-center">{{ getMultiTo(multi_city) }}</h5>
                    <p class="text-center">{{ getMultiDedate2(multi_city) }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-6 align-center">
                  <h6 class="text-center">Class Type</h6>
                  <p class="text-center" style="font-weight: 500; color: gray">
                    {{ getMultiClass(multi_city) }}
                  </p>
                </div>
                <div class="col-lg-3 col-6 align-center">
                  <h6 class="text-center">
                    {{ $t("fareDetContent.passengers") }}
                  </h6>
                  <div class="d-flex justify-center">
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiAdult(multi_city) > 0"
                    >
                      {{ getMultiAdult(multi_city) }} Adt</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiChild(multi_city) > 0"
                      >, {{ getMultiChild(multi_city) }} Ch</span
                    >
                    <span
                      style="font-size: 14px; font-weight: 500; color: gray"
                      v-if="getMultiInfrant(multi_city) > 0"
                      >, {{ getMultiInfrant(multi_city) }} Inf</span
                    >
                  </div>
                </div>
                <div class="col-lg-2 col-12 align-center" align="center">
                  <v-btn
                    color="#ee2b34"
                    @click="modifySection = !modifySection"
                    style="color: white"
                    >{{ $t("searchPageContent.itinContent.modify") }}
                    <v-icon size="25" class="ps-2">
                      {{
                        modifySection
                          ? "mdi-arrow-up-drop-circle-outline"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-card>

          <v-card
            rounded="0"
            style="padding: 10px 10px; overflow: unset"
            v-if="modifySection"
          >
            <div class="row" align="center">
              <h6 style="color: #ee2b34">
                {{ $t("formsContents.modifyContent.heading") }}
              </h6>
            </div>
            <div class="row mt-1 px-3">
              <div class="col-lg-6 col-md-6">
                <v-radio-group
                  inline
                  color="FFB80A"
                  v-model="red"
                  style="position: relative; top: 0px"
                >
                  <v-radio
                    :label="this.$t('formsContents.options.return')"
                    value="1"
                    class="normal-radio"
                    :class="{ radio: red1 == 1 }"
                    @click="act(1)"
                  ></v-radio>
                  <v-radio
                    :label="this.$t('formsContents.options.oneWay')"
                    value="2"
                    class="normal-radio"
                    :class="{ radio: red1 == 2 }"
                    @click="act(2)"
                  ></v-radio>
                  <v-radio
                    :label="this.$t('formsContents.options.multiCity')"
                    value="3"
                    class="normal-radio"
                    :class="{ radio: red1 == 3 }"
                    @click="act(3)"
                  ></v-radio>
                </v-radio-group>
              </div>

              <div class="col-lg-6 col-md-6 mt-3 mt-sm-0">
                <div class="row justify-content-sm-start justify-center">
                  <!-- <div class="col-1">
                  <span style="
                      font-size: 18px;
                      color: gray;
                      position: relative;
                      top: 5px;
                    ">
                    <v-icon> mdi-account-outline </v-icon>
                  </span>
                </div> -->
                  <div class="col-lg-10 col-12">
                    <div class="d-flex input-styles" style="width: fit-content">
                      <span
                        class="d-flex me-2"
                        style="font-size: 18px; color: gray"
                      >
                        <v-icon> mdi-account-outline </v-icon>
                      </span>

                      <v-select
                        v-model="selectedCountry"
                        :items="countries"
                        ref="countrySelect"
                        variant="underlined"
                        class="select-1"
                      >
                        <!-- <template #item="{ item }"> -->
                        <template #item="{}">
                          <div class="dropdown_section" style="cursor: pointer">
                            <div
                              class="row d-flex align-center my-2 mx-1"
                              style="height: 37px"
                              @click.stop
                            >
                              <div class="col-3" style="width: 38%">
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >{{ $t("formsContents.options.adult") }}</span
                                >
                              </div>
                              <div class="col-3 px-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-human-male-female
                                </v-icon>
                              </div>
                              <div
                                class="col-6 adult"
                                style="width: 50%; height: auto"
                              >
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg1()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls }}</div>
                                  </div>
                                  <div class="col-4" @click="add1()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>{{ item.name }}</div> -->
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div
                                class="col-4 d-flex flex-column pe-0"
                                style="width: 38%"
                              >
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >{{ $t("formsContents.options.child") }}</span
                                >
                                <span style="font-size: 11px; color: #4e4d4d"
                                  >2-11(yrs)</span
                                >
                              </div>
                              <div class="col-2 px-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-human-child
                                </v-icon>
                              </div>
                              <div class="col-6 adult" style="width: 50%">
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg2()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls1 }}</div>
                                  </div>
                                  <div class="col-4" @click="add2()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div>{{ item.name }}</div> -->
                            </div>

                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div
                                class="col-5 d-flex flex-column pe-0"
                                style="width: 38%"
                              >
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #4e4d4d;
                                  "
                                  >{{
                                    $t("formsContents.options.infants")
                                  }}</span
                                >
                                <span style="font-size: 11px; color: #4e4d4d"
                                  >2(yrs)</span
                                >
                              </div>

                              <div class="col-1 ps-0" style="width: 9%">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-baby-buggy
                                </v-icon>
                              </div>
                              <div class="col-6 adult" style="width: 50%">
                                <div class="row" style="padding: 0px">
                                  <div class="col-4" @click="deg3()">
                                    <div class="adult-plus">-</div>
                                  </div>
                                  <div
                                    class="col-4"
                                    style="background-color: white"
                                    align="center"
                                  >
                                    <div>{{ aduls2 }}</div>
                                  </div>
                                  <div class="col-4" @click="add3()">
                                    <div class="adult-plus">+</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row d-flex align-center my-2 mx-1"
                              @click.stop
                            >
                              <div class="col-4">
                                <span
                                  style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: grey;
                                  "
                                  >{{ $t("formsContents.options.cabin") }}</span
                                >
                              </div>

                              <div class="col-2" align="center">
                                <v-icon
                                  style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  "
                                >
                                  mdi-seat-passenger
                                </v-icon>
                              </div>
                              <div class="col-6">
                                <div
                                  class="input-styles-1 select-option select-2"
                                >
                                  <v-select
                                    v-model="classType"
                                    :items="classTypeitems"
                                    ref="mySelect"
                                    item-title="text"
                                    variant="underlined"
                                  >
                                    <template
                                      v-slot:item="{ props, item, index }"
                                    >
                                      <v-list-item
                                        v-bind="props"
                                        :subtitle="item.department"
                                        @click="selectClasstype(item, index)"
                                      ></v-list-item>
                                    </template>
                                  </v-select>
                                  <!-- <v-select
                                    v-model="classType"
                                    ref="mySelect"
                                    @click="selectFunction()"
                                    :items="['Economy', 'Business', 'First Class',]"
                                    variant="underlined"
                                    style="color: gray"
                                  ></v-select> -->
                                </div>
                              </div>
                            </div>

                            <div
                              align="end"
                              class="mt-4 pe-3 d-flex justify-end"
                              @click="
                                (travels = false), localdone(), closeDropdown()
                              "
                            >
                              <v-btn
                                rounded="3"
                                color="#ee2b34"
                                width="100px"
                                height="30px"
                                style="color: white"
                              >
                                {{ $t("formsContents.options.done") }}
                              </v-btn>
                            </div>
                          </div>
                        </template>

                        <template #selection="{}">
                          <div class="pax-fonts text-nowrap">
                            <span
                              >{{ aduls }}
                              {{ $t("formsContents.options.adult") }}</span
                            >
                            <span v-if="aduls1 > 0"
                              >, {{ aduls1 }}
                              {{ $t("formsContents.options.child") }}</span
                            >
                            <span v-if="aduls2 > 0"
                              >, {{ aduls2 }}
                              {{ $t("formsContents.options.infants") }}</span
                            >

                            <span>, {{ translatedClassType }}</span>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="rount">
              <div class="row my-4 px-3 gx-0">
                <div class="col-lg-11 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.from"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeFrom(oneway.from)"
                            @click="fromClick(oneway)"
                            @complete="search"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                            ref="fromAutoComplete"
                          >
                            <template #option="slotProps">
                              <div
                                class="flex align-items-center dropdown-box"
                                @click="focus1()"
                              >
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">{{
                            $t("formsContents.labels.flyFrom")
                          }}</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage"
                          >Departure is required</span
                        >
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-landing</v-icon>
                        </span>

                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.to"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeTo()"
                            @click="toClick(oneway)"
                            @complete="search"
                            ref="secondAutoComplete"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                          >
                            <template #option="slotProps">
                              <div class="dropdown-container">
                                <div
                                  class="flex align-items-center dropdown-box"
                                  @click="focus2()"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">{{
                            $t("formsContents.labels.flyTo")
                          }}</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage"
                          >Arrival is required</span
                        >
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>

                        <span class="p-float-label">
                          <span
                            :class="clicked1 ? 'label-top' : 'datepicker-label'"
                          >
                            {{ $t("formsContents.labels.departure") }}
                          </span>

                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            :min-date="
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                0,
                                0,
                                0
                              )
                            "
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate(),
                                23,
                                59,
                                59
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            :month-change-on-scroll="false"
                            class="px-0"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError"
                          >Dep. Date is required</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mt-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <span
                            :class="
                              clicked2 ? 'label-top' : 'datepicker-label2'
                            "
                          >
                            {{ $t("formsContents.labels.return") }}
                          </span>
                          <VueDatePicker
                            ref="dpRef2"
                            v-model="oneway.redate"
                            :format="dateFormat"
                            :min-date="oneway.dedate"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :multi-calendars="multiCalender"
                            :on-click-outside="ClickOutReturn"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toDateError"
                          >Arr. Date is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-1 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn"
                >
                  <div @click="sended()">
                    <v-btn
                      color="#ee2b34"
                      height="40px"
                      width="80px"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                    >
                      <v-icon color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="Oneway">
              <div class="row my-4 px-3 gx-0">
                <div class="col-lg-11 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.from"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeFrom()"
                            @click="fromClick(oneway)"
                            @complete="search"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                          >
                            <template #option="slotProps">
                              <div class="flex align-items-center dropdown-box">
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">From</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage"
                          >Departure is required</span
                        >
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-landing</v-icon>
                        </span>

                        <span class="p-float-label">
                          <AutoComplete
                            v-model="oneway.to"
                            optionLabel="name"
                            dropdown
                            :suggestions="filteredCountries"
                            @change="onChangeTo()"
                            @click="toClick(oneway)"
                            @complete="search"
                            style="
                              width: 100%;
                              height: 100%;
                              position: relative;
                            "
                          >
                            <template #option="slotProps">
                              <div class="dropdown-container">
                                <div
                                  class="flex align-items-center dropdown-box"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </div>
                            </template>
                          </AutoComplete>
                          <label for="ac">To</label>
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage"
                          >Arrival is required</span
                        >
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1"
                          >Enter minimum 3 letters</span
                        >
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 my-3">
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>

                        <span class="p-float-label">
                          <span
                            :class="clicked1 ? 'label-top' : 'datepicker-label'"
                          >
                            Departure
                          </span>

                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            :min-date="new Date()"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            class="px-0"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError"
                          >Dep. Date is required</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-lg-3 col-sm-6 mt-3"
                      :disabled="true"
                      style="pointer-events: none; opacity: 0.5"
                    >
                      <div
                        class="d-flex input-styles"
                        @click="triggerFunction()"
                      >
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <span
                            style="
                              font-size: 14px;
                              color: #242424;
                              font-weight: 600;
                            "
                            :class="
                              clicked2 ? 'label-top' : 'datepicker-label2'
                            "
                          >
                            Return
                          </span>
                          <VueDatePicker
                            disabled
                            :format="dateFormat"
                            :min-date="oneway.dedate"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :multi-calendars="multiCalender"
                            :on-click-outside="ClickOutReturn"
                            :month-change-on-scroll="false"
                          />

                          <!-- <VueDatePicker ref="dpRef2" v-model="oneway.redate" :format="dateFormat" :min-date="oneway.dedate"
                        :max-date="new Date(
                          new Date().getFullYear() + 1,
                          new Date().getMonth(),
                          new Date().getDate()
                        )
                          " :hide-navigation="['time']" auto-apply @update:modelValue="toDateChanged"
                        :on-click-outside="ClickOutReturn" /> -->
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toDateError"
                          >Arr. Date is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-1 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn"
                >
                  <div @click="sended()">
                    <v-btn
                      color="#ee2b34"
                      height="40px"
                      width="80"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                    >
                      <v-icon color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="multicity">
              <div class="row my-4 px-3 gx-0">
                <div class="col-lg-8 col-12">
                  <div
                    v-for="(datas, index) of multi_city.itinery"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 my-3">
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="p-float-label">
                            <AutoComplete
                              v-model="datas.from"
                              optionLabel="name"
                              dropdown
                              :suggestions="filteredCountries"
                              @change="onChangeMultiFrom(index)"
                              @click="multiFromClick(index)"
                              @complete="search"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              "
                            >
                              <template #option="slotProps">
                                <div
                                  class="flex align-items-center dropdown-box"
                                  @click="Multifocus1(index)"
                                >
                                  <div>{{ slotProps.option.originalName }}</div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">From</label>
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromErrorMessage"
                            >Departure is required</span
                          >
                          <br v-if="fromErrorMessage && maximumletter" />
                          <span class="p-error" v-if="maximumletter"
                            >Enter minimum 3 letters</span
                          >
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 my-3">
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="p-float-label">
                            <AutoComplete
                              v-model="datas.to"
                              ref="multiTo"
                              optionLabel="name"
                              dropdown
                              :suggestions="filteredCountries"
                              @change="onChangeMultiTo(index)"
                              @click="multiToClick(index)"
                              @complete="search"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              "
                            >
                              <template #option="slotProps">
                                <div class="dropdown-container">
                                  <div
                                    class="flex align-items-center dropdown-box"
                                    @click="Multifocus2(datas, index)"
                                  >
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </AutoComplete>
                            <label for="ac">To</label>
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="toErrorMessage"
                            >Arrival is required</span
                          >
                          <br v-if="toErrorMessage && maximumletter1" />
                          <span class="p-error" v-if="maximumletter1"
                            >Enter minimum 3 letters</span
                          >
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 my-3">
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="p-float-label">
                            <span
                              :class="
                                clicked1 ? 'label-top' : 'datepicker-label'
                              "
                            >
                              Departure
                            </span>

                            <VueDatePicker
                              ref="dpRef4"
                              v-model="datas.depdata"
                              :start-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :min-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :max-date="
                                new Date(
                                  new Date().getFullYear() + 1,
                                  new Date().getMonth(),
                                  new Date().getDate()
                                )
                              "
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              :multi-calendars="multiCalender"
                              @update:modelValue="
                                (newValue) => multiDateChanged(newValue, index)
                              "
                              :on-click-outside="multiClickOutside()"
                              :month-change-on-scroll="false"
                              class="px-0"
                            />
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="fromDateError"
                            >Dep. Date is required</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 d-grid">
                  <div
                    class="row justify-content-between"
                    style="margin-bottom: 16px"
                  >
                    <div
                      class="col-md-8 pe-0 d-flex align-end justify-content-sm-start justify-content-end mb-1"
                    >
                      <span
                        class="d-flex align-center ms-1 mb-1"
                        style="cursor: pointer"
                        @click="deleteSegment(index)"
                        v-if="deleteSeg"
                      >
                        <v-icon color="red" size="20">mdi-close-octagon</v-icon>
                      </span>
                      <span
                        class="d-flex align-center add_style ms-2 order-sm-0 order-1"
                        @click="multiSend(index)"
                        v-if="addmore"
                      >
                        <v-icon color="green" size="20">mdi-plus-circle</v-icon>
                        <span
                          class="ms-1"
                          style="font-size: 13; font-weight: 600"
                          >Add More</span
                        >
                      </span>
                      <span
                        class="d-flex align-center clear_style ms-2 order-sm-1 order-0"
                        @click="clearall1()"
                        v-if="clearall"
                      >
                        <v-icon color="red" size="20"
                          >mdi-close-box-multiple</v-icon
                        >
                        <span
                          class="ms-1"
                          style="font-size: 13; font-weight: 600"
                          >Clear All</span
                        >
                      </span>

                      <!-- <span class="d-flex align-center add_style" @click="send(index)" v-if="addmore">
                        <v-icon color="green" size="20" style="width: auto;">mdi-plus-circle</v-icon>
                        <span class="ms-1" style="font-size:14; font-weight: 600;">Add More</span>
                      </span> -->
                    </div>
                    <div
                      class="col-lg-4 mt-lg-0 mt-4 d-flex pe-2 align-end justify-center justify-content-lg-end magnify-btn"
                    >
                      <div @click="sendedMulti()">
                        <v-btn
                          color="#ee2b34"
                          height="40px"
                          width="100px"
                          class="ms-lg-2 ms-0"
                          rounded="1"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-card>
      </div>
    </div>

    <!-- <div class="container mt-5">
    <v-card rounded="0" style="position: inherit">
      <div class="air-title">
        <h6 style="margin-left: 15px">Pick your preferred Airline</h6>
      </div>
      <div class="row mt-3 ms-2">
        <div class="col-md-4">
          <p>Compare and Choose preffered Airlines</p>
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              Non Stop
            </v-btn>
          </div>
          <br />
          <div>
            <v-btn
              variant="outlined"
              color="info"
              width="150px"
              style="text-transform: capitalize"
            >
              1+ Stops
            </v-btn>
          </div>
        </div>
        <div class="col-md-4 mt-4">
          <v-card class="cart1">

            <h6 class="mt-2 ms-2">Airline</h6>
            <hr>
            <p>USD 100</p>
            <hr />
            <p>USD 100</p>
          </v-card>
        </div>
      </div>
      <p class="ms-3 mt-2" style="font-size: 13px; font-weight: 500">
        Fares incl. taxes & fees. Airfares include an applied Booking Bonus.
        Additional baggage fees may apply. Flights displayed may be for
        alternate dates and/or airports. Certain results may be outside your
        search criteria.
      </p>
      <br />
    </v-card>
  </div> -->

    <div class="container" style="max-width: 1250px !important;">
      <div class="row mt-4">
        <div class="col-lg-3 mb-2 mb-lg-0">
          <v-card rounded="3">
            <div
              style="
                background-color: #ee2b34;
                padding: 3px;
                border-radius: 10px 10px 0px 0px;
              "
            >
              <div class="row p-2 align-center" style="color: white">
                <div class="col-6">
                  <div>
                    <v-icon size="20px"> mdi-filter </v-icon><span>|</span>
                    <span style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.filterBy") }}</span
                    >
                  </div>
                </div>
                <div class="col-6" align="right">
                  <div style="">
                    <button @click ="resetAllFilters()" style="font-weight:500;">Reset All</button></div>
                </div>
                <div class="col-2 filter-toggle" align="center">
                  <v-icon
                    size="30"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    @click="isIcon = !isIcon"
                    style="overflow-anchor: none"
                    >{{
                      isIcon
                        ? "mdi-chevron-up-circle-outline"
                        : "mdi-chevron-down-circle-outline"
                    }}</v-icon
                  >
                </div>
              </div>
            </div>

            <div
              class="filter_icons"
              style="padding: 10px"
              :class="{ collapse: !isIcon, show: isIcon }"
              aria-expanded="false"
              :id="{ 'collapse-Example': isIcon }"
            >
              <div>
                <br />

                <div class="row gy-0" v-if="showNonStop">
                  <div class="col-8">
                    <v-checkbox
                      label="Non Stop"
                      v-model="NonStopFil"
                      @change="applyFilters"
                      :disabled="!hasNonStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="`1 ${this.$t(
                        'searchPageContent.itinContent.stops'
                      )}`"
                      v-model="OneStopFil"
                      @change="applyFilters"
                      :disabled="!hasOneStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneplusStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="`1 + ${this.$t(
                        'searchPageContent.itinContent.stops'
                      )}`"
                      v-model="OnePlusFil"
                      @change="applyFilters"
                      :disabled ="!hasOnePlusStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <!-- <hr />
                <div class="stop mb-3">Baggage</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Baggage Inclusive" color="primary"></v-checkbox>
                  </div>
                  <div class="col-4" align="center">
                  <div>(100)</div>
                </div>
                </div> -->

                <hr />
                <div class="stop mb-3">
                  {{ $t("searchPageContent.filterContent.fareType") }}
                </div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox
                      label="Refundable"
                      color="primary"
                      v-model="RefundableData"
                      @change="applyFilters"
                      :disabled="!hasRefundable"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <v-checkbox
                      label="Non Refundable"
                      color="primary"
                      v-model="nonRefundableData"
                      @change="applyFilters"
                      :disabled="!hasNonRefundable"
                    ></v-checkbox>
                  </div>
                </div>

                <hr />
                <div class="stop">
                  {{ $t("searchPageContent.filterContent.priceRange") }}
                  <span style="font-size: 13px">
                    ({{
                      $t("searchPageContent.filterContent.avgperPax")
                    }})</span
                  >
                </div>
                <div class="row me-1">
                  <v-range-slider
                    v-model="changevalue"
                    @change="applyFilters"
                    :min="minimum"
                    :max="maximum"
                    :step="steps"
                    thumb-label
                    class="price_slider"
                    color="#0c4ca3"
                  ></v-range-slider>
                </div>
                <div class="row">
                  <div class="col-6" align="center">
                    <p>{{ currencyCode }} {{ changevalue[0] }}</p>
                  </div>
                  <div class="col-6" align="center">
                    <p>{{ currencyCode }} {{ changevalue[1] }}</p>
                  </div>
                </div>

                <hr />

                <div class="my-2" v-if="this.onedatafil || this.rounddatafil">
                  <h5>
                    {{ $t("searchPageContent.filterContent.onwardjourney") }}
                  </h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.depFrom") }}
                      {{ FilterTimeDepname }}
                    </div>
                  </div>

                 <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilter4()" class="me-1">
                      <v-card
                        :color="timeFilterData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="timeFilterData4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            before 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter1()" class="me-1">
                      <v-card
                        :color="timeFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="timeFilterData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                      @click="TimeFilter2()" class="me-1">
                      <v-card

                        :color="timeFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="timeFilterData2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div
                      @click="TimeFilter3()">
                      <v-card

                        :color="timeFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="timeFilterData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.arrivalAt") }}
                      {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                     <div @click="TimeFilterArr4()" class="me-1">
                      <v-card
                        :color="this.timeFilterArrData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="
                              this.timeFilterArrData4 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            before 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterArr1()" class="me-1">
                      <v-card
                        :color="this.timeFilterArrData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="
                              this.timeFilterArrData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterArr2()" class="me-1">
                      <v-card
                        :color="this.timeFilterArrData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="
                              this.timeFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterArr3()">
                      <v-card
                        :color="this.timeFilterArrData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class=""
                            :color="
                              this.timeFilterArrData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="my-2" v-if="this.rounddatafil">
                  <h5>
                    {{ $t("searchPageContent.filterContent.returnjourney") }}
                  </h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.depFrom") }}
                      {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilter4()" class="me-1">
                      <v-card
                        :color="this.timearrFilterData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterData4 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            before 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter1()" class="me-1">
                      <v-card
                        :color="this.timearrFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter2()" class="me-1">
                      <v-card
                        :color="this.timearrFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter3()">
                      <v-card
                        :color="this.timearrFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.arrivalAt") }}
                      {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilterarr4()" class="me-1">
                      <v-card
                        :color="
                          this.timearrFilterArrData4 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterArrData4 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            before 6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr1()" class="me-1">
                      <v-card
                        :color="
                          this.timearrFilterArrData1 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterArrData1 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr2()" class="me-1">
                      <v-card
                        :color="
                          this.timearrFilterArrData2 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr3()">
                      <v-card
                        :color="
                          this.timearrFilterArrData3 ? '#bae9e45e' : 'white'
                        "
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-0"
                            :color="
                              this.timearrFilterArrData3 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            after 6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>
                <div class="my-2" v-if="this.onedatafil || this.rounddatafil">
                  <div>
                 <div>
                  <h6>Airports</h6>
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"></v-img>Depature</span>
                    {{console.log(fromAirportName,'Airpporttanenm')}}
                  <div v-for="(flight,index) in fromAirportName" :key="index">
                    <v-checkbox
                    :value="flight"
                    :label="flight"
                    v-model="selectedFromAirports"
                    @change="applyFilters"
                    ></v-checkbox>
                  </div>
                 </div>
                 <hr v-if="isReturn" style="margin-bottom:12px !important;margin-top:12px !important;"/>
                 <div v-if="isReturn">
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"
                  style="transform:rotate(180deg);"></v-img>Arrival</span>
                  <div v-for="(val,index) of toAirportName" :key="index">
                    <v-checkbox
                    :label="val"
                    :value="val"
                    v-model="selectedToAirports"
                    @change="applyFilters"
                    ></v-checkbox>
                  </div>
                 </div>
                 </div>
                 <hr style="margin-bottom:12px !important;margin-top:12px !important;"/>
                  <div>
                  <h6>Flight Duration</h6>
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"></v-img>OutBound</span>
                  <div class="outbound mt-2">
                    <v-select
                      :items="outBoundTime"
                      v-model="defaultOutBoundTime"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                </div>
                <hr v-if="isReturn" style="margin-bottom:12px !important;margin-top:12px !important;"/>
                <div v-if="isReturn">
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2" style="transform:rotate(180deg);"></v-img>InBound</span>
                  <div class="inbound mt-2">
                    <v-select
                      :items="inBoundTime"
                      v-model="defaultInBoundTime"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                </div>
                <hr style="margin-bottom:12px !important;margin-top:12px !important;">
                <div>
                <div
                  class="stop"
                  v-if="this.multicityData.length > 0 ? false : true"
                >
                  {{ $t("searchPageContent.filterContent.airlines") }}
                </div>
                <div
                  class="row mb-4"
                  style="font-size: 14px; cursor: pointer"
                  v-if="this.multicityData.length > 0 ? false : true"
                >
                  <div class="col-md-6 ps-3" @click="airlineSelect()">
                    {{ $t("searchPageContent.filterContent.selectAll") }}
                  </div>
                  <div class="col-md-6 text-end pe-3" @click="airlineClear()">
                    {{ $t("searchPageContent.filterContent.clearAll") }}
                  </div>
                </div>

                <div
                  class="row"
                  v-for="(data, index) of airlineList.slice(0, maxItemsToShow)"
                  :key="index"
                >
                  <div class="col-12">
                    <v-checkbox
                      class="text-truncate"
                      :label="data.airline"
                      color="primary"
                      v-model="data.active"
                      @click="selectAirline(data, index)"
                      @change="applyFilters"
                    >
                    </v-checkbox>
                  </div>
                </div>

                <div v-if="airlineList.length > 5">
                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="!readMore"
                  >
                    ( +{{ airlineList.length - maxItemsToShow }} More )
                  </p>

                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="readMore"
                  >
                    ( Show less )
                  </p>
                </div>
                </div>

                </div>
                <div v-if="multifil">
                  <div class ="mt-2">
                   <h6>Flight Duration</h6>
                  <span class="d-inline-flex"><v-img src="../assets/airplane.png" width="20px" class="me-2"></v-img>OutBound</span>
                  <div class="outbound mt-2">
                    <v-select
                      :items="outBoundTimeMulti"
                      v-model="defaultOutBoundMulti"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                  </div>
                  <hr style="margin-bottom:12px !important;margin-top:12px !important;"/>
                  <div
                  class="stop"
                  v-if="this.multicityData.length > 0 ? true : false"
                >
                  {{ $t("searchPageContent.filterContent.airlines") }}
                </div>
                <div
                  class="row mb-4"
                  style="font-size: 14px; cursor: pointer"
                  v-if="this.multicityData.length > 0 ? true : false"
                >
                  <div class="col-md-6 ps-3" @click="airlineSelect()">
                    {{ $t("searchPageContent.filterContent.selectAll") }}
                  </div>
                  <div class="col-md-6 text-end pe-3" @click="airlineClear()">
                    {{ $t("searchPageContent.filterContent.clearAll") }}
                  </div>
                  
                </div>
                <div
                  class="row"
                  v-for="(data, index) of airlineList.slice(0, maxItemsToShow)"
                  :key="index"
                >
                  <div class="col-12">
                    <v-checkbox
                      class="text-truncate"
                      :label="data.airline"
                      color="primary"
                      v-model="data.active"
                      @click="selectAirline(data, index)"
                      @change="applyFilters"
                    >
                    </v-checkbox>
                  </div>
                </div>

                <div v-if="airlineList.length > 5">
                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="!readMore"
                  >
                    ( +{{ airlineList.length - maxItemsToShow }} {{ $t("searchPageContent.filterContent.more") }} )
                  </p>

                  <p
                    class="m-0"
                    style="cursor: pointer; color: #3366cc; font-size: 14px"
                    @click="showMore"
                    v-if="readMore"
                  >
                    ( {{ $t("searchPageContent.filterContent.showless") }} )
                  </p>
                </div>
                  <div v-for="(flight, index) in multiAirport" :key="index" style="display:none;">
                    <p class="mb-0 mt-2" style="font-size:16px;font-weight:500">Flight{{index + 1}}</p>
                    <div>
                      <v-checkbox
                      :value="flight.from"
                      :label="flight.from"
                      v-model="multiFromAirports"
                      @change="applyFilters"
                      ></v-checkbox>
                    </div>
                    <div>
                      <v-checkbox
                      :value="flight.to"
                      :label="flight.to"
                      v-model="multiToAirports"
                      @change="applyFilters"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>

                
                <br />
              </div>
            </div>
          </v-card>
        </div>
        <div class="col-lg-9">
          <div class="row mb-3 gx-0" style="cursor: pointer">
            <div
              class="col-md-3 col-6 rec-bottom"
              :class="active ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Recommend()"
              >
                <div>
                  <v-icon
                    :style="active ? 'color:#ee2b34' : 'color:grey'"
                    size="40"
                    >mdi-seal</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">
                    {{ $t("searchPageContent.filterContent.recommended") }}
                  </div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active1 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="bestlayTime(1)"
              >
                <div>
                  <v-icon
                    :style="active1 ? 'color:#ee2b34' : 'color:grey'"
                    size="40"
                    >mdi-star</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">
                    {{ $t("searchPageContent.filterContent.best") }}
                  </div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ layperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active2 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Cheapest(1)"
              >
                <div>
                  <v-icon
                    :style="active2 ? 'color:#ee2b34' : 'color:grey'"
                    size="40"
                    >mdi-currency-usd</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">
                    {{ $t("searchPageContent.filterContent.cheapest") }}
                  </div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6 rec-bottom1"
              :class="active3 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Shortest(1)"
              >
                <div>
                  <v-icon
                    :style="active3 ? 'color:#ee2b34' : 'color:grey'"
                    size="40"
                    >mdi-clock</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">
                    {{ $t("searchPageContent.filterContent.shortest") }}
                  </div>
                  <div class="top_filterprice">
                    {{ shorCurrecy }} {{ shotperperson }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3" v-for="(res, index) of rountresult" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <!-- <br />
                      {{ getid(res.Depature) }} -
                      {{ getNumber(res.Depature) }} -->
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div
                    class="row g-0 px-3 align-center w-100 justify-content-between"
                  >
                    <div class="col-3 d-flex align-center">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-5 ps-2">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 1px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-4 ps-2 d-flex align-center justify-end">
                      <v-btn
                        color="#ee2b34"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }}</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 Timing-section" style="padding: 10px 5px">
                  <div class="mt-2">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <!-- <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radioData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px; width: 100%"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end text-nowrap mt-sm-3 mt-md-0"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getAirportCode(res.Depature) }} -->
                                {{ getCountryName1(tripData.from) }}
                              </p>
                              <p
                                class="text-end d-sm-none d-md-block d-lg-block"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 15px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ getarrtime(res.Depature) }}</span
                                        >
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ getarrtime(res.Depature) }}</span
                                        ><br />
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ get2arrtime(res.Depature) }}</span
                                        >
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <!-- <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div> -->

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 15px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-nowrap mt-sm-3 mt-md-0"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getArrivalCode(res.Depature) }} -->
                                {{ getCountryName2(tripData.to) }}
                              </p>
                              <p
                                class="d-sm-none d-md-block d-lg-block"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameTo(res.Return) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>

                      <div v-if="res.multipleDatas">
                        <div
                          v-for="(data, multindex) of multiDepatureData"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multione(data, multindex, index)">
                              <v-radio-group v-model="data.radios">
                                <v-radio :value="true"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100%; border-radius: 10px">
                              <div class="row p-2">
                                <div
                                  class="col-md-3"
                                  style="border-right: 1px dotted gray"
                                >
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName1(tripData.from) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getAirportNameFrom(data.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-6 col-8"
                                  style="border-right: 1px dotted gray"
                                >
                                  <div class="row g-0 mt-1 justify-center">
                                    <div class="col-md-4 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getDate(data.Depature) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getDateDeparture(data.Depature) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 500;
                                          font-size: 15px;
                                        "
                                      >
                                        {{ getTime(data.Depature) }}
                                      </p>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops == 0"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        Direct
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops == 1"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Depaturejourney.Stops }} Stop
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        id="tooltip"
                                      >
                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon size="8">mdi-circle</v-icon>

                                        <div
                                          v-if="data.Depature.length >= 2"
                                          class="hoverData1"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Depature)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Depaturejourney.Stops >= 2"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Depaturejourney.Stops }} Stops
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        id="tooltip"
                                      >
                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon size="8">mdi-circle</v-icon>

                                        <div
                                          v-if="data.Depature.length >= 2"
                                          class="hoverDataStop1"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Depature)
                                            }}</span>
                                            <span> | </span>
                                            <span
                                              style="
                                                font-size: 12px;
                                                font-weight: 600;
                                              "
                                              >{{
                                                getarrtime(data.Depature)
                                              }}</span
                                            ><br />
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Depature, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 15%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon size="8">mdi-circle</v-icon>
                                        <!-- <div
                                          v-if="data.Depature.length >= 3"
                                          class="hoverDataStop2"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName2,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Depature, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Depature)
                                            }}</span>
                                          </div>
                                        </div> -->

                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Depaturejourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getArrivaldate(data.Depature) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{
                                          getArrivaldateReturn(data.Depature)
                                        }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 500;
                                          font-size: 15px;
                                        "
                                      >
                                        {{ getArrivalTime(data.Depature) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName2(tripData.to) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getretairname(data.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>

                    <br />

                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <!-- <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radiretData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px; width: 100%"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end text-nowrap mt-sm-3 mt-md-0"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getreturnAirportCode(res.Return) }} -->
                                {{ getCountryName2(tripData.to) }}
                              </p>

                              <p
                                class="text-end d-sm-none d-md-block d-lg-block"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameTo(res.Return) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getreturnAirportCode(res.Return) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getreturnDate(res.Return) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getreturnDateDeparture(res.Return) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 15px"
                                  >
                                    {{ getreturnTime(res.Return) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>
                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Returnjourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    id="tooltip"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData2"
                                      :class="{ visible: res.hoveredName3 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ getreturntime(res.Return) }}</span
                                        >
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Returnjourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Returnjourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    id="tooltip"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      :class="{ visible: res.hoveredName3 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getreturndata(res.Return)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ getreturntime(res.Return) }}</span
                                        ><br />
                                        <span style="font-size: 12px">{{
                                          get1returntime(res.Return, index)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{
                                            get2returntime(res.Return)
                                          }}</span
                                        >
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>
                                    <!-- <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName4 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1returntime(res.Return, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2returntime(res.Return)
                                        }}</span>
                                      </div>
                                    </div> -->

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Returnjourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getreturnArrivaldate(res.Return) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getreturnArrivaldateReturn(res.Return) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 15px"
                                  >
                                    {{ getreturnArrivalTime(res.Return) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="res-time text-nowrap mt-sm-3 mt-md-0"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                <!-- {{ getreturnArrivalcode(res.Return) }} -->
                                {{ getCountryName1(tripData.from) }}
                              </p>

                              <p
                                class="res-time d-sm-none d-md-block d-lg-block"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="res-time text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getreturnArrivalcode(res.Return) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                      <div v-if="res.multipleDatas">
                        <div
                          v-for="(data, multindex) of multireturnData"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multiredone(data, multindex, index)">
                              <v-radio-group v-model="data.radiosret">
                                <v-radio :value="true"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100%; border-radius: 10px">
                              <div class="row p-2">
                                <div
                                  class="col-md-3"
                                  style="border-right: 1px dotted gray"
                                >
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName1(tripData.to) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getAirportNameFrom(data.Return) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-6 col-8"
                                  style="border-right: 1px dotted gray"
                                >
                                  <div class="row g-0 mt-1 justify-center">
                                    <div class="col-md-4 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getDate(data.Return) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getDateDeparture(data.Return) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 500;
                                          font-size: 15px;
                                        "
                                      >
                                        {{ getTime(data.Return) }}
                                      </p>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops == 0"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        Direct
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops == 1"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Returnjourney.Stops }} Stop
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Return.length >= 2"
                                          class="hoverData1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Return)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 40%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div
                                      class="col-md-4 col-6 d-flex flex-column justify-center"
                                      v-if="data.Returnjourney.Stops >= 2"
                                    >
                                      <p class="res-stop m-0 text-center">
                                        {{ data.Returnjourney.Stops }} Stops
                                      </p>

                                      <div
                                        class="d-flex align-center justify-center my-2"
                                        style="position: relative"
                                      >
                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          >mdi-circle</v-icon
                                        >

                                        <div
                                          v-if="data.Return.length >= 2"
                                          class="hoverDataStop1"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName1,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              getarrivaldata(data.Return)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              getarrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 15%;
                                            border: 1px solid black;
                                          "
                                        />

                                        <v-icon
                                          size="8"
                                          @mouseover="showDetails2(data, index)"
                                          @mouseout="hideDetails2(data)"
                                          >mdi-circle</v-icon
                                        >
                                        <div
                                          v-if="data.Return.length >= 3"
                                          class="hoverDataStop2"
                                          id="tooltip"
                                          :class="{
                                            visible: data.hoveredName2,
                                          }"
                                        >
                                          <div style="text-align: center"></div>
                                          <div>
                                            <span style="font-size: 12px">{{
                                              get1arrtime(data.Return, index)
                                            }}</span>
                                            <span> | </span>
                                            <span style="font-size: 12px">{{
                                              get2arrtime(data.Return)
                                            }}</span>
                                          </div>
                                        </div>

                                        <hr
                                          style="
                                            width: 30%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div>

                                      <div
                                        class="d-flex justify-center time-section"
                                      >
                                        <v-icon color="grey"
                                          >mdi-clock-outline
                                        </v-icon>
                                        <span>{{
                                          data.Returnjourney.Time
                                        }}</span>
                                      </div>
                                    </div>

                                    <div class="col-md-3 col-3">
                                      <p
                                        class="res-time text-center m-0 date-section-hide"
                                      >
                                        {{ getArrivaldate(data.Return) }}
                                      </p>
                                      <p
                                        class="res-time text-center m-0 date-section-show"
                                      >
                                        {{ getArrivaldateReturn(data.Return) }}
                                      </p>
                                      <p
                                        class="text-center m-0"
                                        style="
                                          font-weight: 500;
                                          font-size: 15px;
                                        "
                                      >
                                        {{ getArrivalTime(data.Return) }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 15px; font-weight: 500"
                                  >
                                    {{ getCountryName2(tripData.from) }}
                                  </p>
                                  <p
                                    class="text-end mb-0"
                                    style="font-size: 10px; font-weight: 500"
                                  >
                                    {{ getretairname(data.Return) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0 justify-content-around">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{
                          getdepseat(res.Depature) >= getretseat(res.Return)
                            ? getdepseat(res.Return)
                            : getretseat(res.Depature)
                        }}
                        Seats</span
                      >
                    </div> -->

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex flex-lg-row flex-column align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex flex-lg-row flex-column justify-center align-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        this.oneway.class
                      }}</span>
                    </div>

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex align-center justify-center"
                      @click="flighted1(res, index)"
                    >
                      <span
                        style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                      >
                        {{
                          $t("searchPageContent.itinContent.flightDetails")
                        }}</span
                      >

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>
                    <div class="col-md-3 col-6 d-lg-none justify-center">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0 text-center"
                              v-bind="props"
                            >
                              {{ $t("fareDetContent.fareDetails") }}
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                {{ $t("fareDetContent.totalPrice") }}
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >{{ $t("fareDetContent.passengers") }}</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  {{ $t("fareDetContent.basePrice") }}<br />
                                  {{ $t("fareDetContent.taxesFees") }}
                                </div>
                                <div class="col-6 f-size-14">
                                  <div class="d-flex flex-row justify-end">
                                    <div class="col-5 text-end pe">
                                      {{ res.currency }} <br />
                                      {{ res.currency }}
                                    </div>
                                    <div class="col-7 text-end pe-2">
                                      {{ res.Baseprice }}<br />
                                      {{ res.Taxprice }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                    <div
                      class="col-md-3 col-4 d-none"
                      @click="addmoreroundData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div
                      class="text-center d-none flex-row justify-content-between fare-detail-mobile"
                    >
                      <div class="col-6 justify-center">
                        <v-menu location="bottom">
                          <template v-slot:activator="{ props }">
                            <div class="d-flex align-center">
                              <v-card-text
                                @click="fareOpen = !fareOpen"
                                style="color: #36c; font-weight: 500"
                                class="pe-0 text-center"
                                v-bind="props"
                              >
                                {{ $t("fareDetContent.fareDetails") }}
                              </v-card-text>
                              <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                            </div>
                          </template>

                          <v-card
                            width="250px"
                            max-height="500px"
                            class="pa-3 m-auto"
                            style="border: 1px solid gray"
                          >
                            <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                            <div class="d-flex justify-content-between">
                              <div>
                                <p
                                  class="m-0"
                                  style="font-size: 16px; font-weight: 500"
                                >
                                  {{ $t("fareDetContent.totalPrice") }}
                                </p>
                              </div>
                              <div>
                                <p class="tot-price m-0">
                                  {{ res.currency }}
                                  {{ res.TotalPrice }}
                                </p>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-12">
                                <div class="row justify-content-between">
                                  <div class="col-6">
                                    <span
                                      style="font-size: 13px; font-weight: 500"
                                      >{{
                                        $t("fareDetContent.passengers")
                                      }}</span
                                    >
                                  </div>
                                  <!-- <div class="col-md-4"></div> -->
                                  <div
                                    class="col-6"
                                    style="font-size: 14px; text-align: end"
                                  >
                                    <span v-if="aduls > 0"
                                      >{{ aduls }} x Adult</span
                                    ><br />
                                    <span v-if="aduls1 > 0"
                                      >{{ aduls1 }} x Child</span
                                    ><br />
                                    <span v-if="aduls2 > 0"
                                      >{{ aduls2 }} x Lap Infant</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3">
                              <hr class="mt-1 mb-2" />
                              <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                              <hr class="mt-1 mb-2" />

                              <div
                                class="p-1 collapse"
                                :class="{ show: fareIcon }"
                                id="collapse-Example"
                              >
                                <div class="row">
                                  <div class="col-6" style="font-size: 14px">
                                    {{ $t("fareDetContent.basePrice") }}<br />
                                    {{ $t("fareDetContent.taxesFees") }}
                                  </div>
                                  <!-- <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div> -->
                                  <div class="col-6 f-size-14">
                                    <div class="d-flex flex-row justify-end">
                                      <div class="col-5 text-end pe">
                                        {{ res.currency }} <br />
                                        {{ res.currency }}
                                      </div>
                                      <div class="col-7 text-end pe-2">
                                        {{ res.Baseprice }}<br />
                                        {{ res.Taxprice }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                            </div>
                          </v-card>
                        </v-menu>
                      </div>
                      <div
                        class="col-6 d-flex align-center justify-center"
                        @click="flighted1(res, index)"
                      >
                        <span
                          style="
                            color: #36c;
                            font-size: 12px;
                            margin-top: 2px;
                            cursor: pointer;
                          "
                        >
                          {{
                            $t("searchPageContent.itinContent.flightDetails")
                          }}</span
                        >

                        <v-icon
                          style="color: #36c; right: 0px !important"
                          size="18"
                          >{{
                            res.Flightdepature
                              ? "mdi-close-box"
                              : "mdi-chevron-down-box"
                          }}</v-icon
                        >
                      </div>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-content-between select-section-hide px-2"
                  style="
                    background-color: rgb(186 233 229 / 37%);
                    border-radius: 0px 10px 10px 0px !important;
                  "
                >
                  <div>
                    <p
                      class="tect-center"
                      style="
                        color: #36c;
                        font-size: 14px;
                        font-weight: 600;
                        margin-top: 2px;
                        cursor: pointer;
                        visibility: hidden;
                      "
                    >
                      Price Details
                    </p>
                  </div>

                  <div
                    class="d-flex flex-column align-center justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          ><span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >Per Person</span
                    >
                    <v-btn
                      color="#ee2b34"
                      width="125px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }}</v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          {{ $t("fareDetContent.fareDetails") }}
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              {{ $t("fareDetContent.totalPrice") }}
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span
                                  style="font-size: 14px; font-weight: 500"
                                  >{{ $t("fareDetContent.passengers") }}</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                {{ $t("fareDetContent.basePrice") }}<br />
                                {{ $t("fareDetContent.taxesFees") }}
                              </div>
                              <!-- <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div> -->
                              <div class="col-6 f-size-14">
                                <div class="d-flex flex-row justify-end">
                                  <div class="col-5 text-end pe">
                                    {{ res.currency }} <br />
                                    {{ res.currency }}
                                  </div>
                                  <div class="col-7 text-end pe-2">
                                    {{ res.Baseprice }}<br />
                                    {{ res.Taxprice }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-md-12 col-12">
                  <v-card class="mt-2" v-if="res.Flightdepature">
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Depature) }} -
                              {{ getArrivalCode(res.Depature) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}
                              :
                              {{ flightdetailsData.Depaturejourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of flightdetailsData.Depature"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ oneway.class }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-3"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.depBaggage.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.depBaggage.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.depBaggage.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.depBaggage.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.depBaggage.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.depBaggage.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.depBaggage.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{
                                        $t(
                                          "searchPageContent.flightDetails.layover"
                                        )
                                      }}
                                      {{ res.layoverTimes1[index] }} ({{
                                        data.Arrival.AirportCode
                                      }})</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size"
                                v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} {{ $t("searchPageContent.flightDetails.layover") }}
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="res.Flightdepature">
                      <div class="mt-2">
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(-90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getreturnAirportCode(res.Return) }} -
                              {{ getreturnArrivalcode(res.Return) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}
                              : {{ flightdetailsData.Returnjourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of flightdetailsData.Return"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated2(data.Departure.Date)
                                    }}</span>

                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep1(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >,
                                    {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated3(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarrival(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500">{{
                                      data.Arrival.AirportCode
                                    }}</span>
                                    , {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ oneway.class }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-3"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.arrBaggage.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.arrBaggage.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.arrBaggage.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.arrBaggage.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.arrBaggage.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.arrBaggage.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.arrBaggage.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>
                            </div>

                            <div class="d-flex position-relative mt-3">
                              <hr
                                v-if="index !== res.Return.length - 1"
                                style="
                                  width: 97%;
                                  position: absolute;
                                  margin-top: 8px !important;
                                  margin-bottom: 0px !important;
                                "
                              />
                              <div
                                class="d-flex justify-center"
                                v-if="index !== res.Return.length - 1"
                                style="
                                  width: 180px;
                                  background: antiquewhite;
                                  border-radius: 18px;
                                  z-index: 1;
                                  margin: auto;
                                "
                              >
                                <div class="d-flex align-center">
                                  <span class="f-size-10"
                                    >{{
                                      $t(
                                        "searchPageContent.flightDetails.layover"
                                      )
                                    }}
                                    {{ res.layoverTimes2[index] }} ({{
                                      data.Arrival.AirportCode
                                    }})</span
                                  >
                                </div>
                              </div>
                            </div>

                            <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="index !== res.Return.length - 1" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size" v-if="index !== res.Return.length - 1"
                                style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} {{ $t("searchPageContent.flightDetails.layover") }}
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="index !== res.Return.length - 1" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of onewayresulted" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="60px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div
                    class="row g-0 px-3 align-center w-100 justify-content-between"
                  >
                    <div class="col-3 d-flex align-center">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>
                    <div class="col-5">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-4 ps-2 d-flex align-center justify-end">
                      <v-btn
                        color="#ee2b34"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="onewayselect(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }}</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 Timing-section" style="padding: 10px 5px">
                  <v-card class="mutilti_data">
                    <div
                      class="mt-2 d-flex"
                      style="display: flex; align-items: center"
                    >
                      <!-- <div
                        style="display: flex; align-items: center"
                        @click="radiosData = 0"
                      >
                        <v-radio-group v-model="res.radios">
                          <v-radio :value="true"></v-radio>
                        </v-radio-group>
                      </div> -->
                      <div style="width: 100%">
                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px"
                        >
                          <div class="row mt-2 g-0 mb-2">
                            <div
                              class="col-md-3 col-2 pe-2 aircode-hide"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-end text-nowrap mt-sm-3 mt-md-0"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getCountryName1(tripData.from) }}
                              </p>
                              <p
                                class="text-end d-sm-none d-md-block d-lg-block"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportNameFrom(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 pe-2 aircode-show"
                              style="border-right: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 15px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    id="tooltip"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData2"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ getarrtime(res.Depature) }}</span
                                        >
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    id="tooltip"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ getarrtime(res.Depature) }}</span
                                        ><br />
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 600;
                                          "
                                          >{{ get2arrtime(res.Depature) }}</span
                                        >
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon size="8">mdi-circle</v-icon>
                                    <!-- <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div> -->

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 15px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-hide"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-nowrap mt-sm-3 mt-md-0"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getCountryName2(tripData.to) }}
                              </p>
                              <p
                                class="d-sm-none d-md-block d-lg-block"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 10px;
                                  font-weight: 500;
                                "
                              >
                                {{ getretairname(res.Depature) }}
                              </p>
                            </div>

                            <div
                              class="col-md-3 col-2 ps-2 aircode-show"
                              style="border-left: 1px dotted gray"
                            >
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <!-- ------------multi Start-------- -->

                    <div v-if="res.multipleDatas">
                      <div
                        v-for="(data, multindex) of multiplesameData"
                        :key="multindex"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                            border-top: 1px solid lightgray;
                          "
                          v-if="multindex !== 0"
                        >
                          <div @click="multione1(data, multindex, index)">
                            <v-radio-group v-model="radiosData">
                              <v-radio :value="multindex + 1"></v-radio>
                            </v-radio-group>
                          </div>
                          <div style="width: 100%; border-radius: 10px">
                            <div class="row p-2">
                              <div
                                class="col-md-3"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName1(tripData.from) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getAirportNameFrom(data.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-6 col-8"
                                style="border-right: 1px dotted gray"
                              >
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getTime(data.Depature) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(data, index)"
                                        @mouseout="hideDetails2(data)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="data.Depature.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(data.Depature, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getArrivalTime(data.Depature) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName2(tripData.to) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getretairname(data.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <!-- ------------multi end-------- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0 justify-content-around">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{ getdepseat(res.Depature) }} Seats</span
                      >
                    </div> -->

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex flex-lg-row flex-column align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex flex-lg-row flex-column align-center justify-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        this.oneway.class
                      }}</span>
                    </div>

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex align-center justify-center"
                      @click="flighted2(res, index)"
                    >
                      <span
                        style="color: #36c; font-size: 12px; cursor: pointer"
                      >
                        {{
                          $t("searchPageContent.itinContent.flightDetails")
                        }}</span
                      >

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>
                    <div class="col-md-3 col-6 d-lg-none justify-center">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0 text-center"
                              v-bind="props"
                            >
                              {{ $t("fareDetContent.fareDetails") }}
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                {{ $t("fareDetContent.totalPrice") }}
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >{{ $t("fareDetContent.passengers") }}</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  {{ $t("fareDetContent.basePrice") }}<br />
                                  {{ $t("fareDetContent.taxesFees") }}
                                </div>
                                <div class="col-6 f-size-14">
                                  <div class="d-flex flex-row justify-end">
                                    <div class="col-5 text-end pe">
                                      {{ res.currency }} <br />
                                      {{ res.currency }}
                                    </div>
                                    <div class="col-7 text-end pe-2">
                                      {{ res.Baseprice }}<br />
                                      {{ res.Taxprice }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>

                    <div
                      class="col-md-3 col-4 d-none"
                      @click="addmoreData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div
                      class="text-center d-none flex-row justify-content-between fare-detail-mobile"
                    >
                      <div class="col-6 justify-center">
                        <v-menu location="bottom">
                          <template v-slot:activator="{ props }">
                            <div class="d-flex align-center">
                              <v-card-text
                                @click="fareOpen = !fareOpen"
                                style="color: #36c; font-weight: 500"
                                class="pe-0"
                                v-bind="props"
                              >
                                {{ $t("fareDetContent.fareDetails") }}
                              </v-card-text>
                              <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                            </div>
                          </template>

                          <v-card
                            width="250px"
                            max-height="500px"
                            class="pa-3 m-auto"
                            style="border: 1px solid gray"
                          >
                            <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                            <div class="d-flex justify-content-between">
                              <div>
                                <p
                                  class="m-0"
                                  style="font-size: 16px; font-weight: 500"
                                >
                                  {{ $t("fareDetContent.totalPrice") }}
                                </p>
                              </div>
                              <div>
                                <p class="tot-price m-0">
                                  {{ res.currency }}
                                  {{ res.TotalPrice }}
                                </p>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-12">
                                <div class="row justify-content-between">
                                  <div class="col-6">
                                    <span
                                      style="font-size: 13px; font-weight: 500"
                                      >{{
                                        $t("fareDetContent.passengers")
                                      }}</span
                                    >
                                  </div>
                                  <!-- <div class="col-md-4"></div> -->
                                  <div
                                    class="col-6"
                                    style="font-size: 14px; text-align: end"
                                  >
                                    <span v-if="aduls > 0"
                                      >{{ aduls }} x Adult</span
                                    ><br />
                                    <span v-if="aduls1 > 0"
                                      >{{ aduls1 }} x Child</span
                                    ><br />
                                    <span v-if="aduls2 > 0"
                                      >{{ aduls2 }} x Lap Infant</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="mt-3">
                              <hr class="mt-1 mb-2" />
                              <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                              <hr class="mt-1 mb-2" />

                              <div
                                class="p-1 collapse"
                                :class="{ show: fareIcon }"
                                id="collapse-Example"
                              >
                                <div class="row">
                                  <div class="col-6" style="font-size: 14px">
                                    {{ $t("fareDetContent.basePrice") }}<br />
                                    {{ $t("fareDetContent.taxesFees") }}
                                  </div>
                                  <!-- <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div> -->
                                  <div class="col-6 f-size-14">
                                    <div class="d-flex flex-row justify-end">
                                      <div class="col-5 text-end pe">
                                        {{ res.currency }} <br />
                                        {{ res.currency }}
                                      </div>
                                      <div class="col-7 text-end pe-2">
                                        {{ res.Baseprice }}<br />
                                        {{ res.Taxprice }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                            </div>
                          </v-card>
                        </v-menu>
                      </div>
                      <div
                        class="col-6 d-flex align-center justify-center"
                        @click="flighted2(res, index)"
                      >
                        <span
                          style="color: #36c; font-size: 12px; cursor: pointer"
                        >
                          {{
                            $t("searchPageContent.itinContent.flightDetails")
                          }}</span
                        >

                        <v-icon style="color: #36c" size="18">{{
                          res.Flightdepature
                            ? "mdi-close-box"
                            : "mdi-chevron-down-box"
                        }}</v-icon>
                      </div>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-content-between select-section-hide"
                  style="background-color: rgb(186 233 229 / 37%)"
                >
                  <div class="d-flex flex-column align-center justify-center">
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          ><span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >Per Person</span
                    >
                    <v-btn
                      color="#ee2b34"
                      width="120px"
                      height="40px"
                      style="color: white"
                      @click.prevent="onewayselect(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }}</v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          class="p-0 pt-1"
                          v-bind="props"
                        >
                          {{ $t("fareDetContent.fareDetails") }}
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              {{ $t("fareDetContent.totalPrice") }}
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span
                                  style="font-size: 14px; font-weight: 500"
                                  >{{ $t("fareDetContent.passengers") }}</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                          <hr class="mt-1 mb-2" />

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                {{ $t("fareDetContent.basePrice") }}<br />
                                {{ $t("fareDetContent.taxesFees") }}
                              </div>
                              <!-- <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div> -->
                              <div class="col-6 f-size-14">
                                <div class="d-flex flex-row justify-end">
                                  <div class="col-5 text-end pe">
                                    {{ res.currency }} <br />
                                    {{ res.currency }}
                                  </div>
                                  <div class="col-7 text-end pe-2">
                                    {{ res.Baseprice }}<br />
                                    {{ res.Taxprice }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-md-12 col-12">
                  <v-card class="mt-2" v-if="res.Flightdepature">
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Depature) }} -
                              {{ getArrivalCode(res.Depature) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}:
                              {{ flightdetailsData.Depaturejourney.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of flightdetailsData.Depature"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ oneway.class }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-3"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.BaggageData.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.BaggageData.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Depature.length - 1 !== index"
                                  style="
                                    width: 180px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{
                                        $t(
                                          "searchPageContent.flightDetails.layover"
                                        )
                                      }}
                                      {{ res.layoverTimes[index] }} ({{
                                        data.Arrival.AirportCode
                                      }})</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="d-flex justify-content-between align-center">
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                              <div class="d-flex justify-center mx-2 layover-size"
                                v-if="res.Depature.length - 1 !== index" style="width: 20%">
                                <div class="d-flex align-center">
                                  <span class="f-size-11 lay-fsize">
                                    {{ data.layoverTimes }} {{ $t("searchPageContent.flightDetails.layover") }}
                                    Time</span>
                                </div>
                              </div>
                              <hr v-if="res.Depature.length - 1 !== index" style="width: 40%" class="mt-0 mb-0" />
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </div>
              </div>
            </v-card>
          </div>

          <div class="mb-3" v-for="(res, index) of multicityData" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <br />
                      {{ getid(res.Flight1) }} -
                      {{ getNumber(res.Flight1) }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div
                    class="row g-0 px-3 align-center w-100 justify-content-between"
                  >
                    <div class="col-3 d-flex align-center">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-5">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <!-- <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <span class="price-Amount">{{ res.perperson }}</span>
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{res.find1}}</span>

                            <div class="d-flex">
                              <span style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                ">.</span>
                                <span id="f1" style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                ">{{ res.find2 }}</span>
                            </div>
                          </div>
                        </div> -->

                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                "
                                >.</span
                              >
                              <span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-4 ps-2 d-flex align-center justify-end">
                      <v-btn
                        color="#ee2b34"
                        width="100px"
                        height="40px"
                        style="color: white"
                        @click.prevent="selected(res, index)"
                        >{{ $t("searchPageContent.itinContent.select") }}</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-8 Timing-section" style="padding: 10px 5px">
                  <!-- ------flight1 start----- -->
                  <div class="mt-2">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <!-- <div
                          style="display: flex; align-items: center"
                          @click="radioData1 = 0"
                        >
                          <v-radio-group v-model="res.radios1">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[0].from }}
                                </p>
                                <p
                                  class="text-end d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight1) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight1) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight1) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getTime(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight1JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight1JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight1.length >= 2"
                                        class="hoverData1"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight1)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight1) }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight1JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight1JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight1.length >= 2"
                                        class="hoverDataStop1"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight1)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight1) }}</span
                                          ><br />
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight1, index)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{
                                              get2arrtime(res.Flight1)
                                            }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>
                                      <!-- <div
                                        v-if="res.Flight1.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight1, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight1)
                                          }}</span>
                                        </div>
                                      </div> -->

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight1JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight1) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight1) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getArrivalTime(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  <!-- {{ getArrivalCode(res.Depature) }} -->
                                  {{ codeData[0].to }}
                                </p>
                                <p
                                  class="d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight1) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas1">
                        <div
                          v-for="(res, multindex) of multiflight1"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <!-- <div @click="multicityone1(res, multindex, index)">
                              <v-radio-group v-model="radioData1">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div> -->

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end text-nowrap mt-sm-3 mt-md-0"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[0].from }}
                                    </p>
                                    <p
                                      class="text-end d-sm-none d-md-block d-lg-block"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight1) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight1) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getTime(res.Flight1) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight1JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight1JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(res, index)"
                                          @mouseout="hideDetails1(res)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="res.Flight1.length >= 2"
                                            class="hoverData1"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight1)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight1)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight1JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight1JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(res, index)"
                                          @mouseout="hideDetails1(res)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="res.Flight1.length >= 2"
                                            class="hoverDataStop1"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight1)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight1)
                                              }}</span
                                              ><br />
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight1, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight1)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>
                                          <!-- <div
                                            v-if="res.Flight1.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight1, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight1)
                                              }}</span>
                                            </div>
                                          </div> -->

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight1JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight1) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight1)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight1) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-nowrap mt-sm-3 mt-md-0"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      <!-- {{ getArrivalCode(res.Depature) }} -->
                                      {{ codeData[0].to }}
                                    </p>
                                    <p
                                      class="d-sm-none d-md-block d-lg-block"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight1 end----- -->

                  <!-- ------flight2 start----- -->

                  <div class="mt-2">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <!-- <div
                          style="display: flex; align-items: center"
                          @click="radioData2 = 0"
                        >
                          <v-radio-group v-model="res.radios2">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 gx-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[1].from }}
                                </p>
                                <p
                                  class="text-end d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight2) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight2) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight2) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getTime(res.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight2JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight2JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverData1"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight2)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight2) }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight2JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight2JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverDataStop1"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight2)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight2) }}</span
                                          ><br />
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight2, index)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="font-size: 14px;font-weight"
                                            >{{
                                              get2arrtime(res.Flight2)
                                            }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>
                                      <!-- <div
                                        v-if="res.Flight2.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight2, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight2)
                                          }}</span>
                                        </div>
                                      </div> -->

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight2JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight2) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight2) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getArrivalTime(res.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[1].to }}
                                </p>
                                <p
                                  class="d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight2) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas2">
                        <div
                          v-for="(data, multindex) of multiflight2"
                          :key="multindex"
                        >
                          <!-- {{data.Flight2JourneyTime}} -->
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex > 0 ? true : false"
                          >
                            <div @click="multicityone2(data, multindex, index)">
                              <v-radio-group v-model="radioData2">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 gx-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end text-nowrap mt-sm-3 mt-md-0"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[1].from }}
                                    </p>
                                    <p
                                      class="text-end d-sm-none d-md-block d-lg-block"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(data.Flight2) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(data.Flight2) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getTime(data.Flight2) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops == 0
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ data.Flight2JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops == 1
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ data.Flight2JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="data.Flight2.length >= 2"
                                            class="hoverData1"
                                            :class="{
                                              visible: data.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(data.Flight2)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            data.Flight2JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops >= 2
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ data.Flight2JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(data, index)"
                                          @mouseout="hideDetails1(data)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="data.Flight2.length >= 2"
                                            class="hoverDataStop1"
                                            :class="{
                                              visible: data.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(data.Flight2)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(data.Flight2)
                                              }}</span>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(data.Flight2, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>
                                          <!-- <div
                                            v-if="data.Flight2.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(data.Flight2, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div> -->

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            data.Flight2JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(data.Flight2) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(data.Flight2)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getArrivalTime(data.Flight2) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-nowrap mt-sm-3 mt-md-0"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[1].to }}
                                    </p>
                                    <p
                                      class="d-sm-none d-md-block d-lg-block"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(data.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>

                  <!-- ------flight2 end----- -->

                  <!-- ------flight3 start----- -->
                  <div class="mt-2" v-if="res.Flight3 ? true : false">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <!-- <div
                          style="display: flex; align-items: center"
                          @click="radioData3 = 0"
                        >
                          <v-radio-group v-model="res.radios3">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->
                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[2].from }}
                                </p>
                                <p
                                  class="text-end d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight3) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight3) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight3) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight3) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getTime(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight3JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight3JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight3.length >= 2"
                                        class="hoverData1"
                                        :class="{ visible: res.hoveredName3 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight3)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight3) }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight3JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight3JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails3(res, index)"
                                      @mouseout="hideDetails3(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight3.length >= 2"
                                        class="hoverDataStop1"
                                        :class="{ visible: res.hoveredName3 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight3)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight3) }}</span
                                          ><br />
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight3, index)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{
                                              get2arrtime(res.Flight3)
                                            }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>
                                      <!-- <div
                                        v-if="res.Flight3.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight3, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight3)
                                          }}</span>
                                        </div>
                                      </div> -->

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight3JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight3) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight3) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getArrivalTime(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[2].to }}
                                </p>
                                <p
                                  class="d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight3) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight3) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas3">
                        <div
                          v-for="(res, multindex) of multiflight3"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone3(res, multindex, index)">
                              <v-radio-group v-model="radioData3">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end text-nowrap mt-sm-3 mt-md-0"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[2].from }}
                                    </p>
                                    <p
                                      class="text-end d-sm-none d-md-block d-lg-block"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight3) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight3) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getTime(res.Flight3) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight3JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight3JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(res, index)"
                                          @mouseout="hideDetails1(res)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="res.Flight3.length >= 2"
                                            class="hoverData1"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight3)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight3JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight3JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(res, index)"
                                          @mouseout="hideDetails1(res)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="res.Flight2.length >= 2"
                                            class="hoverDataStop1"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight3)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight3)
                                              }}</span>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight3, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>
                                          <!-- <div
                                            v-if="res.Flight3.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight3, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div> -->

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight3JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight3) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight3)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight3) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-nowrap mt-sm-3 mt-md-0"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[2].to }}
                                    </p>
                                    <p
                                      class="d-sm-none d-md-block d-lg-block"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight3 end----- -->

                  <!-- ------flight4 start----- -->
                  <div class="mt-2" v-if="res.Flight4 ? true : false">
                    <v-card class="mutilti_data">
                      <div style="display: flex; align-items: center">
                        <!-- <div
                          style="display: flex; align-items: center"
                          @click="radioData4 = 0"
                        >
                          <v-radio-group v-model="res.radios4">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div
                                class="col-md-3 col-2 pe-2 aircode-hide"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[3].from }}
                                </p>
                                <p
                                  class="text-end d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportNameFrom(res.Flight4) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 pe-2 aircode-show"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight4) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight4) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight4) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getTime(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight4JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight4JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails4(res, index)"
                                      @mouseout="hideDetails4(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight4.length >= 2"
                                        class="hoverData1"
                                        :class="{ visible: res.hoveredName4 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight4)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight4) }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight4JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight4JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                      @mouseover="showDetails4(res, index)"
                                      @mouseout="hideDetails4(res)"
                                      id="tooltip"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>

                                      <div
                                        v-if="res.Flight4.length >= 2"
                                        class="hoverDataStop1"
                                        :class="{ visible: res.hoveredName4 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight4)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{ getarrtime(res.Flight4) }}</span
                                          ><br />
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight4, index)
                                          }}</span>
                                          <span> | </span>
                                          <span
                                            style="
                                              font-size: 14px;
                                              font-weight: 600;
                                            "
                                            >{{
                                              get2arrtime(res.Flight4)
                                            }}</span
                                          >
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon size="8">mdi-circle</v-icon>
                                      <!-- @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)" -->
                                      <!-- <div
                                        v-if="res.Flight4.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight4, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight4)
                                          }}</span>
                                        </div>
                                      </div> -->

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight4JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight4) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight4) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 500; font-size: 15px"
                                    >
                                      {{ getArrivalTime(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-hide"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-nowrap mt-sm-3 mt-md-0"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ codeData[3].to }}
                                </p>
                                <p
                                  class="d-sm-none d-md-block d-lg-block"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 10px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getretairname(res.Flight4) }}
                                </p>
                              </div>

                              <div
                                class="col-md-3 col-2 ps-2 aircode-show"
                                style="border-left: 1px dotted gray"
                              >
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight4) }}
                                </p>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas4">
                        <div
                          v-for="(res, multindex) of multiflight4"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone4(res, multindex, index)">
                              <v-radio-group v-model="radioData4">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[3].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight4) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight4) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getTime(res.Flight4) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight4JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight4JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(res, index)"
                                          @mouseout="hideDetails1(res)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="res.Flight4.length >= 2"
                                            class="hoverData1"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight4)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight4JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight4JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                          @mouseover="showDetails1(res, index)"
                                          @mouseout="hideDetails1(res)"
                                          id="tooltip"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>

                                          <div
                                            v-if="res.Flight4.length >= 2"
                                            class="hoverDataStop1"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight4)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight4)
                                              }}</span>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight4, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon size="8">mdi-circle</v-icon>
                                          <!-- @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)" -->
                                          <!-- <div
                                            v-if="res.Flight4.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight4, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div> -->

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight4JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight4) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight4)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 500;
                                            font-size: 15px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight4) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[3].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight4 end----- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mt-3 gx-0 justify-content-around">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{
                          getdepseat(res.Depature) >= getretseat(res.Return)
                            ? getdepseat(res.Return)
                            : getretseat(res.Depature)
                        }}
                        Seats</span
                      >
                    </div> -->

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex flex-lg-row flex-column align-center justify-content-center"
                    >
                      <span><v-icon size="20">mdi-cash</v-icon></span>
                      <div>
                        <span
                          class="refundData"
                          style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          "
                          >{{ res.Refund }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex flex-lg-row flex-column justify-center align-center"
                    >
                      <span><v-icon size="18">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray">{{
                        classType
                      }}</span>
                    </div>

                    <div
                      class="col-lg-4 col-md-3 col-6 d-flex align-center justify-center"
                      @click="flighted3(res, index)"
                    >
                      <span
                        style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        "
                      >
                        {{
                          $t("searchPageContent.itinContent.flightDetails")
                        }}</span
                      >

                      <v-icon style="color: #36c" size="16">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div>
                    <div class="col-md-3 col-6 d-lg-none justify-center">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="
                                color: #36c;
                                font-weight: 500;
                                text-align: center;
                              "
                              class="pe-0"
                              v-bind="props"
                            >
                              {{ $t("fareDetContent.fareDetails") }}
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                          <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                {{ $t("fareDetContent.totalPrice") }}
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >{{ $t("fareDetContent.passengers") }}</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <hr class="mt-1 mb-2" />
                            <!-- <div class="d-flex align-end mb-2">
                              <span
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                style="
                                  padding-right: 5px;
                                  cursor: pointer;
                                  font-size: 14px;
                                  letter-spacing: 1px;
                                "
                              >
                                <a href="#">Fare Summary</a></span
                              >

                              <v-icon
                                size="18"
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                v-model="fareIcon"
                                style="
                                  color: 0D6EFD;
                                  cursor: pointer;
                                  overflow-anchor: none;
                                "
                                >{{
                                  fareIcon
                                    ? "mdi-chevron-up-box-outline"
                                    : "mdi-chevron-down-box-outline"
                                }}</v-icon
                              >
                            </div> -->

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  {{ $t("fareDetContent.basePrice") }}<br />
                                  {{ $t("fareDetContent.taxesFees") }}
                                </div>
                                <div class="col-6 f-size-14">
                                  <div class="d-flex flex-row justify-end">
                                    <div class="col-5 text-end pe-2">
                                      {{ res.currency }} <br />
                                      {{ res.currency }}
                                    </div>
                                    <div class="col-7 text-end pe-0 pe-md-2">
                                      {{ res.Baseprice }}<br />
                                      {{ res.Taxprice }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-menu>
                    </div>

                    <div
                      class="col-md-3 col-4 d-none"
                      @click="multiroundData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div
                      class="text-center d-none flex-row justify-content-between fare-detail-mobile"
                    >
                      <div class="col-6 justify-center">
                        <v-menu location="bottom">
                          <template v-slot:activator="{ props }">
                            <div class="d-flex align-center justify-center">
                              <v-card-text
                                @click="fareOpen = !fareOpen"
                                style="color: #36c; font-weight: 500"
                                class="pe-0"
                                v-bind="props"
                              >
                                {{ $t("fareDetContent.fareDetails") }}
                              </v-card-text>
                              <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                            </div>
                          </template>

                          <v-card
                            width="250px"
                            max-height="500px"
                            class="pa-3 m-auto"
                            style="border: 1px solid gray"
                          >
                            <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                          <hr> -->
                            <div class="d-flex justify-content-between">
                              <div>
                                <p
                                  class="m-0"
                                  style="font-size: 16px; font-weight: 500"
                                >
                                  {{ $t("fareDetContent.totalPrice") }}
                                </p>
                              </div>
                              <div>
                                <p class="tot-price m-0">
                                  {{ res.currency }}
                                  {{ res.TotalPrice }}
                                </p>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-12">
                                <div class="row justify-content-between">
                                  <div class="col-6">
                                    <span
                                      style="font-size: 13px; font-weight: 500"
                                      >{{
                                        $t("fareDetContent.passengers")
                                      }}</span
                                    >
                                  </div>
                                  <!-- <div class="col-md-4"></div> -->
                                  <div
                                    class="col-6"
                                    style="font-size: 14px; text-align: end"
                                  >
                                    <span v-if="aduls > 0"
                                      >{{ aduls }} x Adult</span
                                    ><br />
                                    <span v-if="aduls1 > 0"
                                      >{{ aduls1 }} x Child</span
                                    ><br />
                                    <span v-if="aduls2 > 0"
                                      >{{ aduls2 }} x Lap Infant</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="">
                              <hr class="mt-1 mb-2" />
                              <!-- <div class="d-flex align-end mb-2">
                              <span
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                style="
                                  padding-right: 5px;
                                  cursor: pointer;
                                  font-size: 14px;
                                  letter-spacing: 1px;
                                "
                              >
                                <a href="#">Fare Summary</a></span
                              >

                              <v-icon
                                size="18"
                                class="not-collapsed"
                                data-bs-toggle="collapse"
                                href="#collapse-Example"
                                aria-expanded="true"
                                aria-controls="collapse-Example"
                                v-model="fareIcon"
                                style="
                                  color: 0D6EFD;
                                  cursor: pointer;
                                  overflow-anchor: none;
                                "
                                >{{
                                  fareIcon
                                    ? "mdi-chevron-up-box-outline"
                                    : "mdi-chevron-down-box-outline"
                                }}</v-icon
                              >
                            </div> -->

                              <hr class="mt-1 mb-2" />

                              <div
                                class="p-1 collapse"
                                :class="{ show: fareIcon }"
                                id="collapse-Example"
                              >
                                <div class="row">
                                  <div class="col-6" style="font-size: 14px">
                                    {{ $t("fareDetContent.basePrice") }}<br />
                                    {{ $t("fareDetContent.taxesFees") }}
                                  </div>
                                  <!-- <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div> -->
                                  <div class="col-6 f-size-14">
                                    <div class="d-flex flex-row justify-end">
                                      <div class="col-5 text-end pe-2">
                                        {{ res.currency }} <br />
                                        {{ res.currency }}
                                      </div>
                                      <div class="col-7 text-end pe-0 pe-md-2">
                                        {{ res.Baseprice }}<br />
                                        {{ res.Taxprice }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <!-- <br /> -->
                              <!-- <p style="font-size: 12px">
                              {{ $t("fareDetContent.priceInfo1") }} {{ metaCurrency }} .{{
                                $t("fareDetContent.priceInfo2")
                              }}
                            </p> -->
                            </div>
                          </v-card>
                        </v-menu>
                      </div>
                      <div
                        class="col-6 d-flex align-center justify-content-sm-center"
                        @click="flighted3(res, index)"
                      >
                        <span
                          style="
                            color: #36c;
                            font-size: 12px;
                            margin-top: 2px;
                            cursor: pointer;
                          "
                        >
                          {{
                            $t("searchPageContent.itinContent.flightDetails")
                          }}</span
                        >

                        <v-icon style="color: #36c" size="16">{{
                          res.Flightdepature
                            ? "mdi-close-box"
                            : "mdi-chevron-down-box"
                        }}</v-icon>
                      </div>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center select-section-hide1 px-2"
                  style="background-color: rgb(186 233 229 / 37%)"
                >
                  <div
                    class="d-flex flex-column align-center justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <div class="d-flex">
                          <span
                            style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            "
                            >.</span
                          >
                          <span
                            id="f1"
                            style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            "
                            >{{ res.find2 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <span
                      style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      "
                      >Per Person</span
                    >
                    <v-btn
                      color="#ee2b34"
                      width="110px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }}</v-btn
                    >
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <div class="text-center Fare-overlay">
                    <v-menu location="start">
                      <template v-slot:activator="{ props }">
                        <v-card-text
                          @click="fareOpen = !fareOpen"
                          style="color: #36c; font-weight: 500"
                          v-bind="props"
                        >
                          {{ $t("fareDetContent.fareDetails") }}
                        </v-card-text>
                      </template>

                      <v-card
                        width="300px"
                        max-height="500px"
                        class="pa-3 m-auto"
                        style="border: 1px solid gray"
                      >
                        <!-- <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
                        <hr> -->
                        <div class="d-flex justify-content-between">
                          <div>
                            <p
                              class="m-0"
                              style="font-size: 18px; font-weight: 500"
                            >
                              {{ $t("fareDetContent.totalPrice") }}
                            </p>
                          </div>
                          <div>
                            <p class="tot-price m-0">
                              {{ res.currency }}
                              {{ res.TotalPrice }}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="row justify-content-between">
                              <div class="col-6">
                                <span
                                  style="font-size: 14px; font-weight: 500"
                                  >{{ $t("fareDetContent.passengers") }}</span
                                >
                              </div>
                              <!-- <div class="col-md-4"></div> -->
                              <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                <span v-if="aduls > 0">{{ aduls }} x Adult</span
                                ><br />
                                <span v-if="aduls1 > 0"
                                  >{{ aduls1 }} x Child</span
                                ><br />
                                <span v-if="aduls2 > 0"
                                  >{{ aduls2 }} x Lap Infant</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-1">
                          <hr class="mt-1 mb-2" />
                          <!-- <div class="d-flex align-end mb-2">
                            <span
                              class="not-collapsed"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              style="
                                padding-right: 5px;
                                cursor: pointer;
                                font-size: 14px;
                                letter-spacing: 1px;
                              "
                            >
                              <a href="#">Fare Summary</a></span
                            >

                            <v-icon
                              size="18"
                              class="not-collapsed"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              v-model="fareIcon"
                              style="
                                color: 0D6EFD;
                                cursor: pointer;
                                overflow-anchor: none;
                              "
                              >{{
                                fareIcon
                                  ? "mdi-chevron-up-box-outline"
                                  : "mdi-chevron-down-box-outline"
                              }}</v-icon
                            >
                          </div> -->

                          <!-- <hr class="mt-1 mb-2" /> -->

                          <div
                            class="p-1 collapse"
                            :class="{ show: fareIcon }"
                            id="collapse-Example"
                          >
                            <div class="row">
                              <div class="col-6" style="font-size: 14px">
                                {{ $t("fareDetContent.basePrice") }}<br />
                                {{ $t("fareDetContent.taxesFees") }}
                              </div>
                              <!-- <div
                                class="col-6"
                                style="font-size: 14px; text-align: end"
                              >
                                {{ res.currency }}
                                {{ res.Baseprice }}<br />
                                {{ res.currency }}
                                {{ res.Taxprice }}
                              </div> -->
                              <div class="col-6 f-size-14">
                                <div class="d-flex flex-row justify-content-between">
                                  <div class="pe text-end">
                                    {{ res.currency }}
                                    {{ res.currency }}
                                  </div>
                                  <div class="text-end">
                                    {{ res.Baseprice }}
                                    {{ res.Taxprice }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <br />
                          <p style="font-size: 12px">
                            {{ $t("fareDetContent.priceInfo1") }}
                            {{ metaCurrency }} .{{
                              $t("fareDetContent.priceInfo2")
                            }}
                          </p> -->
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-md-12 col-12">
                  <v-card class="mt-2 pb-2" v-if="res.Flightdepature">
                    <!-- FLight1 Details   -->
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight1) }} -
                              {{ getArrivalCode(res.Flight1) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}
                              : {{ res.Flight1JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight1"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ classType }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-2"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData1.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData1.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData1.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData1.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.BaggageData1.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData1.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.BaggageData1.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight1.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight1.length - 1 !== index"
                                  style="
                                    width: 210px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{
                                        $t(
                                          "searchPageContent.flightDetails.layover"
                                        )
                                      }}
                                      {{ res.layoverTimes1[index] }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight1 Details End  -->

                    <!-- FLight2 Details   -->
                    <div v-if="res.Flightdepature">
                      <div>
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight2) }} -
                              {{ getArrivalCode(res.Flight2) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}
                              : {{ res.Flight2JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight2"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ classType }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-2"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData2.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData2.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData2.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData2.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.BaggageData2.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData2.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.BaggageData2.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight2.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight2.length - 1 !== index"
                                  style="
                                    width: 210px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{
                                        $t(
                                          "searchPageContent.flightDetails.layover"
                                        )
                                      }}
                                      {{ res.layoverTimes2[index] }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight2 Details End  -->

                    <!-- FLight3 Details   -->
                    <div v-if="res.Flightdepature">
                      <div v-if="res.Flight3 ? true : false">
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight3) }} -
                              {{ getArrivalCode(res.Flight3) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}
                              : {{ res.Flight3JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight3"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ classType }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-2"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData3.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData3.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData3.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData3.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.BaggageData3.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData3.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.BaggageData3.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight3.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight3.length - 1 !== index"
                                  style="
                                    width: 210px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{
                                        $t(
                                          "searchPageContent.flightDetails.layover"
                                        )
                                      }}
                                      {{ res.layoverTimes3[index] }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight3 Details End  -->

                    <!-- FLight4 Details   -->
                    <div v-if="res.Flightdepature">
                      <div v-if="res.Flight4 ? true : false">
                        <div
                          class="d-flex p-2 justify-content-between flex-wrap"
                          style="background-color: #e8f0fd"
                        >
                          <div class="d-flex align-center">
                            <v-icon style="transform: rotate(90deg) !important"
                              >mdi-airplane</v-icon
                            >
                            <span class="f-size-15 fw-500 ps-2"
                              >{{ getAirportCode(res.Flight4) }} -
                              {{ getArrivalCode(res.Flight4) }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end">
                            <span class="headtext fw-500"
                              >{{
                                $t(
                                  "searchPageContent.flightDetails.totaltripDuration"
                                )
                              }}
                              : {{ res.Flight4JourneyTime.Time }}</span
                            >
                            <!-- <v-icon class="ms-3">mdi-close-box</v-icon> -->
                          </div>
                        </div>

                        <div class="px-2" style="max-width: 100%">
                          <div
                            v-for="(data, index) of res.Flight4"
                            :key="index"
                          >
                            <div class="row mt-3 mb-3">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12 airline-logo">
                                    <v-img
                                      :src="data.MarketingCarrier.logo"
                                      width="30px"
                                    ></v-img>
                                  </div>
                                  <div class="col-md-8 col-12 airline-profile">
                                    <p class="f-size-13 airline-name m-0">
                                      {{ data.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-7 col-9">
                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated(data.Departure.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightdep(data) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Departure.AirportCode }} </span
                                    >, {{ data.Departure.AirportName }}
                                  </div>
                                </div>

                                <div class="row d-flex">
                                  <div class="col-sm-3 p-0">
                                    <span class="f-size-10 airline-date">{{
                                      getdated1(data.Arrival.Date)
                                    }}</span>
                                    <span
                                      class="f-size-13 airline-time"
                                      style="margin-left: 5px"
                                      >{{ getflightarr(data) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="fw-500"
                                      >{{ data.Arrival.AirportCode }} </span
                                    >, {{ data.Arrival.AirportName }}
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                              >
                                <div class="d-flex class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-seat-passenger
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    {{ classType }}
                                  </span>
                                </div>

                                <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                                  <v-icon size="15px" color="#ccc">
                                    mdi-food-fork-drink
                                  </v-icon>

                                  <span
                                    class="f-size-12"
                                    style="padding-left: 5px"
                                  >
                                    Meal provided
                                  </span>
                                </div> -->
                                <v-tooltip
                                  :v-model="'show' + index"
                                  location="bottom"
                                >
                                  <template v-slot:activator="{ props }">
                                    <div
                                      v-bind="props"
                                      class="d-flex flex-row ms-lg-0 ms-2"
                                    >
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData4.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/handbag.png"
                                          class="baggageimg"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData4.PieceAllowance
                                            .TotalQuantity > 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/luggage1.png"
                                          class="baggageimg1"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                      <div
                                        style="color: grey !important"
                                        v-if="
                                          res.BaggageData4.PieceAllowance
                                            .TotalQuantity == 0
                                        "
                                      >
                                        <v-img
                                          src="../assets/notbag1.png"
                                          class="baggageimg2"
                                          color="grey"
                                          width="18px"
                                          style="opacity: 0.3"
                                        ></v-img>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="d-flex ms-2 mt-1">
                                    <div style="color: white !important">
                                      <v-icon color="gray" size="15">
                                        bi bi-handbag-fill
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span>CarryOn Baggage:</span>
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData4.PieceAllowance
                                            .TotalQuantity > 0
                                            ? 1 + " Piece"
                                            : 1 + " Piece"
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex ms-1 mt-1"
                                    v-if="
                                      res.BaggageData4.PieceAllowance
                                        .TotalQuantity > 0
                                    "
                                  >
                                    <div style="color: white !important">
                                      <v-icon color="white" size="20">
                                        mdi-bag-suitcase
                                      </v-icon>
                                    </div>
                                    <div
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        margin-left: 5px;
                                      "
                                    >
                                      <span> Checked Baggage:</span>
                                      <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                      <span class="ms-1">
                                        {{
                                          res.BaggageData4.PieceAllowance
                                            .TotalQuantity || 0
                                        }}

                                        {{
                                          res.BaggageData4.PieceAllowance
                                            .TotalQuantity > 1
                                            ? "Pieces"
                                            : "Piece"
                                        }} </span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 62 linear inches/158 linear
                                        centimeters</span
                                      ><br />
                                      <span class="f-size-10"
                                        ><i
                                          class="bi bi-circle-fill mx-2"
                                          style="font-size: 5px"
                                        ></i
                                        >Up to 50 pounds/23 kilograms</span
                                      >
                                    </div>
                                  </div>
                                </v-tooltip>
                              </div>

                              <div class="d-flex position-relative mt-3">
                                <hr
                                  v-if="res.Flight4.length - 1 !== index"
                                  style="
                                    width: 97%;
                                    position: absolute;
                                    margin-top: 8px !important;
                                    margin-bottom: 0px !important;
                                  "
                                />
                                <div
                                  class="d-flex justify-center"
                                  v-if="res.Flight4.length - 1 !== index"
                                  style="
                                    width: 210px;
                                    background: antiquewhite;
                                    border-radius: 18px;
                                    z-index: 1;
                                    margin: auto;
                                  "
                                >
                                  <div class="d-flex align-center">
                                    <span class="f-size-10"
                                      >{{
                                        $t(
                                          "searchPageContent.flightDetails.layover"
                                        )
                                      }}
                                      {{ res.layoverTimes4[index] }} ({{
                                        data.Arrival.AirportCode
                                      }})
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- FLight4 Details End  -->
                  </v-card>
                </div>
              </div>
            </v-card>
          </div>
           <div v-if="disableData">
            <v-card class="p-3">
              <div class="d-flex flex-row">
                <span><v-img src="../assets/no-flight.png" width="45px" height="30px"></v-img></span>
                <p class="m-0">We can't find flights for the selected filters.</p><br/>
                <p> Please change your filter options.</p>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="searchloader">
    <loader></loader>
  </div>
  <div v-if="pageError">
    <pageError></pageError>
  </div>
</template>

<script>
import loader from "@/components/loaderComponent.vue";
// import onewayService from '@/service/onewayService'
import multicityService from "@/service/multicityService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
// import userData from "@/airport_icao.json";
import axios from "axios";
import airLogo from "@/airlinesLogo.json";
const totalPricefilter = require("lodash");
import pageError from "@/components/pageError.vue";
// import userData from '@/airport_icao.json';

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    VueDatePicker,
    AutoComplete,
    loader,
    pageError,
  },

  data() {
    return {
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",
      show: false,
      show1: false,
      pageError: false,
      dialog: false,
      fareOpen: false,
      multiCalender: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM, yyyy",
      detail: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      // filters
      filteredData:[],
      outBoundTime:[],
      inBoundTime:[],
      outBoundTimeMulti:[],
      defaultOutBoundMulti:null,
      defaultOutBoundTime:null,
      defaultInBoundTime:null,
      outBoundMulti:null,
      outBound:null,
      inBound:null,
      fromAirportName:[],
      toAirportName:[],
      multiAirport:[],
      isReturn:false,
      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      hasNonStop: false,
      hasOneStop: false,
      hasOnePlusStop: false,
      hasRefundable: false,
      hasNonRefundable: false,
      RefundableData: false,
      nonRefundableData: false,
      selectedFromAirports:false,
      selectedToAirports:false,
      multiFromAirports:false,
      multiToAirports:false,
      onedatafil:false,
      rounddatafil:false,
      multifil:false,
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterData4: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      timeFilterArrData4: false,
      timearrFilterData1:false,
      timearrFilterData2:false,
      timearrFilterData3:false,
      timearrFilterData4:false,
      timearrFilterArrData1:false,
      timearrFilterArrData2:false,
      timearrFilterArrData3:false,
      timearrFilterArrData4:false,
      morningRange: [6, 12],
      afternoonRange: [12, 18],
      eveningRange: [18, 24],
      beforeMorningRange: [0,6],
      hasMorningFlights: false,
      hasAfternoonFlights: false,
      hasEveningFlights: false,
      hasMorningFlights1: false,
      hasAfternoonFlights1: false,
      hasEveningFlights1: false,
       hasMorningReturn: false,
      hasAfternoonReturn: false,
      hasEveningReturn: false,
      hasMorningReturn1: false,
      hasAfternoonReturn1: false,
      hasEveningReturn1: false,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      disableData:false,
      timeMulti1:false,
      timeMulti2:false,
      timeMulti3:false,
      timeMulti4:false,
      timeMultiArr1:false,
      timeMultiArr2:false,
      timeMultiArr3:false,
      timeMultiArr4:false,
      // filters
      items: [],
      airlogodata: [],
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      red: 1,
      red1: 1,
      // paxhide: true,

      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],

      returnData: [],
      Oneway: false,
      rount: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      uniqueId: "",
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      initialChangeValue:[],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      multicityData: [],
      multicityData1: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      multishowData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],

      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      classType: "Economy",
      classTypeitems: [
        {
          text: this.$t("homePageContent.contentSection.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("homePageContent.contentSection.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("homePageContent.contentSection.firstclass"),
          value: "First Class",
          department: "First Class Department",
        },
        // { text: this.$t('homePageContent.contentSection.premiumEconomy'), value: 'Premium Economy', department: 'Premium Economy Department' },
      ],

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      data_id: "",
      roundOfferId: [],
      airlineList: [],
      airlineArr: [],
      oneway_id: "",
      onewayOfferId: [],

      responseData: [],
      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],

      showNonStop: true,
      showOneStop: true,
      showOneplusStop: true,
      recperperson: "",
      recCurrecy: "",
      shotperperson: "",
      layperperson: "",
      shorCurrecy: "",
      active: false,
      active1: false,
      active2: false,
      active3: false,
      radiosData: 0,
      radioData1: 0,
      radioData2: 0,
      radioData3: 0,
      radioData4: 0,
      flightdetailsData: [],
      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],
      onewaymultiData: [],
      rountmultiData: [],
      airCanada: false,
      qatarAirline: false,
      // layoverTimes: [],
      // layoverTimes1: [],
      // input: [
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      // ],

      input: [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],
      multiplesameData: [],
      multiDepatureData: [],
      multireturnData: [],
      multiflight1: [],
      multiflight2: [],
      multiflight3: [],
      multiflight4: [],
      totalMinutesT1: "",
      totalMinutesT2: "",
      displayCurrency: "",
      preferCurrency: "",
      metaCurrency: "",

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "MULTICITY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "",
        DestinationCity1: "",
        OriginCity2: "",
        DestinationCity2: "",
        TripType: "Oneway",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      formMultiData: [],
      FilterTimeDepname: "",
      FilterTimearrname: "",
      multi_city: {
        itinery: [
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
          },
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },

      showMulti_data: {},

      // multi_city: [
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   }
      // ],
      addmore: true,
      clearall: false,
      deleteSeg: true,

      makeSearchUrl: "",
      airLogoApi: [],

      airline_logoData: [],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      readMore: false,
      maxItemsToShow: 5,
      filterAirline: [],
    };
  },

  methods: {
    showMore() {
      this.readMore = !this.readMore;

      // let $data = []
      console.log(this.filterAirline, "this.filterAirline ");

      if (this.readMore) {
        this.maxItemsToShow = this.airlineList.length;
      } else {
        this.maxItemsToShow = 5;
      }
    },

    selectClasstype(item) {
      this.classType = item.value;
    },

    updateClassType() {
      const currentClass = this.classTypeitems.find(
        (item) => item.value === this.classType
      );
      if (currentClass) {
        this.classType = currentClass.value;
      }
    },

    // showLess() {
    //   this.readMore = false;
    // },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
    },

    showDetails1(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }

      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails1(res) {
      res.hoveredName1 = false;
      res.hoveredName2 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
          v.hoveredName1 = true;
        } else {
          v.hoveredName2 = false;
          v.hoveredName1 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName2 = true;
          } else {
            v.hoveredName2 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
      res.hoveredName1 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
          v.hoveredName4 = true;
        } else {
          v.hoveredName3 = false;
          v.hoveredName4 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName3 = true;
          } else {
            v.hoveredName3 = false;
          }
        });
      }
    },
    hideDetails3(res) {
      res.hoveredName3 = false;
      res.hoveredName4 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName4 = true;
          } else {
            v.hoveredName4 = false;
          }
        });
      }
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },

    act(data) {
      this.red1 = data;
      // this.oneway.city = this.red1;
      // console.log(this.red1, "pppppppppppppppppppppppppp");

      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
      }
      if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
      }
      if (this.red1 == 3) {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    actActive() {
      // console.log(this.red1, "TRTRTRTR1");
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.roundModify = false;
        this.onewayModify = true;
      } else if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
        this.roundModify = true;
        this.onewayModify = false;
      } else {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    deg1() {
      // this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
    },
    add1() {
      console.log(this.aduls, "1111");
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls = this.aduls + 1;
        console.log(this.aduls, "2222");
        // this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        // this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;

        // console.log(this.aduls1, "2222");

        // this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        // this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;

        // console.log(this.aduls2, "3333");

        // this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );

      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // console.log(this.classSelect, "ffffaaaallllssseeee");
      if (!this.classSelect) {
        this.$refs.countrySelect.focus();
      }
    },

    closeDropdown() {
      setTimeout(() => {
        this.$refs.mySelect.blur();
        if (this.classType) {
          this.$refs.countrySelect.blur();
          console.log("working:");
        }
      }, 50);
    },

    multiSend() {
      if (this.multi_city.itinery.length < 4) {
        this.multi_city.itinery.push({
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        });

        if (this.multi_city.itinery.length == 4) {
          console.log(
            this.multi_city,
            this.multi_city.itinery.length,
            "lengthlen.....gthlength"
          );

          this.addmore = false;
        }
        // else{
        //   this.addmore = true;
        // }
      }

      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
        this.deleteSeg = true;
      }

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    deleteSegment() {
      if (this.multi_city.itinery.length == 3) {
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = false;
        this.addmore = true;
      } else if (this.multi_city.itinery.length > 2) {
        console.log(this.multi_city.itinery.length, "lengthlengthl,..111..");
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = true;
        this.addmore = true;
      } else {
        this.deleteSeg = false;
      }
    },

    clearall1() {
      this.multi_city.itinery.splice(2, 5);

      (this.multi_city.itinery = [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },

    // getprice(res) {
    //   return (((res.TotalAmount) / this.totaladult).toFixed(2));
    // },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getCountryName1($event) {
      this.FilterTimeDepname = $event.split("(")[0];
      return $event.split("(")[0];
    },
    getCountryName2($event) {
      this.FilterTimearrname = $event.split("(")[0];
      return $event.split("(")[0];
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //  --------filter Start---------------------

    Recommend() {
      this.active = true;
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;

      if (this.onewayfilterData.length > 0) {
        // alert('pppppp99')
        // console.log(this.onewayfilterData,'this.onewayfilterDatathis.onewayfilterData')
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },
    Cheapest(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = false;
        this.active2 = true;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        // this.onewayfilterData.sort((a, b) => a.perperson - b.perperson)
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },

    Shortest(data) {
      if (data == "1") {
        this.active = false;
        this.active1 = false;
        this.active2 = false;
        this.active3 = true;
      } else {
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) =>
          a.Depaturejourney.Time.localeCompare(
            b.Depaturejourney.Time,
            undefined,
            { numeric: true }
          )
        );
        this.shorCurrecy = this.onewayresulted[0].currency;
        this.shotperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort(
          (a, b) =>
            a.Depaturejourney.Time.localeCompare(
              b.Depaturejourney.Time,
              undefined,
              { numeric: true }
            ) &&
            a.Returnjourney.Time.localeCompare(
              b.Returnjourney.Time,
              undefined,
              { numeric: true }
            )
        );
        this.shorCurrecy = this.rountresult[0].currency;
        this.shotperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => {
          const compareFlight1 = a.Flight1JourneyTime.Time.localeCompare(
            b.Flight1JourneyTime.Time,
            undefined,
            { numeric: true }
          );
          const compareFlight2 = a.Flight2JourneyTime.Time.localeCompare(
            b.Flight2JourneyTime.Time,
            undefined,
            { numeric: true }
          );

          let compareFlight3 = 0;
          if (
            a.Flight3JourneyTime &&
            a.Flight3JourneyTime.Time &&
            b.Flight3JourneyTime &&
            b.Flight3JourneyTime.Time
          ) {
            compareFlight3 = a.Flight3JourneyTime.Time.localeCompare(
              b.Flight3JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          let compareFlight4 = 0;
          if (
            a.Flight4JourneyTime &&
            a.Flight4JourneyTime.Time &&
            b.Flight4JourneyTime &&
            b.Flight4JourneyTime.Time
          ) {
            compareFlight4 = a.Flight4JourneyTime.Time.localeCompare(
              b.Flight4JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          return (
            compareFlight1 || compareFlight2 || compareFlight3 || compareFlight4
          );
        });

        this.shorCurrecy = this.multicityData[0].currency;
        this.shotperperson = this.multicityData[0].perperson;
      }
    },

    bestlayTime(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = true;
        this.active2 = false;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.multicityData[0].perperson;
      }
    },

    //  --------filter end---------------------

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    flighted1(res, index) {
      this.flightdetailsData = res;

      this.rountresult.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },
    flighted2(res, index) {
      console.log(index, res, "pppppppiiithis");
      this.flightdetailsData = res;
      // console.log(this.flightdetailsData.Depature,'this.flightdetailsDatathis.flightdetailsData')

      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    flighted3(res, index) {
      console.log(index, res);
      this.multicityData.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    onewayselect(result, index) {
      if (this.flightdetailsData.TotalPrice) {
        // console.log(this.flightdetailsData, 'ppp');
      } else {
        this.flightdetailsData = result;
        // console.log(this.flightdetailsData, 'ptttttttttttttttpp');
      }
      localStorage.removeItem("itineryData");
      localStorage.removeItem("uniId");
      console.log(result, index);
      localStorage.setItem(
        `${result.currency}-${result.selectId}`,
        JSON.stringify(this.flightdetailsData)
      );
      this.$router.push({
        path: "/flightinfo",
        query: { pass_uid1: `${result.currency}-${result.selectId}` },
      });
    },

    multidata() {
      multicityService.getAllUser().then((v) => {
        this.offerpay = v.data.OffersGroup.AirlineOffers[0].Offer;

        this.Journey = v.data.DataLists.FlightList.Flight;

        this.Journey1 = v.data.DataLists.FlightSegmentList.FlightSegment;
        this.Journey.forEach((a) => {
          this.flightsegmet.push(a.SegmentReferences.split(" "));
        });
        this.Journey1.forEach((t) => {
          this.flightsegmetkey.push(t);
        });

        this.Journey.forEach((q) => {
          this.airstime.push(q.Journey.Time);
          this.airstop.push(q.Journey.Stops);
        });
        for (let i = 0; i <= this.offerpay.length - 1; i++) {
          let item = [];
          item = this.offerpay[i].OfferItem;
          item.forEach((t) => {
            this.airprice.push(
              t.TotalPriceDetail.TotalAmount.BookingCurrencyPrice
            );
            if (
              t.PassengerType === "ADT" ||
              t.PassengerType == "ChD" ||
              t.PassengerType == "INF"
            ) {
              if (t.Refundable === "true") {
                this.airref.push("Refundable");
              } else {
                this.airref.push("NON Refundable");
              }
            }
          });
        }

        this.airprice.forEach((c) => {
          this.multicityData1.push({ airprice: c });
        });
      });
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    deatailData() {
      if (this.detail.city == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.oneway1 = true;
      }
      if (this.detail.city == 1) {
        this.roundtrip = true;
        this.Oneway = false;
        (this.rount = true), (this.multicity = false);
      }
      if (this.detail.city == 3) {
        this.multitrip = true;
        this.Oneway = false;
        (this.rount = false), (this.multicity = true);
      }
    },

    // RoundTrip datePicker

    getDatepicker($data, index) {
      if (index > 0) {
        // console.log($data, index, '$data,index....1')

        return $data[index - 1].depdata;
      } else {
        // console.log($data, index, '$data,index....2')

        return `${new Date()}`;
      }
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.clicked2 = false;
        this.fromDateError = false;
        this.oneway.redate = null;
        this.$refs.dpRef2.openMenu();

        // console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        // console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.toDateError = true;
      }
    },

    triggerFunction() {
      alert("Hiii...");
      // this.triggerRadio = !this.triggerRadio

      // if (this.triggerRadio) {
      //   console.log(this.triggerRadio)
      //   this.$refs.radioSelect2.focus();
      // }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();
      // console.log(this.oneway.redate, "kkkkkkkkk");

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(newValue, index) {
      // console.log(index, newValue, "didididid...");

      this.multi_city.itinery.map((v, i) => {
        if (index == i) {
          if (newValue) {
            v.clicked3 = true;
            v.multiDateError = false;
          } else {
            v.clicked3 = false;
            v.multiDateError = true;
          }
        }
      });

      this.multi_city.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    multiClickOutside() {
      this.multi_city.itinery.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    roundData() {
      this.oneway.class = this.$route.query.class;
      console.log(this.$route.query, "querrrrrr");

      if (this.$route.query.from.name) {
        this.oneway.from = this.$route.query.from.name;
        this.tripData.from = this.$route.query.from.name;
      } else {
        this.oneway.from = this.$route.query.from;
        this.tripData.from = this.$route.query.from;
      }
      if (this.$route.query.to.name) {
        this.oneway.to = this.$route.query.to.name;
        this.tripData.to = this.$route.query.to.name;
      } else {
        this.oneway.to = this.$route.query.to;
        this.tripData.to = this.$route.query.to;
      }
      this.oneway.dedate = moment(this.$route.query.dedate).format(
        "DD MMM-YYYY"
      );
      this.tripData.dedate = moment(this.$route.query.dedate).format(
        "DD MMM-YYYY"
      );
      this.oneway.redate = moment(this.$route.query.redate).format(
        "DD MMM-YYYY"
      );
      this.tripData.redate = moment(this.$route.query.redate).format(
        "DD MMM-YYYY"
      );
      this.oneway.class = this.$route.query.class;
      this.tripData.class = this.$route.query.class;

      if (this.$route.query.adult) {
        for (let i = 0; i < this.$route.query.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.$route.query.child) {
        for (let i = 0; i < this.$route.query.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.$route.query.infrant) {
        for (let i = 0; i < this.$route.query.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      // console.log(this.totalpassenger, "aadjj");
      this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
      this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;

      this.aduls = parseFloat(this.$route.query.adult);
      this.tripData.aduls = parseFloat(this.$route.query.adult);

      this.aduls2 = parseFloat(this.$route.query.infrant);
      this.tripData.aduls2 = parseFloat(this.$route.query.infrant);

      this.aduls1 = parseFloat(this.$route.query.child);
      this.tripData.aduls1 = parseFloat(this.$route.query.child);
      this.totaladult = this.aduls + this.aduls1 + this.aduls2;
      this.select = this.$route.query.class;
      this.tripData.select = this.$route.query.class;
    },

    multiDataConvert() {
      console.log(this.multi_city, "this.$route.querythis.$route.query.....3");
      this.red = this.multi_city.city;
      this.red1 = this.multi_city.city;
      if (this.multi_city.adult) {
        for (let i = 0; i < this.multi_city.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.multi_city.child) {
        for (let i = 0; i < this.multi_city.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.multi_city.infrant) {
        for (let i = 0; i < this.multi_city.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      this.multi_city.itinery.forEach((s) => {
        let $result = {
          Departure: {
            AirportCode: "",
            Date: "",
          },
          Arrival: {
            AirportCode: "",
          },
        };
        let tripData = {
          from: "",
          to: "",
        };
        if (s.from) {
          let res = "";
          res = s.from.split("(");
          console.log(res);
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
        } else {
          let res = "";
          res = s.from.name.split("(");
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
        }

        if (s.to) {
          let res = "";
          res = s.to.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
        } else {
          let res = "";
          res = s.to.name.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
        }
        if (s.depdata) {
          $result.Departure.Date = moment(s.depdata).format("YYYY-MM-DD");
        }
        // console.log($result, '$result$result')
        this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
          $result
        );
        this.codeData.push(tripData);
      });

      console.log(this.codeData, "this.$route.querythis.$route.query.....2");

      console.log(this.totalpassenger, "aadjj");
      this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
    },

    multicityresult() {
      this.multiDataConvert();

      this.multi_city.class = this.classType;
      let multiCabin_type = this.multi_city.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      }

      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.metaCurrency;

      console.log(
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      axios
        .post(this.makeSearchUrl, this.MulticityReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          // if (response.data) {
          //   this.multicityTotalData.push(response.data);
          //   this.multicityconvert();
          // }

          if (response.data.Search_Response == "Error") {
            console.log(response, "multi_citymulti_citymulti_city.....1");

            this.$router.push("/pageError");
          } else if (response.data) {
            this.multicityTotalData.push(response.data);
            this.multicityconvert();
            console.log(response, "multi_citymulti_citymulti_city.....2");
          }
        })

        .catch((error) => {
          console.log(error);
          this.$router.push("/pageError");
        });
    },

    multicityconvert() {
      let baggageList = [];

      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "power");
        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        console.log(Journey, "Journey");
        baggageList =
          this.multicityTotalData[i].DataLists.BaggageAllowanceList
            .BaggageAllowance;
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];
        let Baggage1 = [];
        let Baggage2 = [];
        let Baggage3 = [];
        let Baggage4 = [];
        let Baggage5 = [];

        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            if (index == 0) {
              Baggage1.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              Baggage2.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              Baggage3.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData3.push(s.SegmentRefs.split(" "));
              console.log(this.offerData3, "this.offerData3this.offerData3");
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              Baggage4.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              Baggage5.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        console.log(flightsegment, "this.flightsegment");
        let BaggageData1 = [];
        if (this.offerData1.length > 0) {
          Baggage1.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData1.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }

        let BaggageData2 = [];
        if (this.offerData2.length > 0) {
          Baggage2.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData2.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }
        let BaggageData3 = [];
        if (this.offerData3.length > 0) {
          Baggage3.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData3.push(c);
              }
            });
          });
          // console.log(this.offerData3,'this.offerData3this.offerData36557')
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              console.log(
                index,
                this.offerData3[i].length - 1,
                "this.offerData2[i].length - 1"
              );
              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
                // console.log(this.Flight3,'this.Flight3this.Flight3this.Flight3this.Flight3')
              }
            });
          }
        }
        let BaggageData4 = [];
        if (this.offerData4.length > 0) {
          Baggage4.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData4.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }
        let BaggageData5 = [];
        if (this.offerData5.length > 0) {
          Baggage5.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData5.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length - 1; i++) {
          for (let j = 0; j < flightsegment.length - 1; j++) {
            if (
              flightsegment[j].MarketingCarrier.AirlineID ==
              this.airlogodata[i].id
            ) {
              flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];

        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
        });

        $data.forEach((s) => {
          currency.push(this.displayCurrency);

          // if (s.ReqCurrency == "USD") {
          //   currency.push("US$");
          // } else if (s.ReqCurrency == "CAD") {
          //   currency.push("CA$");
          // } else {
          //   currency.push("US$");
          // }

          ownername.push(s.OwnerName);
          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );

          s.OfferItem.forEach((v) => {
            console.log(
              v[0].FareDetail[0].Price.NonRefundable,
              "Price.RefundablePrice.Refundable"
            );

            if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
              refund.push("Refundable");
            } else {
              refund.push("Non Refundable");
            }
          });
        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = perperson[i];
          // console.log(nume1, "llldlldldldldld")
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].hitId = `${currency[i]}-${this.uniqueId}`;
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].BaggageData1 = BaggageData1[i];
          this.multicityData[i].BaggageData2 = BaggageData2[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].Adult = this.aduls;
          this.multicityData[i].Child = this.aduls1;
          this.multicityData[i].Inf = this.aduls2;
          // this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multi_city.class;
          this.multicityData[i].multipleDatas1 = false;
          this.multicityData[i].multipleDatas2 = false;
          this.multicityData[i].radios1 = true;
          this.multicityData[i].radios2 = true;
          this.multicityData[i].radioData1 = false;
          this.multicityData[i].radioData2 = false;
          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
            this.multicityData[i].multipleDatas3 = false;
            this.multicityData[i].radios3 = true;
            this.multicityData[i].radioData3 = false;
            this.multicityData[i].BaggageData3 = BaggageData3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
            this.multicityData[i].multipleDatas4 = false;
            this.multicityData[i].radios4 = true;
            this.multicityData[i].radioData3 = false;
            this.multicityData[i].BaggageData4 = BaggageData4[i];
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
            this.multicityData[i].BaggageData5 = BaggageData5[i];
          }
        }
        this.getmultitime1();

        this.multishowData = this.multicityData;
        this.multiFilterData = this.multicityData;
        // this.multipriceData();
        this.priceRange();
        this.multicitystopFilter();
        this.Shortest();
        this.Recommend();
        this.Cheapest();
        this.bestlayTime();
        this.filterOutBoundMulti();
        this.getAiportFilterMulti();
        this.airlineFilter();

        // this.multiFilterData = this.multicityData;
        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;

          document.getElementById("navbar").style.display = "block";
          document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");
        console.log(
          this.multicityData,
          "multicityDatamulticityDatamulticityData...."
        );
      }
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        // console.log(this.localData[i],"this.localData[i]this.localData[i]this.localData[i]");

        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists, "good");
            // let seats = [];
            let baggageList = [];
            this.Journey = v.DataLists.FlightList.Flight;
            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;

            baggageList = v.DataLists.BaggageAllowanceList.BaggageAllowance;
            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];

            $data.forEach((v) => {
              // console.log(v, i, "offeriddatataa");
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              currency.push(this.displayCurrency);

              // if (s.ReqCurrency == "USD") {
              //   currency.push("US$");
              // } else if (s.ReqCurrency == "CAD") {
              //   currency.push("CA$");
              // } else {
              //   currency.push("US$");
              // }

              // console.log(this.airline_logoData,"vlvlvlvlvvl")

              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);

                  // console.log(ownerlogo,'owmpownwonwown')
                }
              }
              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );
              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                // console.log(v, "kannannaaannnanannananannanan");
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;

                // console.log(
                //   v[0].FareDetail[0].Price.NonRefundable,
                //   "kannannaaannnanannananannanan"
                // );

                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            let depBaggage = [];
            let arrBaggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              if ($data[j].OfferItem[0][0].BaggageAllowance[0] && $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef) {
                depBaggage.push($data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef);
              }
              if ($data[j].OfferItem[0][0].BaggageAllowance[1] && $data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef) {
                arrBaggage.push($data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef);
              }
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }
            let BaggageData = [];
            let BaggageData1 = [];
            depBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });
            arrBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }
            // console.log(this.find1, "this.find1this.find1");
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].depBaggage = BaggageData[i];
              this.rountresult[i].arrBaggage = BaggageData1[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].multipleDatas = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = this.aduls;
              this.rountresult[i].Child = this.aduls1;
              this.rountresult[i].Inf = this.aduls2;
              this.rountresult[i].radioData = true;
              this.rountresult[i].radiretData = true;
              this.rountresult[i].radios = false;
              this.rountresult[i].radiosret = false;
              this.rountresult[i].hitId = `${currency[i]}-${this.uniqueId}`;
            }
            this.rountmultiData = this.rountresult;
            this.rounddatafil = true;
            console.log(this.rountresult, "resulppppppppppppppppppppppppt");
            this.gettimedep();
            this.gettimeret();
            // this.singlepriceRountData();
            this.filterData = this.rountresult;
            this.priceRange();
            // this.calculateTotalTime();
            this.Shortest();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();
            this.airlineFilter();
            // this.stopFilter();
            this.filterOutBound();
            this.getAiportFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')
            this.filterData = this.rountresult;
            this.filterData.sort((a, b) => a.perperson - b.perperson);
            this.rountresult = this.filterData;
          });
        }
      }
    },
    // -------depTime start------

    singlepriceRountData() {
      let $event = [];
      let $data = this.rountresult;
      this.rountresult.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.rountresult = $event.sort((a, b) => a.perperson - b.perperson);

      this.rountresult.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.TotalPrice == b.TotalPrice &&
            a.ownername == b.ownername &&
            (a.Depaturejourney.Time !== b.Depaturejourney.Time ||
              a.Returnjourney.Time !== b.Returnjourney.Time)
          ) {
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.rountresult[i].multi = "Show more Options";
        }
      });

      this.filterData = this.rountresult;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreroundData(data, index) {
      console.log(data, index, this.rountresult, "vasanrhtttt");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = false;
          v.radiosret = false;
          v.multipleDatas = !v.multipleDatas;
        } else {
          v.multipleDatas = false;
        }
      });
      let $event = [];

      this.rountmultiData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      console.log($event, "ppppppwww");
      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Depaturejourney.Time == a.Depaturejourney.Time &&
              b.Returnjourney.Time == a.Returnjourney.Time
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data = [];
      let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
        if (!$data1.some((b) => b.Returnjourney.Time == k.Returnjourney.Time)) {
          $data1.push(k);
        }
      });
      this.multiDepatureData = $data;
      this.multireturnData = $data1;
    },

    multipriceData() {
      let $event = [];
      // let $event1 = [];
      let $data = this.multicityData;
      this.multicityData.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.multicityData = $event.sort((a, b) => a.perperson - b.perperson);

      this.multicityData.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (a.TotalPrice == b.TotalPrice && a.ownername == b.ownername) {
            if (
              a.Flight1JourneyTime.Time !== b.Flight1JourneyTime.Time ||
              a.Flight2JourneyTime.Time !== b.Flight2JourneyTime.Time ||
              (a.Flight3JourneyTime &&
                a.Flight3JourneyTime.Time !== b.Flight3JourneyTime &&
                b.Flight3JourneyTime.Time) ||
              (a.Flight4JourneyTime &&
                a.Flight4JourneyTime.Time !== b.Flight4JourneyTime &&
                b.Flight4JourneyTime.Time)
            ) {
              count++;
              console.log(a, i);
            }
          }
        });
        console.log();
        if (count > 1) {
          this.multicityData[i].multi = "Show more Options";
        }
      });

      this.multiFilterData = this.multicityData;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    multiroundData(data, index) {
      console.log(data, index, this.multicityData, "vasanrhtttt");

      this.multicityData.find((v, i) => {
        if (i == index) {
          console.log(i, index, v, "lllll");

          v.multipleDatas4 = !v.multipleDatas4;
          v.multipleDatas3 = !v.multipleDatas3;
          v.multipleDatas2 = !v.multipleDatas2;
          v.multipleDatas1 = !v.multipleDatas1;
        } else {
          v.multipleDatas1 = false;
          v.multipleDatas2 = false;
          v.multipleDatas3 = false;
          v.multipleDatas4 = false;
        }
      });
      let $event = [];

      this.multishowData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });

      console.log($event, "$event$event");

      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Flight1JourneyTime.Time == a.Flight1JourneyTime.Time &&
              b.Flight2JourneyTime.Time == a.Flight2JourneyTime.Time &&
              (b.Flight3JourneyTime && b.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "" == a.Flight3JourneyTime && a.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "") &&
              (b.Flight4JourneyTime && b.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "" == a.Flight4JourneyTime && a.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "")
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data1 = [];
      let $data2 = [];
      let $data3 = [];
      let $data4 = [];
      // let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data1.some(
            (b) =>
              b.Flight1JourneyTime.Time == k.Flight1JourneyTime.Time ||
              b.Flight2JourneyTime.Time == k.Flight2JourneyTime.Time ||
              (b.Flight3JourneyTime && b.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "" == k.Flight3JourneyTime && k.Flight3JourneyTime.Time
                ? k.Flight3JourneyTime.Time
                : "") ||
              (b.Flight4JourneyTime && b.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "" == k.Flight4JourneyTime && k.Flight4JourneyTime.Time
                ? k.Flight4JourneyTime.Time
                : "")
          )
        ) {
          $data1.push(k);
        }

        if (
          !$data2.some(
            (b) => b.Flight2JourneyTime.Time == k.Flight2JourneyTime.Time
          )
        ) {
          $data2.push(k);
        }

        if (
          !$data3.some((b) =>
            b.Flight3JourneyTime && b.Flight3JourneyTime.Time
              ? b.Flight3JourneyTime.Time
              : "" == k.Flight3JourneyTime && k.Flight3JourneyTime.Time
              ? k.Flight3JourneyTime.Time
              : ""
          )
        ) {
          $data3.push(k);
        }

        if (
          !$data4.some((b) =>
            b.Flight4JourneyTime && b.Flight4JourneyTime.Time
              ? b.Flight4JourneyTime.Time
              : "" == k.Flight4JourneyTime && k.Flight4JourneyTime.Time
              ? k.Flight4JourneyTime.Time
              : ""
          )
        ) {
          $data4.push(k);
        }
      });
      this.multiflight1 = $data1;
      this.multiflight2 = $data2;
      this.multiflight3 = $data3;
      this.multiflight4 = $data4;

      console.log(this.multiflight1, "this.multiflight1");
      console.log(this.multiflight2, "this.multiflight2");
      console.log(this.multiflight3, "this.multiflight3");
      console.log(this.multiflight4, "this.multiflight4");
    },

    radiodep(res, index) {
      console.log(res, index, "res, indexrrrrrr");
      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radioData = true;
          v.radiretData = true;

          this.multireturnData.forEach((d) => {
            d.radiosret = false;
          });
          this.multiDepatureData.forEach((d) => {
            d.radios = false;
          });
        }
      });
    },

    multione(data, multindex, index) {
      //  console.log(data, multindex,index,'data, multindex,indexuuuuufdfdjjb')

      this.multiDepatureData.find((e, j) => {
        console.log(j, multindex, index, "nanduuuuu");
        if (multindex == j) {
          console.log(e, "wwwwwwwwww");
          e.radios = true;
        } else {
          console.log(e, "wwwwwtttttwwwww");
          e.radios = false;
        }
      });

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = true;
          v.radioData = false;
          v.radiretData = false;
          this.multireturnData.forEach((c, k) => {
            if (c.Returnjourney.Time == data.Returnjourney.Time) {
              console.log(c, k, (c.radiosret = false), "wwww");
              c.radiosret = false;
              v.radiosret = true;
              k == 0 ? (v.radiretData = true) : (c.radiosret = false);

              this.multireturnData.forEach((d, j) => {
                if (k == j) {
                  d.radiosret = true;
                } else {
                  d.radiosret = false;
                }
              });
            } else {
              c.radiosret = false;
            }
          });
        }
      });

      this.flightdetailsData = data;
    },

    multiredone(data, multindex, index) {
      console.log(data, multindex, index, "data, multindex,indexrrrrrrrr");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radiosret = true;
          v.radioData = false;
          v.radiretData = false;
          this.multiDepatureData.forEach((c, k) => {
            if (c.Depaturejourney.Time == data.Depaturejourney.Time) {
              if (k == 0) {
                console.log(c, k, "wwww");
                v.radioData = true;
              } else {
                this.multiDepatureData.forEach((d, j) => {
                  if (k == j) {
                    console.log(d, "konoo");
                    d.radios = true;
                  } else {
                    d.radios = false;
                  }
                });
              }
            }
          });
        } else {
          v.radiosret = false;
        }
      });
      this.multireturnData.find((e, j) => {
        console.log(e, j, "ppppprrrrrrrrr");
        if (multindex == j) {
          e.radiosret = true;
        } else {
          e.radiosret = false;
        }
      });
      this.flightdetailsData = data;
    },

    multicityone1(data, multindex, index) {
      console.log(data, multindex, index);
      this.radioData1 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios1 = false;
        }
      });
    },

    multicityone2(data, multindex, index) {
      this.radioData2 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios2 = false;
        }
      });
    },
    multicityone3(data, multindex, index) {
      this.radioData3 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios3 = false;
        }
      });
    },
    multicityone4(data, multindex, index) {
      this.radioData4 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios4 = false;
        }
      });
    },


    // -----------deptime  end-------

    // releseFilter() {
    //   if (
    //     this.timeFilterData1 &&
    //     this.timeFilterData2 &&
    //     this.timeFilterData3
    //   ) {
    //     this.totalTimeData();
    //   } else if (
    //     !this.timeFilterData1 &&
    //     !this.timeFilterData2 &&
    //     !this.timeFilterData3
    //   ) {
    //     this.totalTimeData();
    //   } else if (this.timeFilterData1 && this.timeFilterData2) {
    //     this.timetwoFilter1();
    //   } else if (this.timeFilterData1 && this.timeFilterData3) {
    //     this.timetwoFilter2();
    //   } else if (this.timeFilterData1) {
    //     this.TimeFilterData6();
    //   } else if (this.timeFilterData2) {
    //     this.TimeFilterDataafter12();
    //   } else if (this.timeFilterData3) {
    //     this.TimeFilterDataafter18();
    //   }
    // },

    // -------arrtime start ---------


    // timetwoFilter1() {
    //   // alert("kee");
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }

    //   $event.forEach((v) => {
    //     v.Depature.forEach((c, i) => {
    //       if (i === 0 && $twoFilter) {
    //         const departureTime = c.Departure.Time;
    //         if (departureTime > "06:00" && departureTime < "18:00") {
    //           $result.push(v);
    //           console.log("oooo", departureTime);
    //         }
    //       }

    //       if (
    //         (i == v.Depature.length - 1 && this.timeFilterArrData1) ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3
    //       ) {
    //         const arrivalTime = c.Arrival.Time;
    //         if (arrivalTime > "06:00" && arrivalTime < "18:00") {
    //           $result.push(v);
    //           console.log("oooo222");
    //         }
    //       }
    //     });
    //   });

    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },
    // timetwoFilter2() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       // console.log('vasanth')
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       // console.log('keerthi')
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if ($twoFilter) {
    //       if (
    //         v.Depature[0].Departure.Time > "12:00" &&
    //         v.Depature[0].Departure.Time < "18:00"
    //       ) {
    //         //  <!-- $result.push(v) -->
    //       } else {
    //         $result.push(v);
    //       }
    //     }

    //     if (
    //       this.timeFilterArrData1 ||
    //       this.timeFilterArrData2 ||
    //       this.timeFilterArrData3
    //     ) {
    //       if (
    //         v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
    //         v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
    //       ) {
    //         //  $result.push(v)
    //       } else {
    //         $result.push(v);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

    // timetwoFilter3() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if ($twoFilter) {
    //       if (
    //         v.Depature[0].Departure.Time > "06:00" &&
    //         v.Depature[0].Departure.Time < "12:00"
    //       ) {
    //         //  <!-- $result.push(v) -->
    //       } else {
    //         $result.push(v);
    //       }
    //     }

    //     if (
    //       this.timeFilterArrData1 ||
    //       this.timeFilterArrData2 ||
    //       this.timeFilterArrData3
    //     ) {
    //       if (
    //         v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
    //         v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
    //       ) {
    //         //  $result.push(v)
    //       } else {
    //         $result.push(v);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

    // removeFilter1() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //       console.log("keerthi", $event);
    //     } else {
    //       $event = this.rountresult;
    //       console.log("keerthi", $event);
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if ($twoFilter) {
    //       if (
    //         v.Depature[0].Departure.Time > "06:00" &&
    //         v.Depature[0].Departure.Time < "12:00"
    //       ) {
    //         console.log(
    //           v.Depature[0].Departure.Time,
    //           "v.Depature[0].Departure.Time "
    //         );
    //       } else {
    //         $result.push(v);
    //         console.log($result, "$result");
    //       }
    //     }

    //     if (
    //       this.timeFilterArrData1 ||
    //       this.timeFilterArrData2 ||
    //       this.timeFilterArrData3
    //     ) {
    //       if (
    //         v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
    //         v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
    //       ) {
    //         console.log("llllll");
    //       } else {
    //         $result.push(v);
    //         console.log("keerthi$resul6666666666666", $result);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },
    // removeFilter2() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       console.log("vasanth");
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       // console.log('keerthi')
    //       $event = this.rountresult;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if ($twoFilter) {
    //       if (
    //         v.Depature[0].Departure.Time > "12:00" &&
    //         v.Depature[0].Departure.Time < "18:00"
    //       ) {
    //         //  <!-- $result.push(v) -->
    //       } else {
    //         $result.push(v);
    //       }
    //     }

    //     if (
    //       this.timeFilterArrData1 ||
    //       this.timeFilterArrData2 ||
    //       this.timeFilterArrData3
    //     ) {
    //       if (
    //         v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
    //         v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
    //       ) {
    //         //  $result.push(v)
    //       } else {
    //         $result.push(v);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },
    // removeFilter3() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       // console.log('vasanth')
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       // console.log('keerthi')
    //       $event = this.rountresult;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if ($twoFilter) {
    //       if (
    //         v.Depature[0].Departure.Time > "06:00" &&
    //         v.Depature[0].Departure.Time < "18:00"
    //       ) {
    //         $result.push(v);
    //       }
    //       //        else {
    //       //        $result.push(v)
    //       //  }
    //     }

    //     if (
    //       this.timeFilterArrData1 ||
    //       this.timeFilterArrData2 ||
    //       this.timeFilterArrData3
    //     ) {
    //       if (
    //         v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
    //         v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
    //       ) {
    //         $result.push(v);
    //       }
    //       //        else {
    //       //        $result.push(v)
    //       //  }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

    // totalTimeData() {
    //   let $event = [];

    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       // console.log('vasanth')
    //       $event = this.rountresult;
    //     } else {
    //       // console.log('keerthi')
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timeFilterData1 ||
    //         this.timeFilterData2 ||
    //         this.timeFilterData3) &&
    //       (this.timeFilterArrData1 ||
    //         this.timeFilterArrData2 ||
    //         this.timeFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }

    //   if (this.filterData.length > 0) {
    //     $event.sort((a, b) => a.perperson - b.perperson);
    //     this.rountresult = $event;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     $event.sort((a, b) => a.perperson - b.perperson);
    //     this.onewayresulted = $event;
    //   }
    // },

    // -----------------return journey time filter start--------------


    // timetwoReturnFilter1() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }

    //   $event.forEach((v) => {
    //     v.Return.forEach((c, i) => {
    //       if (i == 0 && $twoFilter) {
    //         const departureTime = c.Departure.Time;
    //         if (departureTime > "06:00" && departureTime < "18:00") {
    //           $result.push(v);
    //         }
    //       }

    //       if (
    //         (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3
    //       ) {
    //         const arrivalTime = c.Arrival.Time;
    //         if (arrivalTime > "06:00" && arrivalTime < "18:00") {
    //           $result.push(v);
    //         }
    //       }
    //     });
    //   });

    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },
    // timetwoReturnFilter2() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }

    //   $event.forEach((v) => {
    //     v.Return.forEach((c, i) => {
    //       if (i == 0 && $twoFilter) {
    //         const departureTime = c.Departure.Time;
    //         if (departureTime > "12:00" && departureTime < "18:00") {
    //           // $result.push(v);
    //         } else {
    //           $result.push(v);
    //         }
    //       }

    //       if (
    //         (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3
    //       ) {
    //         const arrivalTime = c.Arrival.Time;
    //         if (arrivalTime > "12:00" && arrivalTime < "18:00") {
    //           // $result.push(v);
    //         } else {
    //           $result.push(v);
    //         }
    //       }
    //     });
    //   });

    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },
    // timetwoReturnFilter3() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }

    //   $event.forEach((v) => {
    //     v.Return.forEach((c, i) => {
    //       if (i == 0 && $twoFilter) {
    //         const departureTime = c.Departure.Time;
    //         if (departureTime > "06:00" && departureTime < "12:00") {
    //           // $result.push(v);
    //         } else {
    //           $result.push(v);
    //         }
    //       }

    //       if (
    //         (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3
    //       ) {
    //         const arrivalTime = c.Arrival.Time;
    //         if (arrivalTime > "06:00" && arrivalTime < "12:00") {
    //           // $result.push(v);
    //         } else {
    //           $result.push(v);
    //         }
    //       }
    //     });
    //   });

    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

    // totalReturnTimeData() {
    //   let $event = [];

    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       //  $twoFilter=false
    //     } else {
    //       $event = this.filterData;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       //  $twoFilter=false
    //     } else {
    //       $event = this.onewayfilterData;
    //     }
    //   }

    //   if (this.filterData.length > 0) {
    //     $event.sort((a, b) => a.perperson - b.perperson);
    //     this.rountresult = $event;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     $event.sort((a, b) => a.perperson - b.perperson);
    //     this.onewayresulted = $event;
    //   }
    // },

    // removeReturnFilter1() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.rountresult;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if (
    //       $twoFilter &&
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3)
    //     ) {
    //       console.log("pppppp2222");
    //       if (
    //         v.Return[0].Departure.Time > "06:00" &&
    //         v.Return[0].Departure.Time < "12:00"
    //       ) {
    //         // console.log('tttv')
    //       } else {
    //         $result.push(v);
    //       }
    //     }

    //     if (
    //       this.timearrFilterArrData1 ||
    //       this.timearrFilterArrData2 ||
    //       this.timearrFilterArrData3
    //     ) {
    //       console.log("pppppp222266666666666666");
    //       if (
    //         v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
    //         v.Return[v.Return.length - 1].Arrival.Time < "12:00"
    //       ) {
    //         //  $result.push(v)
    //       } else {
    //         $result.push(v);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

    // removeReturnFilter2() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.rountresult;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if (
    //       $twoFilter &&
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3)
    //     ) {
    //       if (
    //         v.Return[0].Departure.Time > "12:00" &&
    //         v.Return[0].Departure.Time < "18:00"
    //       ) {
    //         // console.log('tttv')
    //       } else {
    //         $result.push(v);
    //       }
    //     }

    //     if (
    //       this.timearrFilterArrData1 ||
    //       this.timearrFilterArrData2 ||
    //       this.timearrFilterArrData3
    //     ) {
    //       if (
    //         v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
    //         v.Return[v.Return.length - 1].Arrival.Time < "18:00"
    //       ) {
    //         //  $result.push(v)
    //       } else {
    //         $result.push(v);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

    // removeReturnFilter3() {
    //   let $event = [];
    //   let $result = [];
    //   let $twoFilter = true;
    //   if (this.filterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.rountresult;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.rountresult;
    //     }
    //   }

    //   if (this.onewayfilterData.length > 0) {
    //     if (
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3) &&
    //       (this.timearrFilterArrData1 ||
    //         this.timearrFilterArrData2 ||
    //         this.timearrFilterArrData3)
    //     ) {
    //       $event = this.onewayresulted;
    //       $twoFilter = false;
    //     } else {
    //       $event = this.onewayresulted;
    //     }
    //   }
    //   $event.forEach((v) => {
    //     if (
    //       $twoFilter &&
    //       (this.timearrFilterData1 ||
    //         this.timearrFilterData2 ||
    //         this.timearrFilterData3)
    //     ) {
    //       if (
    //         v.Return[0].Departure.Time > "06:00" &&
    //         v.Return[0].Departure.Time < "18:00"
    //       ) {
    //         console.log("tttv22", v.Return[0].Departure.Time);
    //         $result.push(v);
    //       }
    //     }

    //     if (
    //       this.timearrFilterArrData1 ||
    //       this.timearrFilterArrData2 ||
    //       this.timearrFilterArrData3
    //     ) {
    //       if (
    //         v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
    //         v.Return[v.Return.length - 1].Arrival.Time < "18:00"
    //       ) {
    //         $result.push(v);
    //       }
    //     }
    //   });
    //   if (this.filterData.length > 0) {
    //     this.rountresult = $result;
    //   }
    //   if (this.onewayfilterData.length > 0) {
    //     this.onewayresulted = $result;
    //   }
    // },

   airlineFilter() {
      let storeAirline = new Set();
      let $event = [];

      if (this.rountresult.length > 0) {
        $event = this.rountresult;
      }

      if (this.onewayresulted.length > 0) {
        $event = this.onewayresulted;
      }

      if (this.multicityData.length > 0) {
        $event = this.multicityData;
      }

      if (this.rountresult) {
        this.rountresult.forEach((data) => {
          data.Depature.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
          data.Return.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
        });
      }
      if(this.onewayresulted){
        this.onewayresulted.forEach((data) => {
          data.Depature.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
        })
      }
      if(this.multicityData){
        this.multicityData.forEach((data) => {
          data.Flight1.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          data.Flight2.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          if(data.Flight3){
            data.Flight3.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          }
          if(data.Flight4){
            data.Flight4.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name)
          })
          }
        })
      }

      $event.forEach((v) => {
        if (v.ownername && !storeAirline.has(v.ownername)) {
          storeAirline.add(v.ownername);
        }
      });

     this.airlineList = Array.from(storeAirline)
    .sort()
    .map(s => ({ airline: s, active: true }));

    console.log(this.airlineList,'airlineList')

      this.filterAirline = this.airlineList;
  },
     selectAirline(data, index) {
      let $data = []
      let $result = [];
       if (this.rountresult.length > 0) {
        $data = this.rountresult;
      }

      if (this.onewayresulted.length > 0) {
        $data = this.onewayresulted;
      }
      if(this.multicityData.length > 0){
        $data = this.multicityData;
      }
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
        else if(this.multiFilterData.length > 0){
        this.multicityData = $result;
      }
      });
      this.applyFilters()

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      // $result.sort((a, b) => a.perperson - b.perperson);
      // this.rountresult = $result;
    },
     airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }
      if(this.multicityData.length > 0){
        $data = this.multiFilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
        else if(this.multiFilterData.length > 0){
        this.multicityData = $result;
      }
      this.applyFilters();
    },

     airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      if(this.rountresult.length > 0){
        this.rountresult = this.filterData;
      }
      else if(this.onewayresulted.length > 0){
        this.onewayresulted = this.onewayfilterData
      }
      else if(this.multicityData.length > 0){
        this.multicityData = this.multiFilterData
      }
      this.applyFilters();
    },
    stopFilter() {
      this.filterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    multicitystopFilter() {
      this.multiFilterData.forEach((v) => {
        if (
          v.Flight1JourneyTime.Stops === 0 ||
          v.Flight2JourneyTime.Stops === 0 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops === 0) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops === 0)
        ) {
          this.showNonStop = true;
          console.log("ooooooooo");
        } else if (
          v.Flight1JourneyTime.Stops === 1 ||
          v.Flight2JourneyTime.Stops === 1 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops === 1) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops === 1)
        ) {
          this.showOneStop = true;
        } else {
          this.showOneplusStop = true;
        }
      });
    },

    gettimedep() {
      // for (let i = 0; i < this.rountresult.length; i++) {
      //   for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
      //     const currentIndex = j;
      //     const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

      //     const time1 = new Date(
      //       `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
      //     );
      //     const time2 = new Date(
      //       `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
      //     );

      //     const diffInMilliseconds = Math.abs(time2 - time1);
      //     const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

      //     const hours = Math.floor(diffInMinutes / 60);
      //     const minutes = diffInMinutes % 60;

      //     this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
      //       .toString()
      //       .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
      //   }
      // }

      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes1 = c;
          }
        });
      });
    },
    calculateTotalTime() {
      let $data = [];

      if (this.rountresult.length > 0) {
        this.rountresult.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.rountresult.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      } else if (this.onewayresulted.length > 0) {
        this.onewayresulted.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.onewayresulted.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      }
    },
    gettimeret() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes2 = c;
          }
        });
      });
    },

    async getlayTime() {
      let layoverTime = [];
      for (let i = 0; i < this.onewayresulted.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.onewayresulted.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes = c;
          }
        });
      });
    },

    getmultitime1() {
      let layoverTime1 = [];
      let layoverTime2 = [];
      let layoverTime3 = [];
      let layoverTime4 = [];

      for (let i = 0; i < this.multicityData.length; i++) {
        let layover1 = [];
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layover1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime1.push(layover1);

        this.multicityData.forEach((a, i) => {
          layoverTime1.forEach((c, j) => {
            if (i == j) {
              a.layoverTimes1 = c;
            }
          });
        });

        if (this.multicityData[i].Flight3) {
          let layover2 = [];
          for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover2.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime2.push(layover2);

          this.multicityData.forEach((a, i) => {
            layoverTime2.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes2 = c;
              }
            });
          });
        }
        if (this.multicityData[i].Flight3) {
          let layover3 = [];
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover3.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime3.push(layover3);

          this.multicityData.forEach((a, i) => {
            layoverTime3.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes3 = c;
              }
            });
          });
        }

        if (this.multicityData[i].Flight4) {
          let layover4 = [];
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover4.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime4.push(layover4);

          this.multicityData.forEach((a, i) => {
            layoverTime4.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes4 = c;
              }
            });
          });
        }
      }
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);

      this.initialChangeValue = this.changevalue

      this.currencyCode = this.preferCurrency;
    },
    filterOutBound(){
      // console.log(data,'outBoundTime')
      let outBoundTime1 = []
      let inBoundTime1 = []
      if(this.rountresult){
      this.rountresult.forEach((v) => {
        if(v.Depature){
           let result = v.Depaturejourney.Time.split(" ");
            let hours = parseInt(result[0]);
            let minutes = parseInt(result[2]);

            let roundedHours = Math.round(hours + minutes / 60);
                      outBoundTime1.push(roundedHours + "Hour")
          }
        if(v.Return){
          this.isReturn = true;
            let result1 = v.Returnjourney.Time.split(" ");
            let hours = parseInt(result1[0]);
            let minutes = parseInt(result1[2]);

            let roundedHours = Math.round(hours + minutes / 60);
                      inBoundTime1.push(roundedHours + "Hour")
        }
        });
      }
      if(this.onewayresulted) {
        this.onewayresulted.forEach((v) => {
          console.log(this.onewayresulted,'onewayyyy')
        if(v.Depature){

            let result = (v.Depaturejourney.Time.split("H"))[0]
            outBoundTime1.push(result + "Hour")
        }
        });
      }

        let hours = [...new Set(outBoundTime1)];
        // console.log(hours,'hours')
          let numbers = hours.map(str => parseInt(str));

          let minHour = Math.min(...numbers);
          let maxHour = Math.max(...numbers);
          this.defaultOutBoundTime = `${maxHour} hour`
          this.outBound = `${maxHour} hour`
          for (let i = minHour; i <= maxHour; i++) {
              this.outBoundTime.push(`${i} hour`);
          }
          // console.log(this.outBoundTime,'this.outBoundTimethis.outBoundTime')

          let hours1 = [...new Set(inBoundTime1)];
          let numbers1 = hours1.map(str => parseInt(str));

          let minHour1 = Math.min(...numbers1);
          let maxHour1 = Math.max(...numbers1);
          this.defaultInBoundTime = `${maxHour1} hour`
          this.inBound = `${maxHour1} hour`
          for (let i = minHour1; i <= maxHour1; i++) {
              this.inBoundTime.push(`${i} hour`);
          }
          // console.log(this.inBoundTime,'this.outBoundTimethis.outBoundTime')
    },
    filterOutBoundMulti(){
      let outBoundTime1 = []
      this.multicityData.forEach((v) => {
       for (let i = 1; i <= 4; i++) {
        if (v[`Flight${i}`]) {
          let result = v[`Flight${i}JourneyTime`].Time.split("H");
          let hours = parseInt(result[0]);
          let minutes = parseInt(result[1]);
          let roundedHours = Math.round(hours + minutes / 60);
          outBoundTime1.push(roundedHours + "Hour");
        }
       }
       })
        let hours = [...new Set(outBoundTime1)];
        // console.log(hours,'hours')
          let numbers = hours.map(str => parseInt(str));

          let minHour = Math.min(...numbers);
          let maxHour = Math.max(...numbers);
          this.defaultOutBoundMulti = `${maxHour} hour`
          this.outBoundMulti = `${maxHour} hour`
          for (let i = minHour; i <= maxHour; i++) {
              this.outBoundTimeMulti.push(`${i} hour`);
          }
          console.log(this.outBoundTimeMulti,'outBoundoutBound')
    },

    getAiportFilter() {
  if (this.rountresult.length > 0) {
    console.log('yessss1')
    let fromAirportsSet = new Set();
    let toAirportsSet = new Set();

    this.filterData.forEach(data => {
      fromAirportsSet.add(data.Depature[0].Departure.AirportName);
      toAirportsSet.add(data.Depature[data.Depature.length - 1].Arrival.AirportName);
    });

    this.fromAirportName = Array.from(fromAirportsSet);
    this.toAirportName = Array.from(toAirportsSet);
  }
  console.log(this.onewayresulted,'onewayyyyyyyy')
  if (this.onewayresulted.length > 0) {
    console.log('yessss')
    let fromAirportsSet = new Set();

    this.onewayfilterData.forEach(data => {
      fromAirportsSet.add(data.Depature[0].Departure.AirportName);
    });

    this.fromAirportName = Array.from(fromAirportsSet);
  }
},

getAiportFilterMulti() {
    let flight1From = new Set();
    let flight1To = new Set();
    let flight2From = new Set();
    let flight2To = new Set();
    let flight3From = new Set();
    let flight3To = new Set();
    let flight4From = new Set();
    let flight4To = new Set();

    this.multiFilterData.forEach(data => {
      flight1From.add(data.Flight1[0].Departure.AirportName);
      flight1To.add(data.Flight1[data.Flight1.length - 1].Arrival.AirportName);
      flight2From.add(data.Flight2[0].Departure.AirportName);
      flight2To.add(data.Flight2[data.Flight2.length - 1].Arrival.AirportName);

      if (data.Flight3) {
        flight3From.add(data.Flight3[0].Departure.AirportName);
        flight3To.add(data.Flight3[data.Flight3.length - 1].Arrival.AirportName);
      }

      if (data.Flight4) {
        flight4From.add(data.Flight4[0].Departure.AirportName);
        flight4To.add(data.Flight4[data.Flight4.length - 1].Arrival.AirportName);
      }
    });

    this.multiAirport = [
      {
        from: Array.from(flight1From) || [],
        to: Array.from(flight1To) || []
      },
      {
        from: Array.from(flight2From) || [],
        to: Array.from(flight2To) || []
      },
      {
        from: Array.from(flight3From) || [],
        to: Array.from(flight3To) || []
      },
      {
        from: Array.from(flight4From) || [],
        to: Array.from(flight4To) || []
      }
    ];
    console.log(this.multiAirport,'multiAirportmultiAirport')
},


    // Filter initailly Working ------->

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));
      let baggageList = [];
      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          if (onewayresult[i]) {
            baggageList =
              onewayresult[i].DataLists.BaggageAllowanceList.BaggageAllowance;
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            let shoppingId = onewayresult[i].ShoppingResponseId;
            let refund = [];
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers.Offer;

            let $data_onewayId =
              onewayresult[i].OffersGroup.AirlineOffers.Offer;

            // console.log($data_onewayId,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];
            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              currency.push(this.displayCurrency);

              // if (s.ReqCurrency == "USD") {
              //   currency.push("US$");
              // } else if (s.ReqCurrency == "CAD") {
              //   currency.push("CA$");
              // } else {
              //   currency.push("US$");
              // }

              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });
            // let seats = [];
            // seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            let Baggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              if ( $data[j].OfferItem[0][0].BaggageAllowance[0] && $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef) {
                Baggage.push($data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef);
              }
              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }
            let BaggageData = [];

            Baggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].multipleDatas = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].onewaymulticheck = true;
              this.onewayresulted[i].BaggageData = BaggageData[i];
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = this.aduls;
              this.onewayresulted[i].Child = this.aduls1;
              this.onewayresulted[i].Inf = this.aduls2;
              this.onewayresulted[i].radios = true;
              this.onewayresulted[i].radiosData = false;
              this.onewayresulted[i].hitId = `${currency[i]}-${this.uniqueId}`;
            }
            this.onedatafil = true;
            console.log(this.onewayresulted, "oneway.....w...w..w..w");
            this.onewayfilterData = this.onewayresulted;

            this.getlayTime();
            // this.gettimeret();
            // this.calculateTotalTime();
            ///this.singlepriceData();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();
            this.airlineFilter();
            this.filterOutBound();
            this.getAiportFilter();
            this.priceRange();
            this.Shortest();
            this.stopFilterOneway();

            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }

            this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
            console.log(
              this.onewayresulted,
              "this.onewayresultedthis.onewayresulted"
            );
          }
        }
      }
    },

    multione1(data, multindex, index) {
      // console.log(data, multindex, index, 'data, multindex,index')
      this.radiosData = 1;
      this.onewayresulted.find((c, i) => {
        if (i == index) {
          c.radios = false;
        }
      });

      this.flightdetailsData = data;
    },

    singlepriceData() {
      let $event = [];
      let $data = this.onewayresulted;
      this.onewayresulted.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.onewayresulted = $event.sort((a, b) => a.perperson - b.perperson);

      this.onewayresulted.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.TotalPrice == b.TotalPrice &&
            a.ownername == b.ownername &&
            a.Depaturejourney.Time !== b.Depaturejourney.Time
          ) {
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.onewayresulted[i].multi = "Show more Options";
        }
      });

      this.onewayfilterData = this.onewayresulted;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreData(data, index) {
      console.log(data, index, "vasanrh");
      this.radios = 1;
      this.onewayresulted.find((v, i) => {
        if (i == index) {
          v.multipleDatas = !v.multipleDatas;
          v.onewaymulticheck = !v.onewaymulticheck;
        } else {
          v.multipleDatas = false;
          v.onewaymulticheck = true;
        }
      });

      let $event = [];
      this.onewaymultiData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      let $data = [];
      $event.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
      });

      this.multiplesameData = $data;
    },

    stopFilterOneway() {
      this.onewayfilterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    sended() {
      // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
      this.onewayfilterData = [];
      this.rountresult = [];
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];

        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY-MM-DD");
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      this.onewayfilterData = [];
      this.rountresult = [];

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        let result1 = [];
        // console.log(this.oneway, "onewayonewayonewayonewayoneway");
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");

        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sended1() {
      // console.log("kkkkkkkkkkkkkkkkkk");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      // if (this.concatenatedArray) {
      //   this.concatenatedArray.forEach((v) => {
      //     console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
      //     resul.push(v);
      //   });
      // } else {
      //   console.log(this.concatenatedArray);
      // }

      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      // this.oneway.class = this.select;
      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.Rountdata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;

        console.log(
          this.Rountdata,
          "this.Rountdatathis.Rountdatathis.Rountdatathis.Rountdatathis.Rountdata"
        );

        axios
          .post(this.makeSearchUrl, this.Rountdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          .then((response) => {
            // console.log(response," this.responseData this.responseData this.responseData");

            this.responseData.push(response.data);

            console.log(
              this.responseData,
              "this.responseData this.responseData this.responseData"
            );

            let rountData = [];
            rountData = JSON.stringify(this.responseData);
            localStorage.setItem("rounttrip", rountData);

            let result = "";
            result = localStorage.getItem("rounttrip");
            this.localData.push(JSON.parse(result));
            // console.log(this.localData, " this.localData this.localData");
            if (this.localData) {
              this.roundedtrip();
            }

            // if (response.data.Errors =='Errors') {
            //   this.searchresult = false;
            //   this.searchloader = true;
            //   setTimeout(() => {
            //     this.pageError = true;
            //     this.searchresult = false;
            //   this.searchloader = false;
            //   }, 7000);
            // }

            // else {

            //   this.responseData.push(response.data.AirShoppingRS);
            //   console.log(this.responseData,'this.responseDatathis.responseData')
            //   console.log(
            //     this.responseData,
            //     " this.responseData this.responseData this.responseData"
            //   );
            //   let rountData = [];
            //   rountData = JSON.stringify(this.responseData);
            //   localStorage.setItem("rounttrip", rountData);
            //   let result = "";
            //   result = localStorage.getItem("rounttrip");
            //   this.localData.push(JSON.parse(result));
            //   console.log(this.localData, " this.localData this.localData");
            //   if (this.localData) {
            //     this.roundedtrip();
            //   }
            // }
          })

          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedone1() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );
        // let rountdetail = {
        //   from: this.roundfrom,
        //   to: this.roundto,
        //   dedate: this.urldedate,
        //   redate: this.urlredate,
        //   class: this.oneway.class,
        //   child: this.oneway.child,
        //   adult: this.oneway.adult,
        //   infrant: this.oneway.infrant,
        //   city: this.oneway.city,
        // };

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.onewaydata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;

        console.log(this.onewaydata, "this.onewaydatathis.onewaydata");

        axios
          .post(this.makeSearchUrl, this.onewaydata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // console.log(response, "responseresponseresponse");
            this.responseData.push(response.data);
            // console.log(
            //   this.responseData,
            //   "this.responseDatathis.responseData"
            // );
            let rountData = [];
            rountData = JSON.stringify(this.responseData);

            localStorage.setItem("onewaytrip", rountData);

            if (rountData) {
              this.onewaytrip();
            }

            //             if (response.data.AirShoppingRS.Errors) {
            //               this.searchresult = false;
            //               this.searchloader = true;
            //               setTimeout(() => {
            //                 this.pageError = true;
            //                 this.searchresult = false;
            //               this.searchloader = false;
            //               }, 7000);
            //             }

            // else{
            //   this.responseData.push(response.data.AirShoppingRS);
            // console.og(this.responseData,'this.responseDatathis.responseData')
            // let rountData = [];
            // rountData = JSON.stringify(this.responseData);

            // localStorage.setItem("onewaytrip", rountData);
            // this.$router.push({ path: "/search", query: rountdetail });

            // if (rountData) {
            //   this.onewaytrip();
            // }
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedMulti() {
      this.multi_city.adult = this.aduls;
      this.multi_city.child = this.aduls1;
      this.multi_city.infrant = this.aduls2;
      this.multi_city.class = this.classType;

      console.log(this.multi_city, "datasdatasdatas");

      let multicityData = {
        itinery: [],
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      };

      multicityData.class = this.multi_city.class;
      multicityData.adult = this.multi_city.adult;
      multicityData.child = this.multi_city.child;
      multicityData.infrant = this.multi_city.infrant;
      multicityData.city = this.multi_city.city;
      this.multi_city.itinery.forEach((v) => {
        console.log(v, "multy");
        let itineraryObject = {
          from: v.from.name ? v.from.name : v.from,
          to: v.to.name ? v.to.name : v.to,
          depdata: moment(v.depdata).format("YYYY-MM-DD"),
        };
        multicityData.itinery.push(itineraryObject);
      });

      console.log(multicityData, "this.multicityData5555555555555");

      setTimeout(() => {
        location.reload();
      }, 500);

      let muti_city = JSON.stringify(multicityData);
      this.$router.push({ path: "/search", query: { muti_city } });
    },

    sendedMulticity() {
      for (let i = 0; i < this.multi_city.itinery.length; i++) {
        if (this.multi_city.itinery[i].from) {
          this.multi_city.itinery[i].multiFromError = false;

          // console.log(this.multi_city.itinery[i].from, "mememememe.....");
        } else {
          this.multi_city.itinery[i].multiFromError = true;
        }

        if (this.multi_city.itinery[i].to) {
          this.multi_city.itinery[i].multiToError = false;
        } else {
          this.multi_city.itinery[i].multiToError = true;
        }

        if (this.multi_city.itinery[i].depdata) {
          this.multi_city.itinery[i].multiDateError = false;
        } else {
          this.multi_city.itinery[i].multiDateError = true;
        }
      }
    },

    selected(result) {
      this.dataLoader = true;
      console.log(result, "ppppp");
      if (result.Flight1) {
        setTimeout(() => {
          let obj = {
            adult: this.multi_city.adult,
            child: this.multi_city.child,
            inf: this.multi_city.infrant,
            class: this.multi_city.class,
          };
          console.log(obj, "ppp");

          localStorage.setItem(
            `${"pass"}-${result.selectId}`,
            JSON.stringify(obj)
          );
          localStorage.setItem(
            `${"multi"}-${result.selectId}`,
            JSON.stringify(result)
          );
          this.$router.push({
            path: "/flightinfo",
            query: { pass_uid: `${"multi"}-${result.selectId}` },
          });
        }, 3000);
      } else {
        this.dataLoader = true;
        setTimeout(() => {
          this.dataLoader = false;
          localStorage.setItem(
            `${result.currency}-${result.selectId}`,
            JSON.stringify(result)
          );
          this.$router.push({
            path: "/flightinfo",
            query: { pass_uid: `${result.currency}-${result.selectId}` },
          });
        }, 3000);
      }
    },

    fromClick($event) {
      // console.log($event, "p.....1");
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom(event) {
      if (event.length >= 3) {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          // this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          // this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }
        }
      });

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    // autocompletJson() {

    //   const getConfigData = getConfigDataFromLocalStorage();

    //   this.airlineIcaoUrl =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.url;
    //   this.airlineIcaoType =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.type;

    //   axios
    //     .get(this.airlineIcaoUrl, {
    //       headers: {
    //         "Content-Type": this.airlineIcaoType,
    //       },
    //     })
    //     .then((response) => {
    //       let airlineIcao = response.data;

    //       if (airlineIcao) {

    //         Object.keys(airlineIcao).forEach((icao) => {
    //           const airport = airlineIcao[icao];

    //           if (airport.iata !== "") {
    //             this.fromAirports.push({
    //               iata: airport.iata,
    //               icao: airport.icao,
    //               places: ` ${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //             });
    //           }
    //         });
    //       }
    //     })

    //     .catch((error) => {
    //       console.log(error, "erroroor.....");
    //     });
    // },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      this.fromAirports = [];

      if (event.query.length >= 3) {
        await axios
          .get(
            `${"https://dltest.wistirna.com/api/airportautocomplete?airportString"}=${
              event.query
            }`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from) {
          this.maximumletter = true;
        }
        if (this.oneway.to) {
          this.maximumletter1 = true;
        }
      }
    },
    focus1() {
      setTimeout(() => {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.to) {
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        }, 100);
      }
    },

    focus2() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.dedate) {
        this.$refs.dpRef1.openMenu();
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          // console.log(this.multi_city.itinery[0].to, "bfbfbfbf.....");
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    Multifocus1(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    Multifocus2(data, index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        console.log(data, "bfbfbfbf.....");
        // return data.to.name

        // for (let i = 0; i < 1; i++) {
        //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
        //   this.input[1].from = this.input[0].to;
        // }

        for (let i = 0; i < 1; i++) {
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
    handleCalenderResize() {
      if (window.innerWidth < 400) {
        this.multiCalender = false;
      } else {
        this.multiCalender = true;
      }
    },

    // Form Header section

    getMultifrom(data) {
      // console.log(data, 'datatatetee')

      if (data.itinery[0].from === null) {
        return null;
      } else {
        const $data = data.itinery[0]?.from?.name;
        return $data || data.itinery[0].from;
      }

      // if (data.itinery[0].from.name) {
      //   return data.itinery[0].from.name
      // }
      // else {
      //   return data.itinery[0].from
      // }
    },
    getMultiDedate1(data) {
      if (data.itinery[0].depdata) {
        // return data.itinery[0].depdata
        return moment(data.itinery[0].depdata, "DD-MMM-YYYY").format(
          "DD-MMM-YYYY"
        );
      } else {
        return "";
      }
    },

    getMultiTo(data) {
      // console.log(data, "Testing...1...1...1.");

      if (data.itinery[data.itinery.length - 1].to === null) {
        return null;
      } else {
        const $data = data.itinery[data.itinery.length - 1]?.to?.name;
        return $data || data.itinery[data.itinery.length - 1].to;
      }
    },

    getMultiDedate2(data) {
      if (data.itinery[data.itinery.length - 1].depdata) {
        // return data.itinery[data.itinery.length - 1].depdata
        return moment(
          data.itinery[data.itinery.length - 1].depdata,
          "DD-MMM-YYYY"
        ).format("DD-MMM-YYYY");
      } else {
        return "";
      }
    },

    getMultiClass(data) {
      console.log(data, "mdatatiCadata");
      if (data.class) {
        return data.class;
      } else {
        return "";
      }
    },

    //     getMultifrom(data) {
    //       if (data.length>0) {

    //   console.log(data,'wrthofjsdosjkdjk')

    //    if (data.itinery[0].from) {
    //           return data.itinery[0].from
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate1(data) {

    //          if (data.length>0) {
    //    if (data.itinery[0].depdata) {
    //           return data.itinery[0].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },

    //     getMultiTo(data) {

    //         if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].to) {
    //           return data.itinery[data.itinery.length - 1].to
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate2(data) {

    //          if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].depdata) {
    //           return data.itinery[data.itinery.length - 1].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }
    //     },

    //     getMultiClass(data) {
    //       if (data.classes) {
    //         return data.classes
    //       }
    //       else {
    //         return ''
    //       }

    //     },

    // filter functions

    filterByTimeRange(flight, range) {
          let [start, end] = range;
          const depTimeParts = flight.Depature[0].Departure.Time.split(":");
          let hours = parseInt(depTimeParts[0])
          let minutes = parseInt(depTimeParts[1])
          let totalHours = Math.floor(hours + minutes / 60);
          let value = totalHours >= start && totalHours < end;
          return value;
      },
      filterByTimeRange1(flight, range) {
          let [start, end] = range;
          const depTimeParts = flight.Depature[flight.Depature.length-1].Arrival.Time.split(":");
          let hours = parseInt(depTimeParts[0])
          let minutes = parseInt(depTimeParts[1])
          let totalHours = Math.floor(hours + minutes / 60);
          let value = totalHours >= start && totalHours < end;
          console.log(totalHours,value,'disabled...01')
          return value;
      },
      filterByTimeRangeRet(flight, range){
        let [start, end] = range;
          const retTimeParts = flight.Return[0].Departure.Time.split(":");
          let hours = parseInt(retTimeParts[0])
          let minutes = parseInt(retTimeParts[1])
          let totalHours = Math.floor(hours + minutes / 60);
          let value = totalHours >= start && totalHours < end;
          console.log(hours,value,'valuevalue')
          return value;
      },
      filterByTimeRangeRet1(flight,range){
          let [start, end] = range;
          const retTimeParts = flight.Return[flight.Return.length-1].Arrival.Time.split(":");
          let hours = parseInt(retTimeParts[0])
          let minutes = parseInt(retTimeParts[1])
          let totalHours = Math.floor(hours + minutes / 60);
          let value = totalHours >= start && totalHours < end;
          console.log(hours,value,'valuevalue')
          return value;
      },

      //multicity

      filterTimeRangeMulti(flight,range){
        let [start, end] = range;
        // const retTimeParts = flight.Flight3[0].Departure.Time.split(":");
        //   let hours = parseInt(retTimeParts[0])
        //   let minutes = parseInt(retTimeParts[1])
        //   let totalHours = Math.round(hours + minutes / 60);
        //   let value = totalHours >= start && totalHours < end;
        //   return value;

        let flightTimes = [
          flight.Flight1[0]?.Departure.Time,
          flight.Flight2[0]?.Departure.Time,
          flight.Flight3[0]?.Departure.Time,
          flight.Flight4[0]?.Departure.Time
        ];

        return flightTimes.some(time => {
          if (time) {
            let [hours, minutes] = time.split(":").map(Number);
            let totalHours = hours + minutes / 60;
            return totalHours >= start && totalHours < end;
          }
          return false;
        });
        },
      filterTimeMultiArr(flight,range){
        let [start, end] = range;
          // const retTimeParts = flight.Flight1[flight.Flight1.length-1].Arrival.Time.split(":");
          // let hours = parseInt(retTimeParts[0])
          // let minutes = parseInt(retTimeParts[1])
          // let totalHours = Math.round(hours + minutes / 60);
          // let value = totalHours >= start && totalHours < end;
          // return value;
          let flightTimes = [
          flight.Flight1[flight.Flight1.length-1].Arrival.Time,
          flight.Flight2[flight.Flight2.length-1].Arrival.Time,
          flight.Flight3[flight.Flight3.length-1].Arrival.Time,
          flight.Flight4[flight.Flight4.length-1].Arrival.Time
        ];

        return flightTimes.some(time => {
          if (time) {
            let [hours, minutes] = time.split(":").map(Number);
            let totalHours = Math.round(hours + minutes / 60);
            return totalHours >= start && totalHours < end;
          }
          return false;
        });
      },

        TimeFilter1() {
            this.timeFilterData1 = !this.timeFilterData1;
            this.applyFilters();
        },

        TimeFilter2() {
          this.timeFilterData2 = !this.timeFilterData2;
            this.applyFilters();
        },

        TimeFilter3() {
          this.timeFilterData3 = !this.timeFilterData3;
            this.applyFilters();
        },
        TimeFilter4() {
          this.timeFilterData4 = !this.timeFilterData4;
            this.applyFilters();
        },

        TimeFilterArr1() {
          this.timeFilterArrData1 = !this.timeFilterArrData1;
          this.applyFilters();
        },

        TimeFilterArr2() {
          this.timeFilterArrData2 = !this.timeFilterArrData2;
          this.applyFilters();
        },

        TimeFilterArr3() {
          this.timeFilterArrData3 = !this.timeFilterArrData3;
          this.applyFilters();
        },
        TimeFilterArr4() {
          this.timeFilterArrData4 = !this.timeFilterArrData4;
          this.applyFilters();
        },
        TimeretFilter1(){
          this.timearrFilterData1 = !this.timearrFilterData1;
          this.applyFilters();
        },
        TimeretFilter2(){
          this.timearrFilterData2 = !this.timearrFilterData2
          this.applyFilters();
        },
        TimeretFilter3(){
          this.timearrFilterData3 = !this.timearrFilterData3
          this.applyFilters();
        },
        TimeretFilter4(){
          this.timearrFilterData4 = !this.timearrFilterData4
          this.applyFilters();
        },
        TimeretFilterarr1(){
          this.timearrFilterArrData1 = !this.timearrFilterArrData1
          this.applyFilters();
        },
        TimeretFilterarr2(){
          this.timearrFilterArrData2 = !this.timearrFilterArrData2
          this.applyFilters();
        },
        TimeretFilterarr3(){
          this.timearrFilterArrData3 = !this.timearrFilterArrData3
          this.applyFilters();
        },
        TimeretFilterarr4(){
          this.timearrFilterArrData4 = !this.timearrFilterArrData4
          this.applyFilters();
        },

        //multicity
        TimeMulti1(){
          this.timeMulti1 = !this.timeMulti1
          this.applyFilters();
        },
        TimeMulti2(){
          this.timeMulti2 = !this.timeMulti2
          this.applyFilters();
        },
        TimeMulti3(){
          this.timeMulti3 = !this.timeMulti3
          this.applyFilters();
        },
        TimeMulti4(){
          this.timeMulti4 = !this.timeMulti4
          this.applyFilters();
        },
        TimeMultiArr1(){
          this.timeMultiArr1 = !this.timeMultiArr1
          this.applyFilters();
        },
        TimeMultiArr2(){
          this.timeMultiArr2 = !this.timeMultiArr2
          this.applyFilters();
        },
        TimeMultiArr3(){
          this.timeMultiArr3 = !this.timeMultiArr3
          this.applyFilters();
        },
        TimeMultiArr4(){
          this.timeMultiArr4 = !this.timeMultiArr4
          this.applyFilters();
        },

      applyFilters() {
        console.log(this.onewayresulted,'resultttt....3')
        if(this.rountresult.length > 0){
          this.filteredData = this.filterData;
          console.log(this.filteredData,'resultttt....3')
        }
        else if(this.onewayresulted.length > 0){
          this.filteredData = this.onewayfilterData;
          console.log(this.filteredData,'resultttt....4')
        }
        else if(this.multicityData.length > 0){
          this.filteredData = this.multiFilterData;
          this.multifil = true

          console.log(this.filteredData,'resultttt...5')
        }
        let result = this.filteredData;

        if (this.RefundableData && this.nonRefundableData) {
          result = result.filter(v => v.Refund === "Refundable" || v.Refund === "Non Refundable");
        } else {
          // Refundable filter
          if (this.RefundableData) {
            result = result.filter(v => v.Refund === "Refundable");
          }

          // Non-refundable filter
          if (this.nonRefundableData) {
            result = result.filter(v => v.Refund === "Non Refundable");
          }
        }


        // Non-Stop filter OneStopFil
        if(this.rountresult.length > 0){
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(v => v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0 ||
                                      v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1 ||
                                      v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1);
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(v => (v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0) ||
                                        (v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1));
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(v => (v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0) ||
                                          (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1));
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(v => (v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1) ||
                                            (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1));
              } else {
                if (this.NonStopFil) {
                  result = result.filter(v => v.Depaturejourney.Stops === 0 || v.Returnjourney.Stops === 0);
                }
                if (this.OneStopFil) {
                  result = result.filter(v => v.Depaturejourney.Stops === 1 || v.Returnjourney.Stops === 1);
                }
                if (this.OnePlusFil) {
                  result = result.filter(v => v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1);
                }
              }
            }
          }
        }
        }
        else if(this.onewayfilterData.length > 0){
          if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(v => v.Depaturejourney.Stops === 0 ||
                                      v.Depaturejourney.Stops === 1 ||
                                      v.Depaturejourney.Stops > 1);
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(v => (v.Depaturejourney.Stops === 0 ) ||
                                        (v.Depaturejourney.Stops === 1));
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(v => (v.Depaturejourney.Stops === 0) ||
                                          (v.Depaturejourney.Stops > 1));
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(v => (v.Depaturejourney.Stops === 1) ||
                                            (v.Depaturejourney.Stops > 1));
              } else {
                if (this.NonStopFil) {
                  result = result.filter(v => v.Depaturejourney.Stops === 0);
                }
                if (this.OneStopFil) {
                  result = result.filter(v => v.Depaturejourney.Stops === 1);
                }
                if (this.OnePlusFil) {
                  result = result.filter(v => v.Depaturejourney.Stops > 1);
                }
              }
            }
          }
        }
        }
        else if(this.multiFilterData.length > 0){
          if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(v => v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0 ||
                                      v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1 ||
                                      v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1);
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(v => (v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0) ||
                                        (v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1));
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(v => (v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0) ||
                                          (v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1));
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(v => (v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1) ||
                                            (v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1));
              } else {
                if (this.NonStopFil) {
                  result = result.filter(v => {
                    if(v.Flight4JourneyTime){
                      return v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0 || v.Flight4JourneyTime.Stops === 0
                    }
                else if(v.Flight1JourneyTime && v.Flight2JourneyTime && v.Flight3JourneyTime){
                  return v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0 || v.Flight3JourneyTime.Stops === 0;
                }
                else if(v.Flight1JourneyTime && v.Flight2JourneyTime){
                  return v.Flight1JourneyTime.Stops === 0 || v.Flight2JourneyTime.Stops === 0
                }
                })
                }
                if (this.OneStopFil) {
                  result = result.filter(v => {
                    if(v.Flight4JourneyTime){
                      return v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1 || v.Flight4JourneyTime.Stops === 1
                    }
                else if(v.Flight1JourneyTime && v.Flight2JourneyTime && v.Flight3JourneyTime){
                  return v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1 || v.Flight3JourneyTime.Stops === 1;
                }
                else if(v.Flight1JourneyTime && v.Flight2JourneyTime){
                  return v.Flight1JourneyTime.Stops === 1 || v.Flight2JourneyTime.Stops === 1
                }
                  });
                }
                if (this.OnePlusFil) {
                  result = result.filter(v => {
                    if(v.Flight4JourneyTime){
                      return v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1 || v.Flight4JourneyTime.Stops > 1
                    }
                else if(v.Flight1JourneyTime && v.Flight2JourneyTime && v.Flight3JourneyTime){
                  return v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1 || v.Flight3JourneyTime.Stops > 1;
                }
                else if(v.Flight1JourneyTime && v.Flight2JourneyTime){
                  return v.Flight1JourneyTime.Stops > 1 || v.Flight2JourneyTime.Stops > 1
                }
                  });
                }
              }
            }
          }
        }
        }


        if (this.changevalue && this.changevalue.length === 2) {
          const [minValue, maxValue] = this.changevalue;
          result = result.filter(item => {
          const price = parseFloat(item.perperson);
          return price >= parseFloat(minValue) && price <= parseFloat(maxValue);
          });
        }

        if (this.selectedFromAirports) {
            result = result.filter(flight => this.selectedFromAirports.includes(flight.Depature[0].Departure.AirportName));
            console.log(result,'resultttt...1')
          }
          if (this.selectedToAirports) {
            result = result.filter(flight => this.selectedToAirports.includes(flight.Depature[flight.Depature.length-1].Arrival.AirportName));
            console.log(result,'resultttt...2')
          }

          if(this.multicityData.length > 0){
            if (this.multiFromAirports) {
              result = result.filter(flight =>
                  this.multiFromAirports.includes(flight.Flight1[0].Departure.AirportName) ||
                  this.multiFromAirports.includes(flight.Flight2[0].Departure.AirportName) ||
                  this.multiFromAirports.includes(flight.Flight3[0].Departure.AirportName) ||
                  this.multiFromAirports.includes(flight.Flight4[0].Departure.AirportName)
              );
            }
            if (this.multiToAirports) {
              result = result.filter(flight =>
                  this.multiToAirports.includes(flight.Flight1[flight.Flight1.length-1].Arrival.AirportName) ||
                  this.multiToAirports.includes(flight.Flight2[flight.Flight2.length-1].Arrival.AirportName) ||
                  this.multiToAirports.includes(flight.Flight3[flight.Flight3.length-1].Arrival.AirportName) ||
                  this.multiToAirports.includes(flight.Flight4[flight.Flight4.length-1].Arrival.AirportName)
              );
            }
          }

      if(this.rountresult || this.onewayfilterData){
      if (this.defaultOutBoundTime) {
          let compare = parseInt(this.defaultOutBoundTime.split(' ')[0]);
          result = result.filter(v => {
            if (v.Depaturejourney.Time) {
              let timeParts = v.Depaturejourney.Time.split(" ");
              let hours = parseInt(timeParts[0]);
              let minutes = parseInt(timeParts[2]);
              let roundedHours = Math.round(hours + minutes / 60);
              return roundedHours <= compare;
            }
            return false;
          });
        }
      }
        if(this.rountresult){
        if (this.defaultInBoundTime) {
          let compare = parseInt(this.defaultInBoundTime.split(' ')[0]);
          result = result.filter(v => {
            if (v.Returnjourney.Time) {
              let timeParts = v.Returnjourney.Time.split(" ");
              let hours = parseInt(timeParts[0]);
              let minutes = parseInt(timeParts[2]);

              let roundedHours = Math.round(hours + minutes / 60);
              return roundedHours <= compare;
            }
            return false;
          });
        }
        }
        if(this.multiFilterData){
          if (this.defaultOutBoundMulti) {
          let compare = parseInt(this.defaultOutBoundMulti.split(' ')[0]);

            result = result.filter(v => {
              const checkTime = (flight) => {
                if (flight && flight.Time) {
                  let timeParts = flight.Time.split(" ");
                  let hours = parseInt(timeParts[0]);
                  let minutes = parseInt(timeParts[2]);
                  let roundedHours = Math.round(hours + minutes / 60);
                  return roundedHours <= compare;
                }
                return false;
              };

              return (
                checkTime(v.Flight1JourneyTime) ||
                checkTime(v.Flight2JourneyTime) ||
                checkTime(v.Flight3JourneyTime) ||
                checkTime(v.Flight4JourneyTime)
              );
            });
          }
        }


      if(this.rountresult || this.onewayresulted){

        if (this.timeFilterData1 && this.timeFilterData2 && this.timeFilterData3 && this.timeFilterData4) {
        result = result.filter(flight =>
          this.filterByTimeRange(flight, this.morningRange) ||
          this.filterByTimeRange(flight, this.afternoonRange) ||
          this.filterByTimeRange(flight, this.eveningRange) ||
          this.filterByTimeRange(flight, this.beforeMorningRange)
        );
      }
        else if (
          (this.timeFilterData1 && this.timeFilterData2) || (this.timeFilterData1 && this.timeFilterData4) ||
          (this.timeFilterData1 && this.timeFilterData3) || (this.timeFilterData2 && this.timeFilterData4) ||
          (this.timeFilterData2 && this.timeFilterData3) || (this.timeFilterData3 && this.timeFilterData4)
        ) {
          result = result.filter(flight => {
            const timeFilters = [];
            if (this.timeFilterData1) timeFilters.push(this.morningRange);
            if (this.timeFilterData2) timeFilters.push(this.afternoonRange);
            if (this.timeFilterData3) timeFilters.push(this.eveningRange);
            if (this.timeFilterData4) timeFilters.push(this.beforeMorningRange);
            return timeFilters.some(range => this.filterByTimeRange(flight, range));
          });
        }
        else {

          if (this.timeFilterData1) {
          result = result.filter(flight => this.filterByTimeRange(flight, this.morningRange));
          }

          else if (this.timeFilterData2) {
            result = result.filter(flight => this.filterByTimeRange(flight, this.afternoonRange));
          }

          else if (this.timeFilterData3) {
            result = result.filter(flight => this.filterByTimeRange(flight, this.eveningRange));
          }
          else if(this.timeFilterData4){
            result = result.filter(flight => this.filterByTimeRange(flight, this.beforeMorningRange));
          }

        }


          // arrival time filters
          if (this.timeFilterArrData1 && this.timeFilterArrData2 && this.timeFilterArrData3 && this.timeFilterArrData4) {
        result = result.filter(flight =>
          this.filterByTimeRange1(flight, this.morningRange) ||
          this.filterByTimeRange1(flight, this.afternoonRange) ||
          this.filterByTimeRange1(flight, this.eveningRange) ||
          this.filterByTimeRange1(flight, this.beforeMorningRange)
        );
      }
        else if (
          (this.timeFilterArrData1 && this.timeFilterArrData2) || (this.timeFilterArrData1 && this.timeFilterArrData4) ||
          (this.timeFilterArrData1 && this.timeFilterArrData3) || (this.timeFilterArrData2 && this.timeFilterArrData4) ||
          (this.timeFilterArrData2 && this.timeFilterArrData3) || (this.timeFilterArrData3 && this.timeFilterArrData4)
        )
        {
          result = result.filter(flight => {
            const timeFilters = [];
            if (this.timeFilterArrData1) timeFilters.push(this.morningRange);
            if (this.timeFilterArrData2) timeFilters.push(this.afternoonRange);
            if (this.timeFilterArrData3) timeFilters.push(this.eveningRange);
            if (this.timeFilterArrData4) timeFilters.push(this.beforeMorningRange);

            return timeFilters.some(range => this.filterByTimeRange1(flight, range));
          });
        }
        else {

          if (this.timeFilterArrData1) {
            result = result.filter(flight => this.filterByTimeRange1(flight, this.morningRange));
          }
          else if (this.timeFilterArrData2) {
            result = result.filter(flight => this.filterByTimeRange1(flight, this.afternoonRange));
          }
          else if (this.timeFilterArrData3) {
            result = result.filter(flight => this.filterByTimeRange1(flight, this.eveningRange));
          }
          else if (this.timeFilterArrData4) {
            result = result.filter(flight => this.filterByTimeRange1(flight, this.beforeMorningRange));
          }
        }

        //Return
          if (this.timearrFilterData1 && this.timearrFilterData2 && this.timearrFilterData3 && this.timearrFilterData4) {
        result = result.filter(flight =>
          this.filterByTimeRangeRet(flight, this.morningRange) ||
          this.filterByTimeRangeRet(flight, this.afternoonRange) ||
          this.filterByTimeRangeRet(flight, this.eveningRange) ||
          this.filterByTimeRangeRet(flight, this.beforeMorningRange)
        );
      }
        else if (
          (this.timearrFilterData1 && this.timearrFilterData2) || (this.timearrFilterData1 && this.timearrFilterData4) ||
          (this.timearrFilterData1 && this.timearrFilterData3) || (this.timearrFilterData2 && this.timearrFilterData4) ||
          (this.timearrFilterData2 && this.timearrFilterData3) || (this.timearrFilterData3 && this.timearrFilterData4)
        ) {
          result = result.filter(flight => {
            const timeFilters = [];
            if (this.timearrFilterData1) timeFilters.push(this.morningRange);
            if (this.timearrFilterData2) timeFilters.push(this.afternoonRange);
            if (this.timearrFilterData3) timeFilters.push(this.eveningRange);
            if (this.timearrFilterData4) timeFilters.push(this.beforeMorningRange);

            return timeFilters.some(range => this.filterByTimeRangeRet(flight, range));
          });
        }
        else {
          if (this.timearrFilterData1) {
            result = result.filter(flight => this.filterByTimeRangeRet(flight, this.morningRange));
          }
          if (this.timearrFilterData2) {
            result = result.filter(flight => this.filterByTimeRangeRet(flight, this.afternoonRange));
          }
          if (this.timearrFilterData3) {
            result = result.filter(flight => this.filterByTimeRangeRet(flight, this.eveningRange));
          }
          if (this.timearrFilterData4) {
            result = result.filter(flight => this.filterByTimeRangeRet(flight, this.beforeMorningRange));
          }
        }

        if (this.timearrFilterArrData1 && this.timearrFilterArrData2 && this.timearrFilterArrData3 && this.timearrFilterArrData4) {
        result = result.filter(flight =>
          this.filterByTimeRangeRet1(flight, this.morningRange) ||
          this.filterByTimeRangeRet1(flight, this.afternoonRange) ||
          this.filterByTimeRangeRet1(flight, this.eveningRange) ||
          this.filterByTimeRangeRet1(flight, this.beforeMorningRange)
        );
      }
        else if (
          (this.timearrFilterArrData1 && this.timearrFilterArrData2) || (this.timearrFilterArrData1 && this.timearrFilterArrData4) ||
          (this.timearrFilterArrData1 && this.timearrFilterArrData3) || (this.timearrFilterArrData2 && this.timearrFilterArrData4) ||
          (this.timearrFilterArrData2 && this.timearrFilterArrData3) || (this.timearrFilterArrData3 && this.timearrFilterArrData4)
        ) {
          result = result.filter(flight => {
            const timeFilters = [];
            if (this.timearrFilterArrData1) timeFilters.push(this.morningRange);
            if (this.timearrFilterArrData2) timeFilters.push(this.afternoonRange);
            if (this.timearrFilterArrData3) timeFilters.push(this.eveningRange);
            if (this.timearrFilterArrData4) timeFilters.push(this.beforeMorningRange);

            return timeFilters.some(range => this.filterByTimeRangeRet1(flight, range));
          });
        }
        else {
          if (this.timearrFilterArrData1) {
            result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.morningRange));
          }
          if (this.timearrFilterArrData2) {
            result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.afternoonRange));
          }
          if (this.timearrFilterArrData3) {
            result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.eveningRange));
          }
          if (this.timearrFilterArrData4) {
            result = result.filter(flight => this.filterByTimeRangeRet1(flight, this.beforeMorningRange));
          }
        }
      }
      if(this.multicityData){
        console.log('dataaaa...1')
        if (this.timeMulti1 && this.timeMulti2 && this.timeMulti3 && this.timeMulti4) {
          result = result.filter(flight =>
            this.filterTimeRangeMulti(flight, this.morningRange) ||
            this.filterTimeRangeMulti(flight, this.afternoonRange) ||
            this.filterTimeRangeMulti(flight, this.eveningRange) ||
            this.filterTimeRangeMulti(flight, this.beforeMorningRange)
          );
        }
        else if (
          (this.timeMulti1 && this.timeMulti2) ||
          (this.timeMulti1 && this.timeMulti4) ||
          (this.timeMulti1 && this.timeMulti3) ||
          (this.timeMulti2 && this.timeMulti4) ||
          (this.timeMulti2 && this.timeMulti3) ||
          (this.timeMulti3 && this.timeMulti4)
        ) {
          console.log('dataaaa...2')
          result = result.filter(flight => {
            const timeFilters = [];
            if (this.timeMulti1) timeFilters.push(this.morningRange);
            if (this.timeMulti2) timeFilters.push(this.afternoonRange);
            if (this.timeMulti3) timeFilters.push(this.eveningRange);
            if (this.timeMulti4) timeFilters.push(this.beforeMorningRange);
            return timeFilters.some(range => this.filterTimeRangeMulti(flight, range));
          });
        }
        else {

          if (this.timeMulti4) {
            console.log('dataaaa...3')
            result = result.filter(flight => this.filterTimeRangeMulti(flight, this.beforeMorningRange));
          }
          if (this.timeMulti1) {
            console.log('dataaaa...4')
            result = result.filter(flight => this.filterTimeRangeMulti(flight, this.morningRange))
          }
          if (this.timeMulti2) {
            console.log('dataaaa...5')
            result = result.filter(flight => this.filterTimeRangeMulti(flight, this.afternoonRange));
          }
          if (this.timeMulti3) {
            console.log('dataaaa...6')
            result = result.filter(flight => this.filterTimeRangeMulti(flight, this.eveningRange));
          }
        }
        console.log(result,'resulttMultii')
        if (this.timeMultiArr1 && this.timeMultiArr2 && this.timeMultiArr3 && this.timeMultiArr4) {
          result = result.filter(flight =>
            this.filterTimeMultiArr(flight, this.morningRange) ||
            this.filterTimeMultiArr(flight, this.afternoonRange) ||
            this.filterTimeMultiArr(flight, this.eveningRange) ||
            this.filterTimeMultiArr(flight, this.beforeMorningRange)
          );
        }
        else if (
          (this.timeMultiArr1 && this.timeMultiArr2) ||
          (this.timeMultiArr1 && this.timeMultiArr3) ||
          (this.timeMultiArr2 && this.timeMultiArr3) ||
          (this.timeMultiArr1 && this.timeMultiArr4) ||
          (this.timeMultiArr2 && this.timeMultiArr4) ||
          (this.timeMultiArr3 && this.timeMultiArr4)
        ) {
          result = result.filter(flight => {
            const timeFilters = [];
            if (this.timeMultiArr1) timeFilters.push(this.morningRange);
            if (this.timeMultiArr2) timeFilters.push(this.afternoonRange);
            if (this.timeMultiArr3) timeFilters.push(this.eveningRange);
            if (this.timeMultiArr3) timeFilters.push(this.beforeMorningRange);

            return timeFilters.some(range => this.filterTimeMultiArr(flight, range));
          });
        }
        else {
          if (this.timeMultiArr1) {
            result = result.filter(flight => this.filterTimeMultiArr(flight, this.morningRange));
          }
          if (this.timeMultiArr2) {
            result = result.filter(flight => this.filterTimeMultiArr(flight, this.afternoonRange));
          }
          if (this.timeMultiArr3) {
            result = result.filter(flight => this.filterTimeMultiArr(flight, this.eveningRange));
          }
          if (this.timeMultiArr4) {
            result = result.filter(flight => this.filterTimeMultiArr(flight, this.beforeMorningRange));
          }
        }
      }

      if(this.airlineList){
        const activeAirlines = this.airlineList.filter(airline => airline.active).map(airline => airline.airline);
        console.log(activeAirlines,'resultAirline')
        // result = result.filter(flight => activeAirlines.includes(flight.ownername));
        if (this.rountresult.length > 0) {
            result = result.filter(flight => {
              const ownernameMatch = activeAirlines.includes(flight.ownername);

              const marketingCarrierMatch = flight.Depature.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingCarrierMatch1 = flight.Return.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return ownernameMatch || marketingCarrierMatch || marketingCarrierMatch1;
            });
          }
          else if(this.onewayresulted.length > 0){
            result = result.filter(flight => {
              const ownernameMatch = activeAirlines.includes(flight.ownername);

              const marketingCarrierMatch = flight.Depature.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return ownernameMatch || marketingCarrierMatch;
            });
          }
          else if(this.multicityData.length > 0){
            result = result.filter(flight => {
              const ownernameMatch = activeAirlines.includes(flight.ownername);
              if(flight.Flight1 && flight.Flight2 && flight.Flight3 && flight.Flight4){
                const marketingFlight1 = flight.Flight1.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight2 = flight.Flight2.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight3 = flight.Flight3.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight4 = flight.Flight4.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return ownernameMatch || marketingFlight1 || marketingFlight2 || marketingFlight3 || marketingFlight4;
              }
              else if(flight.Flight1 && flight.Flight2 && flight.Flight3){
                const marketingFlight1 = flight.Flight1.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight2 = flight.Flight2.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight3 = flight.Flight3.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return ownernameMatch || marketingFlight1 || marketingFlight2 || marketingFlight3;
              }
              else {
              const marketingCarrierMatch = flight.Flight1.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingCarrierMatch1 = flight.Flight2.some(dep =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return ownernameMatch || marketingCarrierMatch || marketingCarrierMatch1;
              }
            });
          }
    //        this.airlineList.forEach(v => {
    //     const hasData = result.some(flight => flight.ownername === v.airline ||
    //         flight.Depature.some(dep => dep.MarketingCarrier.Name === v.airline) ||
    //         flight.Return.some(dep => dep.MarketingCarrier.Name === v.airline) ||
    //         (flight.Flight1 && flight.Flight1.some(dep => dep.MarketingCarrier.Name === v.airline)) ||
    //         (flight.Flight2 && flight.Flight2.some(dep => dep.MarketingCarrier.Name === v.airline)) ||
    //         (flight.Flight3 && flight.Flight3.some(dep => dep.MarketingCarrier.Name === v.airline)) ||
    //         (flight.Flight4 && flight.Flight4.some(dep => dep.MarketingCarrier.Name === v.airline))
    //     );

    //     v.active = hasData;
    // });
         
    // console.log(this.airlineList, 'updatedAirlineList');


            console.log(result,'resulttttMultti')
      }
        if(this.rountresult.length>0){
          this.hasNonStop = result.some(flight => flight.Depaturejourney.Stops === 0 || flight.Returnjourney.Stops === 0);
          this.hasOneStop = result.some(flight => flight.Depaturejourney.Stops === 1 || flight.Returnjourney.Stops === 1);
          this.hasOnePlusStop = result.some(flight => flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1);
        }
        else if(this.onewayresulted.length > 0){
          this.hasNonStop = result.some(flight => flight.Depaturejourney.Stops === 0 )
          this.hasOneStop = result.some(flight => flight.Depaturejourney.Stops === 1 )
          this.hasOnePlusStop = result.some(flight => flight.Depaturejourney.Stops > 1)
        }
        else if(this.multiFilterData.length > 0){
          this.multiFilterData.forEach((v) => {
            if(v.Flight4){
              this.hasNonStop = result.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 || flight.Flight4JourneyTime.Stops === 0);
              this.hasOneStop = result.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1 || flight.Flight4JourneyTime.Stops === 1);
              this.hasOnePlusStop = result.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1 || flight.Flight4JourneyTime.Stops > 1);
            }
            else if(v.Flight1 && v.Flight2 && v.Flight3){
              this.hasNonStop = result.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 )
              this.hasOneStop = result.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1)
              this.hasOnePlusStop = result.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1)
            }
            else {
              this.hasNonStop = result.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0)
              this.hasOneStop = result.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1)
              this.hasOnePlusStop = result.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1)
            }
          })
        }
          this.hasRefundable = result.some(flight => flight.Refund === "Refundable");
          this.hasNonRefundable = result.some(flight => flight.Refund === "Non Refundable");

        console.log(result,'resulttttMultti..1')
          this.hasMorningFlights = !this.timeFilterData1 || result.some(flight => this.filterByTimeRange(flight, this.morningRange));
          this.hasAfternoonFlights = !this.timeFilterData2 || result.some(flight => this.filterByTimeRange(flight, this.afternoonRange));
          this.hasEveningFlights = !this.timeFilterData3 || result.some(flight => this.filterByTimeRange(flight, this.eveningRange));

          this.hasMorningFlights1 = !this.timeFilterArrData1 || result.some(flight => this.filterByTimeRange1(flight, this.morningRange));
          this.hasAfternoonFlights1 = !this.timeFilterArrData2 || result.some(flight => this.filterByTimeRange1(flight, this.afternoonRange));
          this.hasEveningFlights1 = !this.timeFilterArrData3 || result.some(flight => this.filterByTimeRange1(flight, this.eveningRange));

          this.hasMorningReturn = !this.timearrFilterData1 || result.some(flight => this.filterByTimeRangeRet(flight, this.morningRange));
          this.hasAfternoonReturn = !this.timearrFilterData2 || result.some(flight => this.filterByTimeRangeRet(flight, this.afternoonRange));
          this.hasEveningReturn = !this.timearrFilterData3 || result.some(flight => this.filterByTimeRangeRet(flight, this.eveningRange));


          this.hasMorningReturn1 = !this.timearrFilterArrData1 || result.some(flight => this.filterByTimeRangeRet1(flight, this.morningRange));
          this.hasAfternoonReturn1 = !this.timearrFilterArrData2 || result.some(flight => this.filterByTimeRangeRet1(flight, this.afternoonRange));
          this.hasEveningReturn1 = !this.timearrFilterArrData3 || result.some(flight => this.filterByTimeRangeRet1(flight, this.eveningRange));

        // this.disableData = !this.hasMorningFlights || !this.hasAfternoonFlights || !this.hasEveningFlights ||!this.hasMorningFlights1 || !this.hasAfternoonFlights1 || !this.hasEveningFlights1 || !this.hasMorningReturn || !this.hasAfternoonReturn || !this.hasEveningReturn || !this.hasMorningReturn1 || !this.hasAfternoonReturn1 || !this.hasEveningReturn1 || result.length == 0



    console.log(this.hasMorningFlights,'filterFlights1')
    console.log(this.hasAfternoonFlights,'filterFlights2')
    console.log(this.hasEveningFlights,'filterFlights3')

    if (this.filterData.length > 0) {
      this.rountresult = result;

      this.disableData = this.rountresult.length == 0
      if(this.disableData){
        this.hasRefundable = this.filterData.some(flight => flight.Refund === "Refundable");
        this.hasNonRefundable = this.filterData.some(flight => flight.Refund === "Non Refundable");
        this.hasNonStop = this.filterData.some(flight => flight.Depaturejourney.Stops === 0 || flight.Returnjourney.Stops === 0);
        this.hasOneStop = this.filterData.some(flight => flight.Depaturejourney.Stops === 1 || flight.Returnjourney.Stops === 1);
        this.hasOnePlusStop = this.filterData.some(flight => flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1);
      }
    }
    if (this.onewayfilterData.length > 0) {
      this.onewayresulted = result;
      this.disableData = this.onewayresulted.length == 0
      if((this.hasMorningFlights || this.hasAfternoonFlights || this.hasEveningFlights || this.hasMorningFlights1 || this.hasAfternoonFlights1 || this.hasEveningFlights1) && this.disableData){
        this.hasRefundable = this.onewayfilterData.some(flight => flight.Refund === "Refundable");
        this.hasNonRefundable = this.onewayfilterData.some(flight => flight.Refund === "Non Refundable");
        this.hasNonStop = this.onewayfilterData.some(flight => flight.Depaturejourney.Stops === 0 )
        this.hasOneStop = this.onewayfilterData.some(flight => flight.Depaturejourney.Stops === 1 )
        this.hasOnePlusStop = this.onewayfilterData.some(flight => flight.Depaturejourney.Stops > 1)
      }
    }
    if(this.multiFilterData.length > 0){
      this.multicityData = result
      console.log(result,'resulttttMult')
      this.disableData = this.multicityData.length == 0
      if(this.disableData){
        this.hasRefundable = this.multiFilterData.some(flight => flight.Refund === "Refundable");
        this.hasNonRefundable = this.multiFilterData.some(flight => flight.Refund === "Non Refundable");
        this.multiFilterData.forEach((v) => {
          if(v.Flight4){
            this.hasNonStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 || flight.Flight4JourneyTime.Stops === 0);
            this.hasOneStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1 || flight.Flight4JourneyTime.Stops === 1);
            this.hasOnePlusStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1 || flight.Flight4JourneyTime.Stops > 1);
          }
          else if(v.Flight1 && v.Flight2 && v.Flight3){
            this.hasNonStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0 || flight.Flight3JourneyTime.Stops === 0 )
            this.hasOneStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1 || flight.Flight3JourneyTime.Stops === 1)
            this.hasOnePlusStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1 || flight.Flight3JourneyTime.Stops > 1)
          }
          else {
            this.hasNonStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 0 || flight.Flight2JourneyTime.Stops === 0)
            this.hasOneStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops === 1 || flight.Flight2JourneyTime.Stops === 1)
            this.hasOnePlusStop = this.multiFilterData.some(flight => flight.Flight1JourneyTime.Stops > 1 || flight.Flight2JourneyTime.Stops > 1)
          }
        })
      }
      console.log(this.multicityData,'multiiiiii')
    }
        // this.rountresult = result;
    },

    resetAllFilters(){
          this.RefundableData = false
          this.nonRefundableData = false
          this.NonStopFil = false
          this.OneStopFil = false
          this.OnePlusFil = false
          this.selectedFromAirports = false
          this.selectedToAirports = false
          this.timeFilterData1 = false
          this.timeFilterData2 = false
          this.timeFilterData3 = false
          this.timeFilterData4 = false
          this.timeFilterArrData1 = false
          this.timeFilterArrData2 = false
          this.timeFilterArrData3 = false
          this.timeFilterArrData4 = false
          this.timearrFilterData1 = false
          this.timearrFilterData2 = false
          this.timearrFilterData3 = false
          this.timearrFilterData4 = false
          this.timearrFilterArrData1 = false
          this.timearrFilterArrData2 = false
          this.timearrFilterArrData3 = false
          this.timearrFilterArrData4 = false
          this.changevalue = this.initialChangeValue
          this.airlineList.forEach((v) => {
              v.active = true;
            });

            if (this.filterData.length > 0) {
              this.rountresult = this.filterData;
            } else if (this.onewayfilterData.length > 0) {
              this.onewayresulted = this.onewayfilterData;
            } else if (this.multiFilterData.length > 0) {
              this.multicityData = this.multiFilterData;
            }

          if(this.rountresult.length > 0){
            this.defaultOutBoundTime = this.outBound
            this.defaultInBoundTime = this.inBound
            this.rountresult = this.filterData
          }
          else if(this.onewayresulted.length > 0){
            this.defaultOutBoundTime = this.outBound
            this.onewayresulted = this.onewayfilterData
          }
          else if(this.multiFilterData.length > 0){
            this.timeMulti1 = false,
            this.timeMulti2 = false,
            this.timeMulti3 = false,
            this.timeMulti4 = false,
            this.timeMultiArr1 = false,
            this.timeMultiArr2 = false,
            this.timeMultiArr3 = false,
            this.timeMultiArr4 = false,
            this.defaultOutBoundMulti = this.outBoundMulti
            this.multicityData = this.multiFilterData
          }
        },


    getMultiAdult(data) {
      // console.log(data, 'lkllklklklk...22..2.2.2')
      if (data.adult > 0) {
        return data.adult;
      } else {
        return "";
      }
    },
    getMultiChild(data) {
      if (data.child > 0) {
        return data.child;
      } else {
        return "";
      }
    },
    getMultiInfrant(data) {
      if (data.infrant > 0) {
        return data.infrant;
      } else {
        return "";
      }
    },

    // Form Header section End

    getConfig() {
      // console.log(this.$store.state.data,'homehoemehojoke...')

      // let getConfigData = this.$store.state.data
      // let portalLocal = localStorage.getItem("portalData")

      // let getConfigData1 = JSON.parse(portalLocal)

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;
        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;

        this.displayCurrency =
          getConfigData1.payload.portal_configuration.currency.display;
        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        // console.log(this.makeSearchUrl, 'head...sea..1..')
        // this.getConfigFunc();
      }
    },

    valuechange() {
      let ress = [];
      if (this.filterData.length > 0) {
        // this.rountresult = this.filterData;
        this.filterData.forEach((c) => {
          if (
            c.perperson >= this.rountresult[0].perperson &&
            c.perperson <=
              this.rountresult[this.rountresult.length - 1].perperson
          ) {
            ress.push(c);
          }
        });
        this.rountresult = ress;
        console.log(this.rountresult, "this.rountresult this.rountresult ");
      }
    },

    // RefundableData(data) {
    //   if (data == 1) {
    //     this.Refundablevalue = !this.Refundablevalue;
    //   }
    //   let $data = [];
    //   this.valuechange();
    //   console.log(this.nonRefundablevalue, this.Refundablevalue, "reeenon");
    //   if (this.rountresult.length > 0) {
    //     $data = this.rountresult;
    //   }
    //   if (this.onewayresulted.length > 0) {
    //     $data = this.onewayresulted;
    //   }
    //   if (this.multicityData.length > 0) {
    //     $data = this.multicityData;
    //   }
    //   if (
    //     (this.Refundablevalue && this.nonRefundablevalue) ||
    //     (!this.Refundablevalue && !this.nonRefundablevalue)
    //   ) {
    //     console.log("1111");
    //     if (this.rountresult.length > 0) {
    //       this.rountresult = $data;
    //     }
    //     if (this.onewayresulted.length > 0) {
    //       this.onewayresulted = $data;
    //     }
    //     if (this.multicityData.length > 0) {
    //       this.multicityData = $data;
    //     }
    //   } else if (!this.Refundablevalue && this.nonRefundablevalue) {
    //     let $result = [];
    //     $data.forEach((v) => {
    //       if (v.Refund !== "Refundable") {
    //         $result.push(v);
    //       }
    //     });
    //     console.log("000000555555 ", $result);
    //     if (this.rountresult.length > 0) {
    //       this.rountresult = $result;
    //     }
    //     if (this.onewayresulted.length > 0) {
    //       this.onewayresulted = $result;
    //     }
    //     if (this.multicityData.length > 0) {
    //       this.multicityData = $result;
    //     }
    //   } else if (this.Refundablevalue) {
    //     let $result = [];
    //     $data.forEach((v) => {
    //       if (v.Refund == "Refundable") {
    //         $result.push(v);
    //       }
    //     });

    //     console.log("0000005555556666 ", $result);
    //     if (this.rountresult.length > 0) {
    //       this.rountresult = $result;
    //     }
    //     if (this.onewayresulted.length > 0) {
    //       this.onewayresulted = $result;
    //     }
    //     if (this.multicityData.length > 0) {
    //       this.multicityData = $result;
    //     }
    //   }
    //   this.airlineFilter();
    // },

    // nonRefundableData(data) {
    //   if (data == 1) {
    //     this.nonRefundablevalue = !this.nonRefundablevalue;
    //   }

    //   this.valuechange();
    //   let $data = [];

    //   if (this.rountresult.length > 0) {
    //     $data = this.rountresult;
    //   }
    //   if (this.onewayresulted.length > 0) {
    //     $data = this.onewayresulted;
    //   }
    //   if (this.multicityData.length > 0) {
    //     $data = this.multicityData;
    //   }

    //   if (
    //     (this.Refundablevalue && this.nonRefundablevalue) ||
    //     (!this.Refundablevalue && !this.nonRefundablevalue)
    //   ) {
    //     console.log("1111");
    //     if (this.rountresult.length > 0) {
    //       this.rountresult = $data;
    //     }
    //     if (this.onewayresulted.length > 0) {
    //       this.onewayresulted = $data;
    //     }
    //     if (this.multicityData.length > 0) {
    //       this.multicityData = $data;
    //     }
    //   } else if (this.Refundablevalue && !this.nonRefundablevalue) {
    //     console.log("111122222");
    //     let $result = [];
    //     $data.forEach((v) => {
    //       if (v.Refund !== "Non Refundable") {
    //         $result.push(v);
    //       }
    //     });

    //     if (this.rountresult.length > 0) {
    //       this.rountresult = $result;
    //     }
    //     if (this.onewayresulted.length > 0) {
    //       this.onewayresulted = $result;
    //     }
    //     if (this.multicityData.length > 0) {
    //       this.multicityData = $result;
    //     }
    //   } else if (this.nonRefundablevalue) {
    //     console.log("1111333333");
    //     let $result = [];
    //     $data.forEach((v) => {
    //       if (v.Refund == "Non Refundable") {
    //         $result.push(v);
    //       }
    //     });
    //     if (this.rountresult.length > 0) {
    //       this.rountresult = $result;
    //     }
    //     if (this.onewayresulted.length > 0) {
    //       this.onewayresulted = $result;
    //     }
    //     if (this.multicityData.length > 0) {
    //       this.multicityData = $result;
    //     }
    //   }

    //   this.airlineFilter();
    // },

  },

  // AutoComplete Functions End

  watch: {
    'RefundableData'() {
      this.applyFilters();
    },
    'nonRefundableData'() {
      this.applyFilters();
    },
    'NonStopFil'() {
      this.applyFilters();
    },
    'OneStopFil'(){
      this.applyFilters();
    },
    'OnePlusFil'(){
      this.applyFilters();
    },
    'defaultOutBoundTime'(){
      this.applyFilters();
    },
    'defaultInBoundTime'(){
      this.applyFilters();
    },
    'selectedFromAirports'(){
      this.applyFilters();
    },
    'selectedToAirports'(){
      this.applyFilters();
    },
    'changevalue'(){
      this.applyFilters();
    },
    'defaultOutBoundMulti'(){
      this.applyFilters();
    },
    classType(newSelectValue) {
      console.log("select property changed to:", newSelectValue);

      if (newSelectValue) {
        setTimeout(() => {
          const focusDrop = this.$refs.countrySelect;
          if (focusDrop) {
            this.$refs.countrySelect.focus();
          }

          this.classSelect = false;
          console.log(this.classSelect);
        }, 100);
      }
    },


    "$i18n.locale": {
      handler: function () {
        this.classTypeitems = [
          {
            text: this.$t("homePageContent.contentSection.economy"),
            value: "Economy",
            department: "Economy Department",
          },
          {
            text: this.$t("homePageContent.contentSection.business"),
            value: "Business",
            department: "Business Department",
          },
          {
            text: this.$t("homePageContent.contentSection.firstclass"),
            value: "First Class",
            department: "First Class Department",
          },
        ];
        // this.classType = 'Economy';
      },
      deep: true,
    },
  
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },

    translatedClassType() {
      const currentClass = this.classTypeitems.find(
        (item) => item.value === this.classType
      );
      return currentClass ? currentClass.text : this.classType;
    },

    displayClassType() {
      const classType = this.tripData.class;
      switch (classType) {
        case "Economy":
          return this.$t("homePageContent.contentSection.economy");
        case "Business":
          return this.$t("homePageContent.contentSection.business");
        case "First Class":
          return this.$t("homePageContent.contentSection.firstclass");
        case "Premium Economy":
          return this.$t("homePageContent.contentSection.premiumEconomy");
        default:
          return classType; // Fallback to the original classType if no translation is found
      }
    },
  },

  created() {
    this.getConfig();
    this.airlineLogo_func();

    console.log(this.$route.query, "this.$route.queryeee");
    localStorage.removeItem("rounttrip");
    localStorage.removeItem("onewaytrip");

    this.red = this.$route.query.city;
    this.red1 = this.$route.query.city;

    // console.log(this.red,'nandupillai',this.red1,'nandupillai')

    // this.autocompletJson();

    if (this.red || this.red1) {
      this.roundData();
      this.mydata();
      // this.filterShowing();
    } else {
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      this.red1 = 3;
      console.log(this.red1, "reeddd111....");
      // this.paxhide = false
      localStorage.removeItem("rountData");
      this.multi_city = JSON.parse(this.$route.query.muti_city);

      this.classType = this.multi_city.class;

      console.log(
        this.multi_city,
        "this.showMulti_datauerthis.showMulti_datay"
      );

      // this.showMulti_data = this.multi_city
      // this.formMultiData = JSON.parse(this.$route.query.muti_city);
      this.multicityresult();

      // this.filterShowing();

      console.log(this.multi_city, "this.$route.querythis.$route.query....1");
      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
      }

      if (this.multi_city.itinery.length == 4) {
        console.log(
          this.multi_city,
          this.multi_city.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
      }
    }

    // console.log(this.red1, "this.red1 this.red1 this.red1 this.red1 ");
    if (this.red1 == 1) {
      this.sended1();
    }
    if (this.red1 == 2) {
      this.sendedone1();
    }
    this.generateBookingRefId();
    this.actActive();
    this.airlogodata = airLogo;
    this.deatailData();
    this.clicked1 = true;
    this.clicked2 = true;
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleCalenderResize);
  },

  mounted() {
    this.generateBookingRefId();
    document.getElementById("navbar").style.display = "none";
    document.getElementById("food").style.display = "none";

    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleCalenderResize);
    // this.autocompletJson();
    // this.fetchItems();

    this.handleResize();
    this.handleCalenderResize();
  },
};
</script>

<style scoped>
/* Filter By Section*/

>>> .filter_icons .v-selection-control--density-default {
  --v-selection-control-size: 30px !important;
}

>>> .filter_icons .v-selection-control .v-label {
  font-size: 14px;
  font-weight: 500 !important;
}

>>> .filter_icons .v-selection-control__input .v-icon {
  font-size: 20px !important;
}

>>> .filter_icons .price_slider .v-slider-track {
  --v-slider-track-size: 3px !important;
}

>>> .filter_icons .price_slider .v-slider-thumb {
  --v-slider-thumb-size: 16px !important;
}

/* Filter By Section End*/

.bg-image {
  height: auto;
  background-image: url("../assets/tourist_vector.jpg");
  background-size: 100% 100%;
  background-position: left;
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>> .dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

.mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
}

.v-input__append > .v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}
/* 
.v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
} */

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>> .dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.price-head .price-currency {
  font-size: 17px;
  font-weight: 600;
  color: black;
  padding-top: 9px;
  padding-right: 6px;
  /* font-size: 25px;
  font-weight: 600;
  color: black;
  padding-top: 6px;
  padding-right: 6px; */
}

.price-head .price-Amount {
  font-size: 26px;
  font-weight: 600;
  color: black;
}

.radio {
  /* background-color: #FFB80A; */
  border-radius: 5px;
  color: black !important;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400;
  height: 30px;
  width: auto;
  /* width: 150px; */
}

.air-title {
  background-color: #ee2b34;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
  padding: 0px 0px 0px 12px;
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: grey;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 11px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

>>> .v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: red;
  position: absolute;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 14px !important;
  left: 0px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  /* font-size: 16px;
  color: #242424;
  font-weight:600; */
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

.dp__icon {
  display: block;
}

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
}

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
  height: 24px;
  font-family: "Poppins", sans-serif !important;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: -10px !important;
  left: -48px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #242424;
  font-size: 16px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #242424;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: absolute;
  left: 50%;
  top: -40px;
  width: 250px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: absolute;
  left: 50.4%;
  top: -42px;
  width: 300px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: auto;
}

.hoverDataStop2 {
  position: absolute;
  left: 62%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 50%;
  top: -37px;
  width: 250px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.refunt {
  color: blue;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>> .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.v-card {
  border-radius: 10px !important;
  z-index: revert-layer;
  overflow: unset;
}

.hoveredCard:hover {
  cursor: pointer;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: none; */
  /* border: 2px solid lightgray; */
  /* border-radius: 8px !important; */
}

.card-in {
  box-shadow: none;
}

/* .card-in:hover {
  box-shadow: 0px 0px 1px 0px black;
} */

.card-in {
  /* box-shadow: 0px 0px 1px 0px black; */
}

.time-section .v-icon {
  font-size: 12px !important;
  color: grey;
}

.time-section span {
  font-size: 9px;
  margin-left: 5px;
  font-weight: 500;
  color: grey;
}

.clear_style {
  border: 1px solid #ffa0a0;
  border-radius: 5px;
  padding: 4px 6px;
  height: 30px;
  cursor: pointer;
  width: 105px;
}

.clear_style:hover {
  background-color: #ffe9e9;
}

.add_style {
  border: 1px solid #a0ffa0;
  border-radius: 5px;
  padding: 4px;
  height: 30px;
  cursor: pointer;
  width: 105px;
}

.add_style:hover {
  background-color: #e9ffe9;
}

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }

  .magnify-btn .v-btn {
    width: 140px !important;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }
  .airlineLogo-hide {
    display: none !important;
  }

  .airlineLogo-show {
    display: flex !important;
    justify-content: space-between !important;
  }
  .select-section-hide {
    display: none !important;
  }
  .select-section-hide1 {
    display: none !important;
  }
}
@media only screen and (min-width: 992px) {
  .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  }
  .select-section-hide {
    display: flex !important;
  }
  .select-section-hide1 {
    display: flex !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    padding: 0px 0px 0px 12px;
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section .v-icon {
    font-size: 12px !important;
    color: grey;
  }

  .time-section span {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

@media only screen and (min-width: 479px) {
  .aircode-hide {
    display: block;
  }

  .aircode-show {
    display: none;
  }

  /* .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  } */

  /* .select-section-hide {
    display: flex !important;
  } */

  /* .select-section-hide1 {
    display: flex !important;
  } */

  .date-section-hide {
    display: block;
  }

  .date-section-show {
    display: none;
  }

  .fare-detail-mobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }

  .pax-fonts span[data-v-92fd54d6] {
    font-size: 12px;
    color: #242424;
    font-weight: 700;
  }

  .aircode-hide {
    display: none;
  }

  .aircode-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .select-section-hide1 {
    display: none !important;
  } */

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .airlineLogo-show .mbl-price-section {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .mbl-price-section .price-currency {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    padding-top: 10px;
    padding-right: 4px;
  }

  .airlineLogo-show .mbl-price-section .price-Amount {
    margin-bottom: 0px !important;
    font-size: 22px !important;
  }

  .airlineLogo-show .mbl-price-section .content {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .v-btn {
    height: 30px !important;
  }

  >>> .airlineLogo-show .v-btn .v-btn__content {
    font-size: 13px !important;
    letter-spacing: 0px !important;
  }

  .date-section-hide {
    display: none;
  }

  .date-section-show {
    display: block;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }

  .fare-detail-mobile {
    display: block;
  }

  .fare-detail-mobile .v-card-text {
    color: rgb(51, 102, 204);
    font-weight: 500;
    padding-left: 0 !important;
    text-align: left;
    width: auto !important;
  }

  .fare-detail-mobile .v-icon {
    font-size: 24px !important;
    height: 18px !important;
    width: 24px !important;
    position: relative !important;
    right: 60px !important;
    top: 2px !important;
  }
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 38px;
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
  width: 100%;
}

.form-card {
  width: 90%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;
}

.back-bg {
  margin: 20px;
  width: 500px;
  height: 500px;
  border-radius: 30px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.round-mode,
.oneway-mode {
  padding: 10px 10px;
  overflow: unset;
  box-shadow: none;
  border: 2px solid lightgray;
  border-radius: 5px !important;
}

/* Traveller Pax Dropdown section */

>>> .input-styles .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

>>> .input-styles .select-1 .v-input__control {
  height: 36px;
}

>>> .input-styles .select-1 .v-field__input {
  padding-top: 2;
  align-items: baseline;
}

>>> .input-styles .select-1 .v-field__append-inner {
  padding-top: 0;
}

>>> .select-2 .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-2 .v-input--density-default {
  top: 0;
}

>>> .select-2 .v-input__control {
  height: 28px;
}

>>> .select-2 .v-field__input {
  padding-top: 0px;
  align-items: baseline;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px;
}

/* Traveller Pax Dropdown section End */

.p-float-label .p-inputwrapper-focus ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-float-label .p-inputwrapper-filled ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-inputtext {
  font-size: 16px !important;
  font-weight: 600px !important;
}

.pax-fonts span {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

>>> .dp__month_year_select {
  pointer-events: none;
}

>>> .dp__today {
  border: 1px solid #ee2b34 !important;
}

>>> .dp__date_hover:hover {
  background: #ee2b34;
  color: white;
}

>>> .dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  top: -10px !important;
  left: -50px !important;
  transform: none !important;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.recment {
  border: 1px solid rgb(31 206 229);
  background-color: #bae9e45e;
}

.normal-rec {
  border: 1px solid rgb(163, 161, 161) !important;
}

.top_filterprice {
  font-size: 18px;
  font-weight: 500;
}

.top_filterTitle {
  font-size: 14px;
}
.mutilti_data {
  padding: 2px 4px;
  max-height: 250px;
  /* overflow: scroll; */
  /* overflow-x: hidden; */
  border: 1px solid lightgray;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}
.baggageimg,
.baggageimg1,
.baggageimg2 {
  height: 20px !important;
}
.headtext {
  font-size: 15px;
}

/* mobile view */
@media screen and (max-width: 430px) {
  .baggageimg,
  .baggageimg1,
  .baggageimg2 {
    height: 13px !important;
  }
  /* .fare-detail-mobile .v-card-text {
    padding-left: 12px !important;
  } */
  .top_filterprice {
    font-size: 14px !important;
  }
  .headtext {
    font-size: 13px;
  }
}
.rec-bottom {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.rec-bottom1 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media screen and (max-width: 760px) {
  .rec-bottom {
    border-radius: 0 !important;
  }
  .rec-bottom1 {
    border-radius: 0 !important;
  }
  .refundData {
    text-align: center !important;
    display: flex;
  }
}
@media screen and (min-width: 998px) and (max-width: 1024px) {
  .price-head .price-currency {
    font-size: 14px;
  }
  .price-head .price-Amount {
    font-size: 20px;
    margin-top: 3px;
  }
}

>>>.outbound .v-input{
  border:1px solid lightgray;
  border-radius:8px;
}
>>>.inbound .v-input{
  border:1px solid lightgray;
  border-radius:8px;
}
/* >>>.outbound .v-select__selection .v-select__selection-text{
  --v-input-padding-top: 6px !important;
} */

>>>.outbound .v-field{
  height:35px;
}
>>>.outbound .v-field .v-field__field{
  height:35px;
  top:-10px;
}
>>>.outbound .v-field__input .v-input--density-default{
   --v-input-padding-top: 6px !important;
}
>>>.inbound .v-field{
  height:35px;
}
>>>.inbound .v-field .v-field__field{
  height:35px;
  top:-10px;
}
>>>.inbound .v-field__input .v-input--density-default{
   --v-input-padding-top: 6px !important;
}
</style>
